import { Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { darken, styled } from "@mui/material/styles";
import { notifyPageNav, openMenu } from "../../analytics/notifyCustomActions";
import AssistantSelector from "../AssistantSelector";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import NavItem from "./NavItem";
import { useIsAdminPanel, useIsMobile, useStatusBarDisplayed } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { APP_HEADER_HEIGHT, APP_STATUS_BAR_HEIGHT } from "../consts";
import useAppStore from "../../stores/appStore";
import { systemModeSelector } from "../../stores/selectors/appSelectors";
import { SYSTEM_MODES } from "../../stores/slices/createAppSlice";

const ChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== "statusBarDisplayed"
})(({ theme, statusBarDisplayed }) => ({
  "zIndex": 1,
  "position": "absolute",
  "padding": 0,
  "top": statusBarDisplayed ? 145 : 116,
  "right": "-13px",
  "backgroundColor": "#FFFFFF",
  "border": "1px solid rgba(45, 44, 68, 0.24)",
  "&:hover": {
    backgroundColor: darken(theme.palette.background.default, 0.04)
  },
  [theme.breakpoints.down("sm")]: {
    display: "none"
  }
}));

const TextDivider = styled("div", {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  span: { height: 24 },
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
    1
  )} ${theme.spacing(3)}`,
  ...(!open && {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  })
}));

const DrawerContent = (
  {
    open,
    setOpen,
    coreNavItems,
    secondaryNavItems,
    secondaryNavTitle,
    showAssistantSelector,
    useCurrentPage,
    showMinimizeButton = true
  }
) => {
  const systemMode = useAppStore(systemModeSelector);
  const currentPage = useCurrentPage();
  const isMobile = useIsMobile();
  const isAdminPanel = useIsAdminPanel();
  const navigate = useNavigate();
  const showStatusBar = useStatusBarDisplayed();

  const [openNavSection, setopenNavSection] = useState("");
  const [navSectionWasClosed, setnavSectionWasClosed] = useState("");
  const [showDivider, setShowDivider] = useState(false);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 0 && !showDivider) setShowDivider(true);
    if (event.currentTarget.scrollTop === 0 && showDivider)
      setShowDivider(false);
  };
  const toggleDrawer = () => {
    open && setopenNavSection("");
    setOpen(!open);
  };

  const toggleParentNavItem = (navItemKey, navItemIsOpen) => {
    if (!open) return;
    if (openNavSection === navItemKey || navItemIsOpen) {
      setopenNavSection("");
      setnavSectionWasClosed(navItemKey);
    } else {
      setopenNavSection(navItemKey);
      setnavSectionWasClosed("");
    }
  };

  const handleClick = (navItem, navItemIsOpen) => () => {
    if (navItem.children) {
      toggleParentNavItem(navItem.key, navItemIsOpen);
      openMenu(navItem.text);
    }
    if (navItem.url) {
      navigate(navItem.url);
      notifyPageNav(navItem.text, "navPage");
    }
    if (isMobile) setOpen(false);
  };

  const singleItemProps = {
    handleClick,
    open,
    openNavSection,
    navSectionWasClosed,
    currentPage
  };


  return (
    <>
      {systemMode === SYSTEM_MODES.PREVIEW ? <div style={{
        height: `calc(100% - ${APP_HEADER_HEIGHT}px)`,
        background: 'linear-gradient(180deg, rgba(45, 44, 68, 0.00) 0%, rgba(54, 53, 84, 0.40) 100%)',
        borderRight: '1px solid #92929238',
        zIndex: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%'
      }} /> : null}
      <div style={{ marginTop: APP_HEADER_HEIGHT + (showStatusBar ? APP_STATUS_BAR_HEIGHT : 0) }} />
      {showAssistantSelector && <AssistantSelector isNavPanelOpen={open} />}
      {showMinimizeButton && systemMode !== SYSTEM_MODES.PREVIEW
        ? (
          <ChevronIcon statusBarDisplayed={showStatusBar} size="small" onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ChevronIcon>
        )
        : null}
      {showDivider && (
        <Divider
          sx={{ marginX: "24px", borderColor: "rgba(45, 44, 68, 0.12)" }}
        />
      )}
      <List
        sx={{ pt: isAdminPanel ? 1 : 0, overflowY: "auto", overflowX: "hidden" }}
        onScroll={handleScroll}
      >
        {coreNavItems.map(navItem => (
          <NavItem key={navItem.key} navItem={navItem} {...singleItemProps} />
        ))}
        {secondaryNavItems?.length > 0 && (
          <>
            <TextDivider open={open}>
              {open
                ? (
                  <Typography variant="overline">{secondaryNavTitle}</Typography>
                )
                : (
                  <Divider />
                )}
            </TextDivider>
            {secondaryNavItems.map(navItem => (
              <NavItem
                key={navItem.key}
                navItem={navItem}
                {...singleItemProps}
              />
            ))}
          </>
        )}
      </List>
    </>
  );
};

export default DrawerContent;
