import React from "react";
import {
  getMandatoryMessageForType,
  NO_OPTIONS_ERROR_MESSAGE,
  NO_OPTIONS_MESSAGE,
  STATUS_TYPES,
  VAGUE_NO_OPTIONS_MESSAGE
} from "./consts";
import { FIELD_TYPES } from "src/components/common/fields/utils";

export const isBool = (value) => typeof value === "boolean";

export const isPrimitiveType = (type) => (type === FIELD_TYPES.TEXT
  || type === FIELD_TYPES.PHONE
  || type === FIELD_TYPES.URL
  || type === FIELD_TYPES.INT
  || type === FIELD_TYPES.BOOL
  || type === FIELD_TYPES.SECRET
);

export const transformFieldSpecToConfig = (fieldSpec) => {
  if (fieldSpec?.fields?.length) {
    return fieldSpec?.fields?.map(({ key, type, default: configuration }) => ({
      key,
      type,
      configuration: configuration || null
    }));
  }
  return {
    key: fieldSpec.key,
    type: fieldSpec.type,
    configuration: fieldSpec.default || null
  };
};

export const determineStatusForTableRow = (liveValue, value, publishValue, status) => {
  if (status === STATUS_TYPES.DRAFT) {
    return STATUS_TYPES.DRAFT;
  }
  if (JSON.stringify(value) !== JSON.stringify(publishValue)) {
    return STATUS_TYPES.DRAFT;
  }
  if (status === STATUS_TYPES.PENDING) {
    return STATUS_TYPES.PENDING;
  }
  if (JSON.stringify(liveValue) !== JSON.stringify(publishValue)) {
    return STATUS_TYPES.PENDING;
  }
  return STATUS_TYPES.LIVE;
};

export const noOptionList = (label) => [
  {
    display_key: label ? NO_OPTIONS_MESSAGE.replace('$label', label) : VAGUE_NO_OPTIONS_MESSAGE,
    key: "no_options",
    disabled: true
  }
];

export const errorOptionList = (label) => [
  {
    display_key: NO_OPTIONS_ERROR_MESSAGE.replace('$label', label),
    key: "no_options_error",
    error: true
  }
];

export  const extractKeysAndCollectionIdsIfRef = (obj) => {
  const result = [];

  const extract = (obj) =>{
    if (obj?.options) {
      obj.options.forEach(field => {
        extract(field);
      });
    }
    else if (obj?.fields) {
      obj.fields.forEach(field => {
        extract(field);
      });
    }
    else if (obj?.type === FIELD_TYPES.COLLECTION_REF) {
      result.push({ key: obj.key, collection_id: obj.collection_id, query_params: obj.query_params });
    }
  };

  extract(obj);
  return result;
};

export const isItemMultiLanguageField = (field) => field?.type === FIELD_TYPES.MULTI_LANGUAGE_TEXT || field?.type === FIELD_TYPES.ACTION;

export const getRealFieldValue = (value, language) => (Object.prototype.hasOwnProperty.call(value || {}, 'text') && value?.text !== '')
    || (Object.prototype.hasOwnProperty.call(value || {}, language) && value?.[language] !== '')
    || value?.length > 0
    || (typeof value !== 'object' && value)
    || Number.isFinite(value);

export const isMandatoryField = ({ type, is_mandatory, value, language }) => {
  const val = getRealFieldValue(value, language);
  if (is_mandatory && !val && !isBool(value)) return getMandatoryMessageForType(type, language);
  if (!val && !isBool(value)) return "";
};

export const getMandatoryStar = (isMandatory) => isMandatory ? <span style={{ color: '#EF404B' }}> *</span> : null;
