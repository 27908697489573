import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const UnfoldIcon = () => (<SvgIcon>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15.293 10.207C15.488 10.402 15.744 10.5 16 10.5C16.256 10.5 16.512 10.402 16.707 10.207C17.098 9.81597 17.098 9.18397 16.707 8.79297L12.707 4.79297C12.322 4.40697 11.6981 4.40197 11.305 4.78097L7.30505 8.64297C6.90805 9.02597 6.89705 9.65997 7.28105 10.057C7.66505 10.456 8.29705 10.466 8.69505 10.082L11.988 6.90197L15.293 10.207ZM8.70725 13.7931C8.51225 13.5981 8.25625 13.5001 8.00025 13.5001C7.74425 13.5001 7.48825 13.5981 7.29325 13.7931C6.90225 14.1841 6.90225 14.8161 7.29325 15.2071L11.2932 19.2071C11.6782 19.5931 12.3022 19.5981 12.6952 19.2191L16.6952 15.3571C17.0922 14.9741 17.1032 14.3401 16.7192 13.9431C16.3352 13.5441 15.7032 13.5341 15.3052 13.9181L12.0122 17.0981L8.70725 13.7931Z"
    fill="#2D2C44"
    fillOpacity="0.7"
  />
</SvgIcon>);

export default UnfoldIcon;
