import { Stack, Typography } from "@mui/material";
import React from "react";
import emptyBoxIcon from "../static/images/empty_box.svg";
import underConstruction from "../static/images/under-construction.svg";
import { APP_HEADER_HEIGHT } from "../layout/consts";

const PlaceholderPage = ({ type, height }) => {
  const getImg = () => {
    if (type === "construction") {
      return underConstruction;
    }
    return emptyBoxIcon;
  };
  const getText = () => {
    if (type === "construction") {
      return "Page is under construction";
    }
    return "Nothing here yet";
  };
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: height || `calc(100vh - ${APP_HEADER_HEIGHT}px)` }}
    >
      <img width="102px" height="56px" src={getImg()} alt="" />
      <Typography mt={2} variant="body1" color="text.secondary">
        {getText()}
      </Typography>
    </Stack>
  );
};

export default PlaceholderPage;
