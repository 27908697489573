import React from "react";

const GenesysIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" stroke="#E0E0E0"/>
    <path
      d="M22.9826 26.9796C21.4747 26.9796 19.9621 27.0338 18.4574 26.9656C16.431 26.8743 14.8498 25.9683 13.8015 24.2571C13.2111 23.2954 12.9223 22.244 13.018 21.129C13.1281 19.8514 13.5941 18.7039 14.5147 17.7484C15.3476 16.8843 16.3481 16.3268 17.5464 16.1223C17.9612 16.0511 18.3856 16.0077 18.8069 16.0062C21.6072 15.9969 24.4059 16.0031 27.2061 16C28.3741 16 29.4639 16.2849 30.4388 16.8982C31.3802 17.4913 32.0775 18.309 32.5227 19.3155C32.8993 20.1673 33.078 21.0515 32.9679 21.9807C32.7652 23.7074 31.9275 25.0686 30.4468 26.0458C29.4368 26.7117 28.3055 26.9951 27.0929 26.9951C25.7238 26.9951 24.3532 26.9951 22.9842 26.9951V26.9796H22.9826ZM22.9379 18.1433C21.4556 18.1433 19.9733 18.134 18.4926 18.1464C17.4618 18.1556 16.6321 18.6032 15.9843 19.3574C15.3492 20.0976 15.0875 20.9555 15.2136 21.9203C15.2918 22.5211 15.5088 23.0678 15.9029 23.5324C16.6815 24.4507 17.6852 24.8967 18.925 24.8673C20.0499 24.841 21.1748 24.8595 22.2997 24.8595C23.9479 24.8595 25.5946 24.858 27.2428 24.8595C28.2305 24.8595 29.057 24.5111 29.7846 23.8638C30.9367 22.8371 31.0691 21.0825 30.4755 20.0573C29.8038 18.899 28.8001 18.1742 27.3864 18.1479C25.9057 18.12 24.4218 18.1417 22.9411 18.1417L22.9379 18.1433Z"
      fill="#FF4E1D"/>
    <path
      d="M25.4651 39.9965C24.8478 39.9965 24.2305 40.0043 23.6148 39.9965C22.0331 39.9732 20.6769 39.4203 19.5846 38.3097C18.4556 37.1635 17.9022 35.7733 18.0142 34.1952C18.1581 32.1776 19.1608 30.6383 21 29.6551C21.7276 29.2653 22.5257 29.0525 23.3573 29.0354C24.6639 29.0074 25.9704 28.9904 27.277 29.0059C28.283 29.0183 29.2473 29.2497 30.1365 29.7204C31.1264 30.2454 31.8509 31.0282 32.3706 31.9896C32.7609 32.7119 32.9736 33.4916 32.9976 34.304C33.0679 36.6354 31.6158 38.7555 29.4088 39.6051C28.7228 39.8692 28.0143 40.0028 27.277 39.9981C26.6741 39.995 26.0696 39.9981 25.4667 39.9981L25.4651 39.9965ZM25.4699 37.9556C26.1064 37.9556 26.7429 37.9556 27.381 37.9556C27.5073 37.9556 27.6337 37.9494 27.7568 37.9323C29.3161 37.7195 30.6547 36.4241 30.7762 35.0184C30.8386 34.3086 30.8034 33.6143 30.3924 32.9884C29.6391 31.8405 28.5852 31.2053 27.1635 31.199C26.0024 31.1944 24.8414 31.1835 23.6819 31.199C22.7783 31.2115 21.9675 31.4755 21.299 32.1015C20.5602 32.7927 20.146 33.6205 20.1572 34.6224C20.1636 35.1691 20.3091 35.6941 20.5922 36.1709C21.2511 37.2799 22.2394 37.8904 23.5604 37.9525C24.1953 37.982 24.8334 37.9572 25.4699 37.9572V37.9556Z"
      fill="#FF4E1D"/>
    <path
      d="M29.4859 15C27.7621 14.9933 26.1016 13.6297 26.0033 11.6127C25.92 9.91237 27.414 7.97034 29.4909 8.00034C31.6261 8.03202 32.9052 9.74567 32.9951 11.346C33.0984 13.1847 31.5561 14.9867 29.4842 15H29.4859ZM31.0365 11.4677C31.0215 10.5875 30.3303 9.9057 29.4842 9.91404C28.3983 9.92404 27.9486 10.8742 27.9436 11.6027C27.9386 12.3145 28.7264 13.058 29.4609 13.028C30.3819 12.9913 31.0398 12.3312 31.0365 11.4677Z"
      fill="#FF4E1D"/>
  </g>
</svg>;

export default GenesysIcon;
