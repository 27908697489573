import React from 'react';
import {Stack, Tooltip} from "@mui/material";
import AdminHyroLogo from "../../Logos/AdminHyroLogo";
import DashboardHyroLogo from "../../Logos/DashboardHyroLogo";
import { StyledButton, StyledDivider } from "../HeaderStyles";
import AccountsMenu from "../AccountsMenu";
import WandIcon from "../../../icons/WandIcon";
import UserMenu from "../UserMenu";
import { useIsAdminPanel, useIsCollection, useIsMobile } from "../../../hooks";
import NotificationsPopover from "src/layout/Header/notifications-menu/NotificationsPopover";
import PreviewButton from "../PreviewButton";
import useAppStore from "../../../stores/appStore";
import {setSystemModeSelector} from "../../../stores/selectors/appSelectors";
import { SYSTEM_MODES } from "../../../stores/slices/createAppSlice";
import {
  fetchAllCollectionsSelector,
  hasAssistantConfigSelector
} from "../../../stores/selectors/collectionsSelectors";
import { draftSelector } from "../../../stores/selectors/tripletsSelectors";
import NotificationDotIcon from "src/icons/NotificationDotIcon";
import styled from "@emotion/styled";

const IconWrapper = styled("span")`
  position: absolute;
  top: -7px;
  right: -7px;
`;

const ReadOnlyModeHeader = ({ selectedAssistant }) => {
  const isAdminPanel = useIsAdminPanel();
  const setSystemMode = useAppStore(setSystemModeSelector);
  const isMobile = useIsMobile();
  const fetchCollections = useAppStore(fetchAllCollectionsSelector);
  const draft = useAppStore(draftSelector);
  const isCollectionPage = useIsCollection();
  const hasAssistantConfig = useAppStore(hasAssistantConfigSelector);

  const handleEditModeClick = () => {
    setSystemMode(SYSTEM_MODES.EDIT);
    if (!isCollectionPage && !hasAssistantConfig && draft) {
      fetchCollections('skills');
    }
  };

  return <>
    <Stack direction='row'>
      {isAdminPanel ? <AdminHyroLogo /> : <DashboardHyroLogo />}
      <StyledDivider orientation="vertical" flexItem />
      <AccountsMenu />
      <Stack direction='row' sx={{ ml: 'auto' }} alignItems={'center'} justifyContent='center' gap={1}>
        {selectedAssistant && !isMobile ?
          <>
            <Tooltip
              title={draft ? "Unpublished changes pending" : ""}
              placement="bottom">
              <StyledButton
                data-testid={'edit-mode-button'}
                onClick={handleEditModeClick}
                variant="outlined"

                startIcon={<WandIcon/>}>
                Edit mode
                {!!draft && <IconWrapper><NotificationDotIcon /></IconWrapper>}
              </StyledButton>
            </Tooltip>
            <PreviewButton/>
            <NotificationsPopover/>
          </>
          : null}
        <UserMenu />
      </Stack>
    </Stack>
  </>;
};

export default ReadOnlyModeHeader;
