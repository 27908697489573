import React from "react";

const PatientIdentificationIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#E6FDF2" stroke="#B0F0D2"/>
    <path d="M24 27.5H19.5C18.1044 27.5 17.4067 27.5 16.8389 27.6722C15.5605 28.06 14.56 29.0605 14.1722 30.3389C14 30.9067 14 31.6044 14 33M28 30L30 32L34 28M26.5 19.5C26.5 21.9853 24.4853 24 22 24C19.5147 24 17.5 21.9853 17.5 19.5C17.5 17.0147 19.5147 15 22 15C24.4853 15 26.5 17.0147 26.5 19.5Z" stroke="#3DC684" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>;

export default PatientIdentificationIcon;
