import React from "react";

const WelcomeIllustration = () => (<svg width="308" height="114" viewBox="0 0 308 114" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_1796_3240)">
    <path d="M47.1059 64.7674C47.1059 64.7674 39.8311 80.7414 35.3058 76.9053C31.9261 73.9854 42.0078 54.6906 51.8031 45.3009C61.5983 35.9112 72.8256 33.9072 80.616 33.85C98.889 33.7355 114.928 39.0029 134.748 73.012C134.748 73.012 141.221 91.219 165.107 97.4024C188.994 103.586 219.697 92.9366 246.62 47.362C273.542 1.78749 309 16.9599 309 16.9599M68.1988 63.0279C70.7134 64.8515 59.6262 71.1773 61.3979 74.3686C63.4553 78.1869 74.8283 73.9697 80.7148 61.0902C82.715 56.7591 86.3155 48.3248 98.3742 54.0236C110.433 59.7225 120.777 71.9751 140.094 73.1149C140.094 73.1149 169.698 76.6482 176.042 57.5569C182.385 38.5227 151.353 54.5935 174.67 65.0795C197.988 75.5654 224.62 46.5011 216.447 14.4165C208.275 -17.6682 203.303 -51.2345 231.135 -64M60.8364 72.8505C60.8364 72.8505 56.5944 75.5991 54.0051 72.1773C50.8649 68.0263 61.3322 63.146 61.6627 58.8267M53.081 69.4689C52.2983 71.121 45.4219 71.9755 46.7077 66.2216C47.9936 60.5247 55.2055 57.1635 55.7086 52.8908M36.1856 67.4864C36.1856 67.4864 31.8175 67.9004 30.7109 64.4697C30.012 62.222 31.1768 60.4474 34.5549 60.3291C38.1076 60.2108 40.5538 59.0278 42.8835 56.5435" stroke="#7583F7" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.7104 81.1325C25.4388 81.2499 24.2745 82.4243 24.0805 82.7766C23.8864 83.0897 23.8088 83.168 23.4207 82.894C23.0326 82.6591 22.9938 82.5026 23.0714 82.1894C23.1491 81.9154 23.6148 79.5666 23.4983 79.4492C23.3819 79.3318 22.0624 78.1965 21.946 77.9617C21.8295 77.7268 21.8296 76.9439 22.0624 76.9439C22.2953 76.9047 24.1581 77.4919 24.4297 77.5311C24.7014 77.5311 26.3702 75.2606 26.6419 75.2998C26.9135 75.3389 27.5344 76.0827 27.5344 76.2001C27.5344 76.3175 26.8359 78.5488 26.9135 78.8229C26.9911 79.0577 28.6987 80.6627 28.7375 80.8584C28.7763 81.0542 28.3494 81.5631 28.039 81.5631C27.6897 81.5631 26.1373 80.9759 25.7104 81.1325Z" fill="#EF4068"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M55.436 89.5783C55.2424 89.8145 54.9714 91.4683 54.9714 91.8621C54.9714 92.2558 54.9326 92.3345 54.468 92.3345C54.0033 92.3345 53.8871 92.2558 53.8097 91.9408C53.6935 91.6652 52.8416 89.4208 52.6868 89.3814C52.5319 89.342 50.7894 89.1451 50.5958 89.027C50.3635 88.9089 49.9375 88.2395 50.1311 88.082C50.286 87.9245 52.1834 87.3733 52.4157 87.2551C52.648 87.137 52.7642 84.2626 53.0353 84.1445C53.2676 84.0263 54.2356 84.302 54.2744 84.4201C54.3131 84.5382 54.9714 86.7826 55.165 86.9401C55.3586 87.0976 57.6819 87.4914 57.798 87.6095C57.9529 87.7276 57.8368 88.397 57.6044 88.5545C57.3334 88.8301 55.7071 89.2239 55.436 89.5783Z" fill="#7583F7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M47.9807 100.674C47.8136 100.686 47.0779 101.204 46.9286 101.354C46.7792 101.503 46.7339 101.517 46.5479 101.331C46.3619 101.145 46.3452 101.069 46.4337 100.919C46.4917 100.768 47.0019 99.5753 46.9548 99.4984C46.9078 99.4215 46.2849 98.6493 46.2522 98.527C46.204 98.3892 46.2873 97.9648 46.4246 97.9825C46.5463 97.9848 47.5149 98.5353 47.6527 98.5835C47.7905 98.6317 48.9272 97.5881 49.0805 97.6518C49.2183 97.7 49.5013 98.1921 49.472 98.2524C49.4427 98.3127 48.855 99.4274 48.8728 99.5646C48.8905 99.7018 49.6713 100.781 49.673 100.873C49.6902 100.979 49.3898 101.187 49.237 101.153C49.024 101.149 48.2236 100.648 47.9807 100.674Z" fill="#7583F7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.0521 96.6941C14.9296 97.1512 15.3809 99.7222 15.5623 100.261C15.7817 100.81 15.7789 100.972 15.0967 101.239C14.4145 101.505 14.1968 101.406 13.9263 101.047C13.6179 100.679 11.1372 97.9317 10.8786 97.985C10.6302 98.0001 7.96562 98.6745 7.61348 98.6209C7.22336 98.5572 6.2339 97.8429 6.43975 97.5306C6.60761 97.2081 9.09193 95.3829 9.34598 95.0426C9.63803 94.7125 8.28012 90.5103 8.59995 90.2284C8.91978 89.9466 10.4202 89.8178 10.5517 89.9347C10.6731 90.0898 12.8748 92.9664 13.2445 93.1063C13.6142 93.2462 17.2241 92.5393 17.4798 92.6486C17.7736 92.7682 17.9845 93.8047 17.6924 94.1348C17.4179 94.5513 15.2359 96.0083 15.0521 96.6941Z" fill="#7583F7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.0423 97.9143C33.5091 97.9143 30.9664 99.0451 30.4332 99.3961C29.9001 99.747 29.736 99.786 29.2849 99.2011C28.8338 98.6162 28.8338 98.3432 29.1209 97.9533C29.4079 97.5633 31.6226 94.2878 31.4995 94.0538C31.3765 93.8198 29.9411 91.3242 29.9001 90.8952C29.8591 90.5053 30.3102 89.2965 30.7203 89.4134C31.0894 89.4914 33.7552 91.5191 34.1653 91.6751C34.5754 91.8701 38.6766 89.3744 39.0457 89.6084C39.4558 89.8424 39.989 91.3242 39.9069 91.5191C39.7839 91.7141 37.3642 94.6777 37.3232 95.0677C37.2822 95.4966 39.0867 98.9281 39.0047 99.2401C38.9637 99.552 37.9384 100.059 37.4873 99.864C37.0361 99.747 34.8625 97.9533 34.0423 97.9143Z" fill="#8DBBF2"/>
  </g>
  <defs>
    <clipPath id="clip0_1796_3240">
      <rect width="308" height="114" fill="white"/>
    </clipPath>
  </defs>
</svg>);

export default WelcomeIllustration;
