import * as React from "react";
import { darken, styled } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import {Chip, chipClasses, Typography} from "@mui/material";
import {mandatoryKeysSelector} from "../../stores/selectors/collectionsSelectors";
import useAppStore from "../../stores/appStore";
import {MandatoryBadge} from "../../components/common/MandatoryBadge";

const StyledChip = styled(Chip)`
  width: 46px;
  height: 20px;
  margin-left: 4px;
  .${chipClasses.label} {
    padding: 0;
  }

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const StyledListItemButton = styled(ListItemButton)(
  ({ theme }) =>
    ({ selected }) => ({
      "minHeight": 36,
      "padding": `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      "borderRadius": theme.shape.borderRadius,
      "svg": {
        fill: selected
          ? theme.palette.primary.main
          : theme.palette.text.secondary
      },
      "&:hover": {
        backgroundColor: darken(theme.palette.background.default, 0.04)
      },
      "&.Mui-selected": {
        color: selected
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.light
      }
    })
);

const StyledListItemText = styled(ListItemText)`
  & .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== "open" || prop !== "selected"
})(({ open, selected, theme }) => ({
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  minWidth: 0,
  marginRight: open ? theme.spacing(2) : "auto"
}));

const ListItemContent = ({ navItem, open, navItemIsOpen, currentPage, showIcon }) => {
  const mandatoryKeys = useAppStore(mandatoryKeysSelector);
  const isItemSelected = currentPage === navItem.key;

  return (
    <StyledListItemButton open={open} selected={isItemSelected} data-testid={`navigation-item-${navItem.text}`}>
      {showIcon && (
        <StyledListItemIcon open={open} selected={isItemSelected}>
          {navItem.icon ? <navItem.icon /> : <SvgIcon />}
        </StyledListItemIcon>
      )}
      {open && (
        <>
          <StyledListItemText >
            <Typography variant="body2">{navItem.text}</Typography>
          </StyledListItemText>
          {navItem?.isNew ? <StyledChip color={'secondary'} label={'New!'}/> : null}
          {navItem.children
                    && (navItemIsOpen ? <ExpandLess /> : <ExpandMore />)}
        </>
      )}
      {mandatoryKeys?.includes(navItem.key) && <MandatoryBadge close={!open} />}
    </StyledListItemButton>
  );
};

export default ListItemContent;
