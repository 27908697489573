import React from "react";

const TransferToLiveAgentIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#EDF5FF" stroke="#B7D0F2"/>
    <path d="M29.4996 15L32.9996 18.5M32.9996 18.5L29.4996 22M32.9996 18.5H24.9996M22.2266 25.8631C21.0251 24.6615 20.0763 23.3028 19.3803 21.8532C19.3204 21.7285 19.2905 21.6662 19.2675 21.5873C19.1858 21.3069 19.2445 20.9627 19.4145 20.7253C19.4623 20.6584 19.5195 20.6013 19.6338 20.487C19.9834 20.1374 20.1582 19.9626 20.2725 19.7868C20.7035 19.1239 20.7035 18.2693 20.2725 17.6064C20.1582 17.4307 19.9834 17.2559 19.6338 16.9062L19.4389 16.7114C18.9075 16.1799 18.6417 15.9142 18.3564 15.7699C17.7888 15.4828 17.1185 15.4828 16.551 15.7699C16.2656 15.9142 15.9999 16.1799 15.4684 16.7114L15.3108 16.869C14.7812 17.3986 14.5164 17.6634 14.3141 18.0235C14.0897 18.423 13.9283 19.0435 13.9297 19.5017C13.9309 19.9146 14.011 20.1969 14.1712 20.7613C15.0322 23.7947 16.6567 26.6571 19.0447 29.045C21.4326 31.433 24.295 33.0575 27.3284 33.9185C27.8928 34.0787 28.1751 34.1588 28.588 34.16C29.0462 34.1614 29.6667 34 30.0662 33.7756C30.4263 33.5733 30.6911 33.3085 31.2207 32.7789L31.3783 32.6213C31.9098 32.0898 32.1755 31.8241 32.3198 31.5387C32.6069 30.9712 32.6069 30.3009 32.3198 29.7333C32.1755 29.448 31.9098 29.1822 31.3783 28.6508L31.1835 28.4559C30.8339 28.1063 30.6591 27.9315 30.4833 27.8172C29.8204 27.3862 28.9658 27.3862 28.3029 27.8172C28.1271 27.9315 27.9523 28.1063 27.6027 28.4559C27.4884 28.5702 27.4313 28.6274 27.3644 28.6752C27.127 28.8453 26.7828 28.904 26.5024 28.8222C26.4235 28.7992 26.3612 28.7693 26.2365 28.7094C24.7869 28.0134 23.4282 27.0646 22.2266 25.8631Z" stroke="#5F98E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>;

export default TransferToLiveAgentIcon;
