import React, { useState, useEffect, useRef } from "react";
import { DataGrid, gridClasses, useGridApiRef } from '@mui/x-data-grid';
import { getTextWidth } from "src/utils";
import ThreeDotMenu from "src/pages/KnowledgeEditor/Table/ThreeDotMenu";
import { MenuItem, Stack, Typography, styled, chipClasses } from "@mui/material";
import NothingHereYet from "src/static/images/nothing_here_yet.svg";

const FIRST_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 10;
const PAGINATION_INITIAL_STATE = { page: FIRST_PAGE, pageSize: DEFAULT_ROWS_PER_PAGE };
const FALLBACK_POS_FROM_TOP = 320;
const DEFAULT_EMPTY_TEXT = "Nothing here yet";
const WIDTHS = {
  ARRAY_WIDTH: 180,
  ARRAY_EXTRA_WIDTH: 80,
  COLUMN_MAX_WIDTH: 400,
  EXTRA_PADDING: 32,
  EXTRA_PADDING_FIRST_COLUMN: 44
};

const StyledContainer = styled(Stack, { 
  shouldForwardProp: (props) => !['posFromTop', 'stickyActions', 'clickable'].includes(props)
})(({ posFromTop, stickyActions, clickable }) => 
  ({
    [`& .${gridClasses.root}`]: {
      '--DataGrid-containerBackground': '#FAFAFA',
      // 24px is the desired distance from the bottom of the page
      minHeight: `max(calc(100vh - ${posFromTop}px - 24px), 250px)`,
      [`& .${gridClasses.columnHeader}`]: { 
        '&[aria-colindex="1"]': { paddingLeft: '24px' },
        cursor: 'default',
        "&:focus": { outline: 'none' },
        '&[data-field="actions"]': { 
          position: 'sticky',
          right: -1,
          backgroundColor: '#FAFAFA',
          boxShadow: stickyActions ? '-2px 0px 2px 2px rgba(102, 102, 102, 0.01), -4px 0px 8px 0px rgba(85, 85, 85, 0.05)' : 'none' 
        }
      },
      [`& .${gridClasses.columnHeaderTitle}`]: {
        fontWeight: '600'
      },
      [`& .${gridClasses.cell}`]: {
        padding: '12px',
        minHeight: '59px',
        display: 'flex',
        alignItems: 'center',
        '&[aria-colindex="1"]': { paddingLeft: '24px' },
        '&:focus, &:focus-within': { outline: 'none' },
        '&[data-field="actions"]': { 
          position: 'sticky',
          right: -1, 
          display: 'grid',
          placeContent: 'center',
          backgroundColor: 'white',
          boxShadow: stickyActions ? '-2px 0px 2px 2px rgba(102, 102, 102, 0.01), -4px 0px 8px 0px rgba(85, 85, 85, 0.05)' : 'none' }
      },
      [`& .${gridClasses.row}`]: {
        '&:hover': { 
          backgroundColor: '#f7f7f8',
          '[data-field="actions"]': { 
            backgroundColor: '#f7f7f8',
            boxShadow: 'none'
          }},
        ...(clickable && { 
          cursor: 'pointer',
          [`& .${chipClasses.root}`]: { cursor: 'pointer' }
        })
      },
      [`& .${gridClasses.columnSeparator}`]: {
        [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
          display: 'none'
        }
      }
    }
  }));

const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => !['overrideMaxRowHeight'].includes(prop)
})(({ overrideMaxRowHeight }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  WebkitLineClamp: 3,
  textOverflow: 'ellipsis',
  ...(overrideMaxRowHeight ? {} : { maxHeight: '60px' })
}));

const noRowsOverlay = (noResultsText) => <Stack data-testid='hyro-table-no-results' sx={{ position: 'sticky', inset: 0, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
  <img src={NothingHereYet} alt='' />
  <Typography mt={2} variant="body1" color="text.secondary">
    {noResultsText}
  </Typography>
</Stack>;
  
export default function HyroTable({ 
  columns = [],
  rows = [],
  rowActions,
  onRowClick,
  showPagination = false,
  noResultsText = DEFAULT_EMPTY_TEXT,
  overrideMaxRowHeight = false,
  testId
}) {
  const containerRef = useRef(null);
  const apiRef = useGridApiRef();
  const [isActionsSticky, setIsActionsSticky] = useState(false);
  const [paginationModel, setPaginationModel] = useState(showPagination ? PAGINATION_INITIAL_STATE : {});
  const displayedRows = getDisplayedRows();
  const posFromTop = containerRef.current?.getBoundingClientRect()?.top || FALLBACK_POS_FROM_TOP;
  let normalizedCols = normalizeColumnsWidth(columns);
  normalizedCols = addDefaultRenderCell(normalizedCols);
  const hasRowActions = Array.isArray(rowActions) || displayedRows.some(row => rowActions?.(row));
  
  if (hasRowActions){
    normalizedCols.push(...getActionsColumn());
  }

  const normalizedRows = rows.map((row, index) => ({ index, ...row }));

  useEffect(() => {
    const handleScrollPositionChange = (params) => {
      const gridElement = apiRef.current?.rootElementRef?.current?.querySelector('.MuiDataGrid-virtualScroller');
      
      if (gridElement) {
        const maxScrollLeft = gridElement.scrollWidth - gridElement.clientWidth;
        const left = Math.ceil(params.left);

        setIsActionsSticky(left > 0 && left < maxScrollLeft);
      }
    };
  
    const unsubscribe = apiRef.current.subscribeEvent('scrollPositionChange', handleScrollPositionChange);
  
    return () => unsubscribe();
  }, [apiRef]);

  function getActionsColumn(){
    return [
      // Create space for the actions column to be all the way to the right
      { field: 'filler', headerName: '', flex: 1 },
      {
        field: 'actions',
        headerName: '',
        width: 56,
        renderCell: ({ row, api }) => {
          const computeRowActions = Array.isArray(rowActions) ? rowActions : rowActions?.(row);
          if (!computeRowActions) return null;

          const rowIndex = api.getRowIndexRelativeToVisibleRows(row.id);

          return (
            <ThreeDotMenu
              testId={`${testId}-table-row-${rowIndex}`}
              menuItems={computeRowActions?.map(({ display, id, run }) => (<MenuItem
                key={id} 
                data-testid={`${testId}-three-dot-menu-${display.replace(' ', '-')}`}
                onClick={() => run(row)}
              >
                {display}
              </MenuItem>))}
            />
          );
        }
      }]; 
  }

  function getDisplayedRows(){
    return paginationModel.pageSize > 0 && showPagination
      ? rows.slice(paginationModel.page * paginationModel.pageSize, paginationModel.page * paginationModel.pageSize + paginationModel.pageSize) : rows;
  }

  function normalizeColumnsWidth(columns) {
    return columns?.map(({ field, headerName, width: overrideWidth }, index) => { 
      // find the longest content in the column, if it's an array, calculate the first element's length
      const longestColumnContent = displayedRows.map(row => {
        if (Array.isArray(row[field])) return row[field][0];
        return row[field];
      }).reduce((acc, curr) => {
        let width = getTextWidth({ text: curr, font: '600 14px Inter, sans-serif' });
        // if curr is an object then width should be the largest text width in the object
        if (typeof curr === 'object') {
          width = Object.values(curr).reduce((acc, curr) => {
            const currWidth = getTextWidth({ text: curr, font: '600 14px Inter, sans-serif', extraWidth: WIDTHS.EXTRA_PADDING });
            return currWidth > acc ? currWidth : acc;
          }, 0);
        }

        if (!Array.isArray(curr) && width > acc) return width;
        return acc;
      }, '');

      const isFieldAnArray = displayedRows.some(row => row[field] && Array.isArray(row[field]));
      const columnWidth = getTextWidth({
        text: headerName,
        extraWidth: WIDTHS.EXTRA_PADDING,
        font: '600 14px Inter, sans-serif'
      });

      const actualWidth = columnWidth > longestColumnContent ? columnWidth : longestColumnContent;
      let finalWidth;
      if (isFieldAnArray) {
        finalWidth = actualWidth + WIDTHS.ARRAY_EXTRA_WIDTH;
      } else {
        finalWidth = actualWidth + (index === 0 ? WIDTHS.EXTRA_PADDING_FIRST_COLUMN : WIDTHS.EXTRA_PADDING);
      }
      if (overrideWidth) finalWidth = overrideWidth;
      if (finalWidth > WIDTHS.COLUMN_MAX_WIDTH) finalWidth = WIDTHS.COLUMN_MAX_WIDTH;

      return {
        ...columns[index],
        minWidth: finalWidth,
        flex: 1
      };
    });
  }

  function addDefaultRenderCell(columns) {
    return columns.map((column, colIndex) => ({
      ...column,
      renderCell: (params) => (
        <div data-testid={`row-${params.row.index}-table-cell-${colIndex}`}>
          {column.renderCell ? 
            column.renderCell(params) : 
            <TextWrapper overrideMaxRowHeight={overrideMaxRowHeight}>
              {params.value}
            </TextWrapper>
          }
        </div>
      )
    }));
  }

  return (
    <StyledContainer ref={containerRef} posFromTop={posFromTop} stickyActions={isActionsSticky} clickable={onRowClick}>
      <DataGrid
        apiRef={apiRef}
        rows={normalizedRows}
        columns={normalizedCols}
        onRowClick={onRowClick}
        disableColumnSorting
        disableColumnMenu
        disableVirtualization
        disableColumnResize
        disableRowSelectionOnClick
        hideFooter={!showPagination}
        columnHeaderHeight={48}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 59}
        hideFooterPagination={!showPagination}
        pageSizeOptions={[5, 10, 15, 20, 25]}
        initialState={{
          pagination: { paginationModel: showPagination ? PAGINATION_INITIAL_STATE : {} }
        }}
        slots={{
          noRowsOverlay: () => noRowsOverlay(noResultsText)
        }}
        onPaginationModelChange={model => setPaginationModel(model)}
      />
    </StyledContainer>
  );
}