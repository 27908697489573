import { autocompleteClasses, Checkbox, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Paper from "@mui/material/Paper";
import React from "react";
import { capitalizeAndCleanUnderscore } from "../../../utils";
import styled from "@emotion/styled";
import PublishStatus from "../../collections/PublishStatus";
import { StyledChip, StyledAutocomplete, StyledTextField } from "../styles";
import ChevronDown from "../../../icons/ChevronDown";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const MultipleOptions = ({
  id,
  options,
  label,
  value,
  onChange,
  error,
  isReadOnly,
  status,
  displayKey = "display_key",
  disabled,
  isFieldReadOnly,
  showLabel,
  testId
}) => (
  <StyledAutocomplete
    multiple
    disabled={disabled}
    readOnly={isReadOnly}
    isFieldReadOnly={isFieldReadOnly}
    disableCloseOnSelect
    id={id}
    popupIcon={<ChevronDown style={{ top: 'unset', right: 12 }} />}
    value={value || []}
    PaperComponent={StyledPaper}
    componentsProps={{
      paper: { elevation: 3 }
    }}
    options={options}
    onChange={(_, value, reason, detail) => !isReadOnly && onChange(value, reason === 'removeOption' ? detail.option : null)}
    getOptionLabel={option =>
      capitalizeAndCleanUnderscore(option[displayKey])}
    isOptionEqualToValue={(option, value) => {
      const selectedOption = options.find(({ key }) => key === value);
      return option[displayKey] === value[displayKey] || option[displayKey] === selectedOption?.[displayKey];
    }} renderOption={(props, option, { selected }) => (
      <li {...props} data-testid={`${testId}-option-${props['data-option-index']}`}>
        <Checkbox
          icon={icon}
          disableRipple
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {capitalizeAndCleanUnderscore(option[displayKey])}
      </li>
    )}
    renderTags={(value, getTagProps) => value.map((option, index) => {
      const selectedOption = options.find(({ key }) => key === option);
      const label = selectedOption?.[displayKey] || option[displayKey];
      return (
        <StyledChip
          key={option}
          data-testid={`${testId}-chip-${index}`}
          variant="outlined"
          deleteIcon={isReadOnly ? <></> : null}
          label={capitalizeAndCleanUnderscore(label)}
          {...getTagProps({ index })}
        />
      );
    })}
    renderInput={params => (
      <StyledTextField
        {...params}
        FormHelperTextProps={{ 'data-testid': `${testId}-helper` }}
        inputProps={{ ...params.inputProps, 'data-testid': testId }}
        isFieldReadOnly={isFieldReadOnly}
        readOnly={isReadOnly}
        InputProps={{ ...params.InputProps, sx: { borderRadius: "4px" } }}
        variant="outlined"
        error={!!error}
        helperText={(error ? <Typography variant="caption">{error}</Typography> :
          <PublishStatus status={status} />)}
        label={showLabel ? label : ""}
        placeholder={value?.length > 0 ? '' : label}
      />
    )}
  />
);

const StyledPaper = styled(Paper)`
  & .${autocompleteClasses.listbox} .${autocompleteClasses.option}.${autocompleteClasses.focused}[aria-selected='true'],
  & .${autocompleteClasses.listbox} .${autocompleteClasses.option}[aria-selected='true'] {
    background: transparent;

    &:hover {
      background: #2d2c440a;
    }
  }
`;

export default MultipleOptions;
