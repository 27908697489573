import React from "react";

const SpanishFlagIcon = ({style, testId}) => <svg data-testid={testId} width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
  <rect x="1.5" y="5" width="21" height="15" rx="1.5" fill="white"/>
  <mask id="mask0_2041_19694" maskUnits="userSpaceOnUse" x="1" y="5" width="22" height="15">
    <rect x="1.5" y="5" width="21" height="15" rx="1.5" fill="white"/>
  </mask>
  <g mask="url(#mask0_2041_19694)">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 9H22.5V5H1.5V9Z" fill="#DD172C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 20H22.5V16H1.5V20Z" fill="#DD172C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 16H22.5V9H1.5V16Z" fill="#FFD133"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 12H8V12.5H7V12Z" fill="#FFEDB1"/>
    <path d="M6.04426 12.0208C6.03212 11.875 6.14714 11.75 6.2934 11.75H7.7066C7.85286 11.75 7.96788 11.875 7.95574 12.0208L7.82728 13.5623C7.79488 13.951 7.46993 14.25 7.07987 14.25H6.92013C6.53007 14.25 6.20512 13.951 6.17272 13.5623L6.04426 12.0208Z" stroke="#A41517" strokeWidth="0.5"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6 12.5H8V13H7.5L7 14L6.5 13H6V12.5Z" fill="#A41517"/>
    <rect x="4.5" y="11" width="1" height="3.5" rx="0.5" fill="#A41517"/>
    <rect x="8.5" y="11" width="1" height="3.5" rx="0.5" fill="#A41517"/>
    <path d="M6 10.8C6 10.3582 6.35817 10 6.8 10H7.2C7.64183 10 8 10.3582 8 10.8C8 10.9105 7.91046 11 7.8 11H6.2C6.08954 11 6 10.9105 6 10.8Z" fill="#A41517"/>
  </g>
</svg>;

export default SpanishFlagIcon;
