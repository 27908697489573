import React from "react";

const ScreenIcon = ({ fill }) => (<svg
  width="23"
  height="20"
  viewBox="0 0 23 20"
  fill={fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20.6667 0H2.66675C1.56675 0 0.666748 0.9 0.666748 2V14C0.666748 15.1 1.56675 16 2.66675 16H9.66675V18H7.66675V20H15.6667V18H13.6667V16H20.6667C21.7667 16 22.6667 15.1 22.6667 14V2C22.6667 0.9 21.7667 0 20.6667 0ZM20.6667 14H2.66675V2H20.6667V14Z"
    fill={fill}
    fillOpacity="1"
  />
</svg>);

export default ScreenIcon;
