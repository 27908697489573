import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import SearchBar from "src/components/common/SearchBar";

const PREFIX = "HyroHeader";

const classes = {
  headingText: `${PREFIX}-headingText`,
  button: `${PREFIX}-button`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.headingText}`]: {
    fontWeight: 500,
    fontSize: 20,
    margin: 0
  }
}));

const HyroHeader = ({ title, handleSearch, buttonSpec, testId }) => (
  <StyledGrid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
    <Grid item xs={6}>
      <h1 className={classes.headingText}>{title}</h1>
    </Grid>
    <Stack
      justifyContent="flex-end"
      alignItems="center"
      direction="row"
      gap={1.5}
    >
      <SearchBar onChange={handleSearch} />
      {buttonSpec
        ? (
          <Button
            data-testid={`${testId}-create-button`}
            className={classes.button}
            startIcon={buttonSpec.icon}
            color="primary"
            size="medium"
            variant="contained"
            onClick={buttonSpec.run}
            disabled={buttonSpec.disabled}
          >
            {buttonSpec.text}
          </Button>
        )
        : null}
    </Stack>
  </StyledGrid>);

export default HyroHeader;
