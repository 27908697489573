import React from "react";

const BranchDeployerIcon = ({ fill }) => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd"
    d="M6.80019 5.00002C6.80019 5.66277 7.33746 6.20004 8.00021 6.20004C8.66296 6.20004 9.20023 5.66277 9.20023 5.00002C9.20023 4.33727 8.66296 3.8 8.00021 3.8C7.33746 3.8 6.80019 4.33727 6.80019 5.00002ZM8.00021 2C6.34335 2 5.00019 3.34315 5.00019 5.00002C5.00019 6.34325 5.88298 7.4803 7.10003 7.86264V16.1375C5.88288 16.5198 5 17.6569 5 19.0002C5 20.6571 6.34315 22.0002 8.00002 22.0002C9.3434 22.0002 10.4806 21.1173 10.8628 19.9H12.0001C14.7063 19.9 16.9001 17.7062 16.9001 15V13.8627C18.1172 13.4804 19 12.3434 19 11.0001C19 9.34322 17.6569 8.00006 16 8.00006C14.3432 8.00006 13 9.34322 13 11.0001C13 12.3434 13.8829 13.4805 15.1001 13.8628V15C15.1001 16.7121 13.7122 18.1 12.0001 18.1H10.8626C10.5695 17.1671 9.83298 16.4306 8.90003 16.1375V7.86275C10.1173 7.48053 11.0002 6.34339 11.0002 5.00002C11.0002 3.34315 9.65707 2 8.00021 2ZM6.8 19.0002C6.8 19.663 7.33727 20.2002 8.00002 20.2002C8.66277 20.2002 9.20004 19.663 9.20004 19.0002C9.20004 18.3375 8.66277 17.8002 8.00002 17.8002C7.33727 17.8002 6.8 18.3375 6.8 19.0002ZM16 12.2001C15.3373 12.2001 14.8 11.6628 14.8 11.0001C14.8 10.3373 15.3373 9.80006 16 9.80006C16.6628 9.80006 17.2 10.3373 17.2 11.0001C17.2 11.6628 16.6628 12.2001 16 12.2001Z"
    fill={fill} />
</svg>);

export default BranchDeployerIcon;


