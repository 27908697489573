import { AppWrapper, ContentWrapper } from "../LayoutWrappers";

import AccountAssistants from "../../pages/AccountAssistants";
import ErrorBoundary from "../ErrorBoundries";
import React from "react";
import { useLocation } from "react-router-dom";

const AccountLayout = () => {
  const location = useLocation();
  return (
    <AppWrapper className="app">
      <ContentWrapper component="main">
        <ErrorBoundary key={location.pathname}>
          <AccountAssistants />
        </ErrorBoundary>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default AccountLayout;
