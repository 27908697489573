import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const InsightsIcon = () => (<SvgIcon>
  <path
    d="M2.74487 19L9.74487 12L14.7449 17L20.7449 11"
    stroke="currentColor"
    strokeOpacity="1"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="transparent"
  />
  <path d="M14.7451 10L15.6851 7.93L17.7451 7L15.6851 6.07L14.7451 4L13.8251 6.07L11.7451 7L13.8251 7.93L14.7451 10Z" />
  <path d="M3.24487 12L3.74487 10L5.74487 9.5L3.74487 9L3.24487 7L2.74487 9L0.744873 9.5L2.74487 10L3.24487 12Z" />
</SvgIcon>);

export default InsightsIcon;
