import { AppWrapper } from "../LayoutWrappers";
import MuiDivider from "@mui/material/Divider";
import React, {Fragment, useEffect} from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useAppStore from "src/stores/appStore";
import {setHeaderSkeletonSelector} from "src/stores/selectors/appSelectors";
import ForbiddenErrorPage from "src/layout/ErrorPages/ForbiddenErrorPage";
import NotFoundErrorPage from "src/layout/ErrorPages/NotFoundErrorPage";
import InternalErrorPage from "src/layout/ErrorPages/InternalErrorPage";
import GenericErrorPage from "src/layout/ErrorPages/GenericErrorPage";

const PageWrapper = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  height: "100%",
  alignItems: "center",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    width: "540px",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    justifyContent: "center"
  }
}));

const TextWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    textAlign: "center"
  }
}));

const ErrorCode = styled("div")(({ theme }) => ({
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "56px",
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.5)
}));

const ErrorExplanation = styled("div")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  whiteSpace: "nowrap",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(3)
}));

const StyledLink = styled("a")(({ theme }) => ({
  "fontWeight": 500,
  "fontSize": "14px",
  "lineHeight": "20px",
  "color": theme.palette.primary.main,
  "textDecoration": "none",
  ":hover": { textDecoration: "underline" }
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  backgroundColor: theme.palette.primary.main
}));

const LinksWrapper = styled("div")(({theme}) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    justifyContent: "center",
    gap: theme.spacing(1)
  }
}));

export const getErrorPageFromStatus = (status) => {
  switch (status) {
  case 403:
    return ForbiddenErrorPage;
  case 404:
    return NotFoundErrorPage;
  case 500:
    return InternalErrorPage;
  default:
    return GenericErrorPage;
  }
};

const ErrorPage = ({ code, message, explanation, Icon, links }) => {
  const setShowHeaderSkeleton = useAppStore(setHeaderSkeletonSelector);

  useEffect(() => {
    setShowHeaderSkeleton(false);
  }, [setShowHeaderSkeleton]);

  return (<AppWrapper id="errorPage" errorPage>
    <PageWrapper>
      <TextWrapper>
        <ErrorCode>{code}</ErrorCode>
        <ErrorMessage variant="h6">{message}</ErrorMessage>
        <ErrorExplanation>{explanation}</ErrorExplanation>
        <LinksWrapper>
          {links.map((link, index) => (
            <Fragment key={'support-link-' + index}>
              <StyledLink href={link.url}>{link.text}</StyledLink>
              {index < links.length - 1 && (
                <Divider orientation="vertical" flexItem/>
              )}
            </Fragment>
          ))}
        </LinksWrapper>
      </TextWrapper>
      <Icon/>
    </PageWrapper>
  </AppWrapper>);
};

export default ErrorPage;
