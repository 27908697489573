import { REFRESH_PAGE } from "./DraftHeaderConsts";
import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import {
  draftSelector,
  isPublishingTripletsSelector,
  isSavingTripletsSelector
} from "../../../../../stores/selectors/tripletsSelectors";
import { isAssistantPreviewableSelector, isReadOnlyModeSelector } from "../../../../../stores/selectors/appSelectors";
import { DIALOG_VARIANTS } from "../../../../../components/common/Dialogs/DialogConsts";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";
import DraftButtons from "./DraftButtons";
import HyroFeedbackMessage from "../../../../../components/hyro-components/HyroFeedbackMessage";
import { SEVERITY_VARIANTS } from "../../../../../utils";
import { assoc } from "ramda";
import useAppStore from "../../../../../stores/appStore";
import useDraftPublishHeader from "./useDraftPublishHeader";
import { isUpdatingCollectionSelector } from "../../../../../stores/selectors/collectionsSelectors";
import StatusBar from "../../../../../components/common/StatusBar";
import PortalTextContent from "./HeaderTextContent";

const DraftPublishHeader = () => {
  const isPublishingTriplets = useAppStore(isPublishingTripletsSelector);
  const isUpdatingCollection = useAppStore(isUpdatingCollectionSelector);
  const isSavingTriplets = useAppStore(isSavingTripletsSelector);
  const draft = useAppStore(draftSelector);
  const isReadOnly = useAppStore(isReadOnlyModeSelector);
  const isAssistantPreviewable = useAppStore(isAssistantPreviewableSelector);

  const [snackbarAlert, setSnackbarAlert] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const headerRef = useRef();
  const inProgressOrTestIsRunning
    = isSavingTriplets
    || isPublishingTriplets
    || isUpdatingCollection;

  const displayPreviewButtons = isAssistantPreviewable && !isReadOnly;

  useEffect(() => {
    if (isSavingTriplets) {
      setSnackbarAlert({
        open: true,
        message: "Saving changes",
        severity: SEVERITY_VARIANTS.SUCCESS
      });
    }
  }, [setSnackbarAlert, isSavingTriplets]);

  const handleCloseSnackbar = setSnackbarAlert =>
    setSnackbarAlert(assoc("open", false));

  const draftErrorsDialog = useDraftPublishHeader();

  if (draftErrorsDialog.open) {
    return (
      <Dialog
        open={draftErrorsDialog.open}
        title={draftErrorsDialog.title}
        submitButtonText={REFRESH_PAGE}
        handleSubmit={() => location.reload()}
        handleCancel={draftErrorsDialog.handleCancel}
        variant={DIALOG_VARIANTS.transactional}
      >
        <Typography>{draftErrorsDialog.text}</Typography>
      </Dialog>
    );
  }

  return draft || isReadOnly
    ? (
      <>
        <StatusBar
          isReadOnly={isReadOnly}
          mobileChildren={
            <>
              {isReadOnly
                ? "Read only mode"
                : "Draft mode - please open on a larger screen"}
            </>}
          headerRef={headerRef}
        >
          <PortalTextContent {...{ isSavingTriplets, isReadOnly }} />
          {!isReadOnly && (
            <DraftButtons
              {...{
                inProgressOrTestIsRunning,
                setSnackbarAlert,
                displayPreviewButtons,
                handleCloseSnackbar
              }}
            />
          )}
        </StatusBar>
        <HyroFeedbackMessage
          showFeedback={snackbarAlert.open}
          closeFeedback={() => handleCloseSnackbar(setSnackbarAlert)}
          message={snackbarAlert.message}
          severity={snackbarAlert.severity}
        />
      </>
    )
    : null;
};

export default DraftPublishHeader;
