import { DRAFT_ERRORS } from "./DraftHeaderConsts";
import useAppStore from "../../../../../stores/appStore";
import {
  failedToCreateDraftSelector,
  failedToPublishDraftSelector,
  setFailedToCreateDraftSelector,
  setFailedToPublishDraftSelector
} from "../../../../../stores/selectors/tripletsSelectors";

import {
  failedToUpdateDraftSelector,
  setFailedToUpdateDraftSelector
} from "../../../../../stores/selectors/collectionsSelectors";
const useDraftPublishHeader = () => {
  const failedToCreateDraft = useAppStore(failedToCreateDraftSelector);
  const setFailedToUpdateDraft = useAppStore(setFailedToUpdateDraftSelector);
  const failedToPublishDraft = useAppStore(failedToPublishDraftSelector);
  const setFailedToCreateDraft = useAppStore(setFailedToCreateDraftSelector);
  const failedToUpdateDraft = useAppStore(failedToUpdateDraftSelector);
  const setFailedToPublishDraft = useAppStore(
    setFailedToPublishDraftSelector
  );
  let dialogContent = {};
  if (failedToCreateDraft) {
    dialogContent = {
      title: DRAFT_ERRORS.CONFLICT.title,
      text: DRAFT_ERRORS.CONFLICT.text,
      open: true,
      handleCancel: () => {
        setFailedToCreateDraft(false);
      }
    };
  }
  if (failedToPublishDraft) {
    dialogContent = {
      title: DRAFT_ERRORS.PUBLISH.title,
      text: DRAFT_ERRORS.PUBLISH.text,
      open: true,
      handleCancel: () => {
        setFailedToPublishDraft(false);
      }
    };
  }
  if (failedToUpdateDraft) {
    dialogContent = {
      title: DRAFT_ERRORS.UPDATE.title,
      text: DRAFT_ERRORS.UPDATE.text,
      open: true,
      handleCancel: () => {
        setFailedToUpdateDraft(false);
      }
    };
  }
  return dialogContent;
};

export default useDraftPublishHeader;
