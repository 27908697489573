import React from "react";

const PlaygroundIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#EEEEFF" stroke="#DEDFFF"/>
    <path d="M24 14L27.6 17.6C30 11.3 36.7 18 30.4 20.4L34 24L30.4 27.6C28 21.3 21.3 28 27.6 30.4L24 34L20.4 30.4C18 36.7 11.3 30 17.6 27.6L14 24L17.6 20.4C20 26.7 26.7 20 20.4 17.6L24 14Z" stroke="#7583F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>;

export default PlaygroundIcon;
