import {
  Dialog, Link, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography
} from "@mui/material";
import React, {useState} from "react";
import {Button} from "@hyro/ui";
import useBranchDeployerDialog from "./useBranchDeployerDialog";
import MultipleOptions from "../common/fields/MultipleOptions";
import {DIALOG_TYPES, FIELD_TYPES} from "./BranchDeployerConsts";
import useAppStore from "../../stores/appStore";
import {LoadingButton} from "@mui/lab";
import { styled } from '@mui/system';
import { branchesReleaseNamesSelector } from "src/stores/selectors/branchDeployerSelector";

const BranchDeployerDialog = ({
  open,
  type,
  data,
  onClose,
  onSubmit
}) => {
  const branchesReleaseNames = useAppStore(branchesReleaseNamesSelector);
  const {dialogContent} = useBranchDeployerDialog({
    type, data
  });
  const [bdData, setBdData] = useState(data);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const formatAndValidateString = (input) => {
    const formattedString = input.replace(/ /g, "-");
    const isValid = /^[a-zA-Z0-9-]+$/.test(formattedString);

    return {
      formattedString,
      isValid
    };
  };

  const validations = {
    [FIELD_TYPES.RELEASE_NAME]: (value) => {
      const {formattedString, isValid} = formatAndValidateString(value);
      if (!isValid) {
        return "Release name can only contain alphanumeric characters.";
      }
      if (formattedString.length > 33) {
        return "Release name must not exceed 33 characters.";
      }
      if (branchesReleaseNames.includes(formattedString)) {
        return "Release name already exists.";
      }
      return "";
    },
    [FIELD_TYPES.BOT]: (value, bdData) => {
      if (bdData.assistants_tag && (!value)) {
        return "When you add an assistant tag, you must also add an assistant.";
      }
      return "";
    }
  };

  const handleSaveButtonClicked = async () => {
    setIsLoading(true);
    await onSubmit(bdData, type);
    setIsLoading(false);
  };
  const handleChange = (e) => {
    const {value, name} = e.target;
    let formattedValue = value;
    if (name === FIELD_TYPES.RELEASE_NAME) {
      formattedValue = formatAndValidateString(value).formattedString;
    }

    const newBdData = {
      ...bdData,
      [name]: formattedValue
    };

    setBdData(newBdData);

    const newErrors = {
      ...errors,
      [name]: validations[name] ? validations[name](formattedValue, newBdData) : ""
    };

    if (name === FIELD_TYPES.ASSISTANTS) {
      newErrors[FIELD_TYPES.BOT] = validations[FIELD_TYPES.BOT](newBdData[FIELD_TYPES.BOT], newBdData);
    }

    setErrors(newErrors);

  };

  const isSaveDisabled = type === DIALOG_TYPES.create.id && (Object.values(errors).some(error => error) || !bdData[FIELD_TYPES.RELEASE_NAME]);

  return <>
    <Dialog
      open={open}
      title={dialogContent.title}
      onClose={onClose}
    >
      <DialogTitle>{dialogContent?.title}</DialogTitle>
      <DialogContent>
        <Typography
          style={{whiteSpace: "pre-line"}}
          variant="body1"
          color="text.primary"
        >
          {dialogContent?.subtitle}
        </Typography>
        {type === DIALOG_TYPES.viewDetails.id ?
          <Stack gap={2} my={3}>
            {dialogContent.data?.map((field) => (
              bdData[field.id] && (
                <Typography key={field.id} variant="body1" color="textPrimary">
                  <FieldLabel>{field.label}:</FieldLabel>
                  {field.label.toLowerCase().endsWith('url') ? (
                    <ClickableLink href={bdData[field.id]} target="_blank" rel="noopener noreferrer">
                      {bdData[field.id]}
                    </ClickableLink>
                  ) : (
                    bdData[field.id]
                  )}
                </Typography>
              )
            ))}
          </Stack>
          :
          <Stack gap={2} my={3}>
            {dialogContent.data?.map((field) =>
              field.options ?
                <MultipleOptions
                  value={bdData[field.id]}
                  displayKey="key"
                  onChange={(newValue) => handleChange({
                    target: {
                      value: newValue?.map(option => option?.key || option),
                      name: field.id
                    }
                  })}
                  key={field.id}
                  options={field.options.map(option => ({
                    key: option
                  }))}
                  label={field.label}
                  name={field.id}
                  disabled={type === DIALOG_TYPES.viewDetails.id}
                >
                </MultipleOptions> :
                <TextField
                  disabled={type === DIALOG_TYPES.viewDetails.id || type === DIALOG_TYPES.edit.id && field.id === FIELD_TYPES.RELEASE_NAME}
                  name={field.id}
                  key={field.id}
                  label={field.label}
                  value={bdData[field.id]}
                  onChange={handleChange}
                  error={!!errors?.[field.id]}
                  helperText={errors?.[field.id]}
                />)
            }
          </Stack>}
      </DialogContent>
      <DialogActions>
        <Button data-testid={`cancel-${type}-bd-button`} onClick={onClose}>Cancel</Button>
        {type !== DIALOG_TYPES.viewDetails.id &&
          <LoadingButton
            data-testid={`${type}-bd-button`}
            variant="contained"
            disabled={isSaveDisabled}
            onClick={handleSaveButtonClicked}
            loading={isLoading}
          >
            {dialogContent.actionButtonText}
          </LoadingButton>}
      </DialogActions>
    </Dialog>
  </>;
};



const FieldLabel = styled('span')(({theme}) => ({
  fontWeight: 'bold',
  marginRight: theme.spacing(1)
}));

const ClickableLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));
export default BranchDeployerDialog;
