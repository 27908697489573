import React from "react";

const LinkToChatIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8391_169)">
      <circle cx="24" cy="24" r="23.5" fill="#F4EBFF" stroke="#DFC9FC"/>
      <path
        d="M33 24C33 25.6802 33 28.7202 32.673 29.362C32.3854 29.9265 31.9265 30.3854 31.362 30.673C30.7202 31 29.8802 31 28.2 31H25.6837C25.0597 31 24.7477 31 24.4492 31.0613C24.1844 31.1156 23.9282 31.2055 23.6875 31.3285C23.4162 31.4671 23.1725 31.662 22.6852 32.0518L20.2998 33.9602C19.8837 34.2931 19.6756 34.4595 19.5005 34.4597C19.3483 34.4599 19.2042 34.3906 19.1092 34.2716C19 34.1348 19 33.8684 19 33.3355V31C18.07 31 17.605 31 17.2235 30.8978C16.1883 30.6204 15.3796 29.8117 15.1022 28.7765C15 28.395 15 27.93 15 27V20.8C15 19.1198 15 18.2798 15.327 17.638C15.6146 17.0735 16.0735 16.6146 16.638 16.327C17.2798 16 20.3198 16 22 16"
        stroke="#9E77ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M24.6794 18.9791L23.8544 19.804C22.7154 20.943 22.7154 22.7898 23.8544 23.9288C24.9934 25.0678 26.8402 25.0678 27.9792 23.9288L28.8041 23.1039M27.9792 15.6792L28.8041 14.8543C29.9432 13.7152 31.7899 13.7152 32.9289 14.8543C34.068 15.9933 34.068 17.84 32.9289 18.9791L32.104 19.804M26.35 21.4332L30.4333 17.3499"
        stroke="#9E77ED" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_8391_169">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default LinkToChatIcon;
