import React from "react";

const NotFoundIcon = () => (<svg
  width="192"
  height="168"
  viewBox="0 0 192 168"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M126.054 20.2755C124.546 20.1841 123.199 20.1024 122.077 20.0245C107.854 19.2155 100.981 18.2416 94.82 17.3686C89.1329 16.5628 84.0523 15.8429 74.3571 15.418C70.9584 15.2562 65.8246 14.4702 60.0369 13.5842C46.2529 11.4739 28.7596 8.79579 22.1611 12.629C14.6867 17.188 14.4873 37.1586 14.3587 50.041C14.3239 53.525 14.2943 56.4905 14.1273 58.4925C13.5931 64.1829 12.804 70.8772 11.9757 77.9037C10.347 91.7205 8.56696 106.821 8.27587 118.098C8.15537 121.721 8.34123 125.817 11.3247 128.776C14.0019 131.264 17.6817 132.302 21.4248 133.166C49.776 139.921 70.4078 141.287 95.103 142.922C103.395 143.47 112.145 144.05 121.799 144.873C127.758 147.208 137.62 151.419 140.987 162.651C145.984 179.324 152.161 152.872 154.339 139.451C154.308 139.44 154.277 139.429 154.246 139.417C157.301 127.863 158.379 117.243 159.668 104.544C159.927 101.985 160.196 99.3419 160.49 96.5895C161.208 90.0597 162.085 84.5706 162.933 79.2594C164.171 71.5088 165.349 64.1371 165.882 54.463C166.059 51.4539 166.358 48.712 166.632 46.2051C167.786 35.6174 168.483 29.2229 157.618 24.6012C151.227 21.802 135.49 20.8478 126.054 20.2755Z"
    fill="#4548C4"
  />
  <path
    d="M152.263 3.01511C152.135 2.02042 152.948 1.1225 154.079 1.00954C155.21 0.896587 156.231 1.61137 156.36 2.60606C156.426 3.12158 156.494 3.63756 156.563 4.15424C156.809 6.01655 157.056 7.88791 157.232 9.77912C157.325 10.7769 156.481 11.6519 155.346 11.7336C154.211 11.8153 153.216 11.0727 153.123 10.0749C152.954 8.25135 152.717 6.46063 152.472 4.60825C152.403 4.08288 152.333 3.55256 152.263 3.01511Z"
    fill="#8DBBF2"
  />
  <path
    d="M179.207 6.28414C178.313 5.6643 177.017 5.79907 176.312 6.58517C175.416 7.58466 174.536 8.53258 173.644 9.49363L173.639 9.49885C172.752 10.455 171.852 11.4243 170.933 12.4496C170.228 13.2357 170.381 14.3755 171.275 14.9953C172.169 15.6152 173.465 15.4804 174.17 14.6943C175.066 13.6948 175.946 12.7469 176.838 11.7858L176.843 11.7806C177.73 10.8245 178.63 9.85503 179.549 8.82983C180.254 8.04373 180.101 6.90399 179.207 6.28414Z"
    fill="#8DBBF2"
  />
  <path
    d="M187.683 20.5256C188.715 20.1039 189.941 20.498 190.421 21.4059C190.9 22.3139 190.452 23.3917 189.42 23.8135L179.837 27.7275C178.805 28.1492 177.579 27.7551 177.099 26.8472C176.62 25.9392 177.068 24.8614 178.1 24.4396L187.683 20.5256Z"
    fill="#8DBBF2"
  />
  <path
    d="M179.352 42.0675C178.312 41.6603 177.845 40.5888 178.308 39.6743C178.771 38.7598 179.989 38.3486 181.029 38.7558C183.808 39.8441 186.468 40.9918 189.097 42.2212C189.446 42.3668 189.774 42.5283 190.038 42.658L190.062 42.6699C190.365 42.8192 190.592 42.9296 190.811 43.0199C191.842 43.4439 192.288 44.5228 191.805 45.4297C191.323 46.3365 190.096 46.7279 189.065 46.3038C188.698 46.1528 188.35 45.9818 188.073 45.8457L188.049 45.8338C187.746 45.6846 187.519 45.5741 187.3 45.4838C187.268 45.4706 187.236 45.4566 187.204 45.4419C184.642 44.2424 182.054 43.1256 179.352 42.0675Z"
    fill="#8DBBF2"
  />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M79.2445 69.786C76.6459 95.181 82.8598 96.9861 82.8598 96.9861C91.4994 99.2386 101.194 46.8749 89.8086 44.5568C82.0224 42.9226 81.4368 48.5851 79.3941 68.3406C79.3451 68.8143 79.2953 69.296 79.2445 69.786ZM88.6904 110.716C87.129 116.227 84.7396 117.039 80.4432 114.535C76.2372 112.041 75.186 106.897 79.9899 104.271C84.6839 101.8 90.2422 105.287 88.6904 110.716Z"
    fill="white"
  />
</svg>);

export default NotFoundIcon;
