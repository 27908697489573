
export const BRANCH_DEPLOYER_FIELDS = [
  {id: 'release_name', label: 'Release Name'},
  {id: 'dashboard_client_tag', label: 'Dashboard Client'},
  {id: 'dashboard_server_tag', label: 'Dashboard Server'},
  {id: 'bot', label: 'Assistants Name'},
  {id: 'assistants_tag', label: 'Assistants'},
  {id: 'nlu_runtime_tag', label: 'NLU Runtime'},
  {id: 'widget_server_tag', label: 'Widget Server'},
  {id: 'widget_client_tag', label: 'Widget Client'},
  {id: 'nlu_runtime_modes', label: 'NLU Runtime Modes', options: ["preview", "dedicated", "api"]},
  {id: 'ttl_hours', label: 'TTL (hours)'}
];

export const BRANCH_DEPLOYER_PRODUCTION_FIELDS = [
  {id: 'release_name', label: 'Release Name'},
  {id: 'bot', label: 'Assistants Name'},
  {id: 'assistants_tag', label: 'Assistants'},
  {id: 'ttl_hours', label: 'TTL (hours)'}
];

export const VIEW_DETAILS_FIELDS = [
  {id: 'dashboard-server-url', label: 'Dashboard Server url'},
  {id: 'dashboard-client-url', label: 'Dashboard Client url'},
  {id: 'assistants-api-url', label: 'Assistants API url'},
  {id: 'assistants-preview-url', label: 'Assistants preview url'},
  {id: 'assistants-url', label: 'Assistants url'},
  {id: 'widget-server-url', label: 'Widget Server url'},
  {id: 'widget_client_url', label: 'Widget Client url'}
];

export const BRANCH_DEPLOYER_INITIAL_DATA = {
  release_name: '',
  dashboard_client_tag: '',
  dashboard_server_tag: '',
  bot: '',
  assistants_tag: '',
  nlu_runtime_tag: '',
  widget_server_tag: '',
  widget_client_tag: '',
  nlu_runtime_modes: [],
  ttl_hours: '48'
};

export const FIELD_TYPES = {
  RELEASE_NAME: 'release_name',
  DASHBOARD_CLIENT: 'dashboard_client_tag',
  DASHBOARD_SERVER: 'dashboard_server_tag',
  BOT: 'bot',
  ASSISTANTS: 'assistants_tag',
  NLU_RUNTIME: 'nlu_runtime_tag',
  WIDGET_SERVER: 'widget_server_tag',
  WIDGET_CLIENT: 'widget_client_tag',
  NLU_RUNTIME_MODES: 'nlu_runtime_modes',
  TTL: 'ttl_hours'
};

export const COLUMNS = {
  release_name: {id: 'release_name', label: 'Release Name'},
  link: {id: 'dashboard-client-url', label: 'Dashboard'},
  chat: {id: 'snippet', label: 'Snippet'},
  voice: {id: 'snippet', label: 'Voice'},
  expired: {id: 'expired', label: 'Expires in'},
  services: {id: 'services', label: 'Services'},
  userEmail: {id: 'createdBy', label: 'Creator'},
  editorEmail: {id: 'lastUpdatedBy', label: 'Last Editor'},
  status: {id: 'status', label: 'Status'}
};

export const KEYS_TO_FILTER = Object.keys(BRANCH_DEPLOYER_INITIAL_DATA);
export const DIALOG_TYPES = {
  create: {id: 'create', display: 'Create'},
  edit: {id: 'edit', display: 'Edit'},
  reactivate: {id: 'reactivate', display: 'Reactivate'},
  viewDetails: {id: 'viewDetails', display: 'View Details'},
  deactivate: {id: 'deactivate', display: 'Deactivate'},
  refresh:{id: 'refresh', display: 'Refresh Status'}
};


export const CREATE_DIALOG = {
  title: "Create new branch deployer",
  subtitle: "Please enter the following details to create a new branch deployer.",
  buttonText: "Create"
};

export const EDIT_DIALOG = {
  title: "Update selected branch deployer",
  subtitle: "You are about to make some changes to a branch deployer",
  buttonText: "Save"
};

export const REACTIVATE_DIALOG = {
  title: "Reactivate selected branch deployer",
  subtitle: "You are about to reactivate the branch deployer $branchDeployerName.",
  buttonText: "Reactivate"
};

export const DEACTIVATE_DIALOG = {
  title: "Deactivate selected branch deployer",
  subtitle: "Are you sure you want to deactivate the branch deployer $branchDeployerName?",
  buttonText: "Deactivate"
};

export const VIEW_DETAILS_DIALOG = {
  title: "Branch Deployer Details",
  subtitle: "Here are the details of the branch deployer $branchDeployerName."
};

export const COPY_SNIPPET_SUCCESS_MESSAGE = "Successfully copied snippet";


export const STATUS_TYPES = {
  LIVE: "Live",
  PENDING: "Pending",
  EXPIRED: "Expired",
  TERMINATED: "Terminated",
  KG_UPDATE: "KG Update",
  FAILED: "Failed"
};
export const BD_STATUS_TO_CHIP = {
  [STATUS_TYPES.LIVE]: {
    backgroundColorPath: ["success", "alert", "background"],
    textColorPath: ["success", "dark"]
  },
  [STATUS_TYPES.PENDING]: {
    backgroundColorPath: ["warning", "light"],
    textColorPath: ["warning", "dark"]
  },
  [STATUS_TYPES.EXPIRED]: {
    backgroundColorPath: ["grey", "200"],
    textColorPath: ["text", "disabled"]
  },
  [STATUS_TYPES.TERMINATED]: {
    backgroundColorPath: ["error", "light"],
    textColorPath: ["error", "dark"]
  },
  [STATUS_TYPES.KG_UPDATE]: {
    backgroundColorPath: ["warning", "light"],
    textColorPath: ["warning", "dark"]
  },
  [STATUS_TYPES.FAILED]: {
    backgroundColorPath: ["error", "light"],
    textColorPath: ["error", "dark"]
  }
};
