import { buttonBaseClasses, CircularProgress, Divider, InputAdornment, selectClasses, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { capitalizeAndCleanUnderscore } from "../../../utils";
import styled from "@emotion/styled";
import PublishStatus from "../../collections/PublishStatus";
import AddIcon from "../../../icons/AddIcon";
import ChevronDown from "../../../icons/ChevronDown";
import { StyledTextField } from "../styles";
import {noOptionList} from "src/components/collections/helpers";

const ADD_NEW = "add_new";

const SingleOption = ({
  id,
  value,
  showLabel,
  label,
  options = [],
  onChange,
  error,
  isReadOnly,
  status,
  onAddNewCollection,
  isFieldReadOnly,
  placeholder,
  loading,
  field,
  testId,
  ...rest
}) => {
  const handleChange = ({ target }) => {
    if (target.value === ADD_NEW) {
      onAddNewCollection();
    } else {
      onChange(target.value);
    }
  };

  let fieldHasNoOptions = false;
  if (options?.length === 0) {
    options = noOptionList(field?.spec?.display_key);
    fieldHasNoOptions = true;
  }


  return (
    <>
      <StyledTextField
        disabled={loading}
        readOnly={isReadOnly}
        isFieldReadOnly={isFieldReadOnly}
        data-testid={testId}
        InputProps={{ sx: { borderRadius: "8px", backgroundColor: 'white' ,
          ...(loading && {
            [`.${selectClasses.outlined}::after`]: {
              content: "'Loading...'",
              color: 'rgba(45, 44, 68, 0.4)'
            }
          })
        }, readOnly: isReadOnly ,
        endAdornment: loading ? <InputAdornment sx={{ position: 'absolute', right: 12 }} position="end">
          <CircularProgress color='inherit' size={20} />
        </InputAdornment> : null
        }}
        SelectProps={{
          MenuProps: { sx: MenuSx , elevation: 3 },
          IconComponent: (props) => !loading ? <ChevronDown {...props} style={{ top: 'unset', right: 12 }} /> : null
        }}
        label={showLabel ? label : ""}
        onChange={handleChange}
        error={!!error}
        // FormHelperTextProps={{ 'data-testid': `${testId}-helper` }}
        helperText={(error ? <Typography data-testid={`${testId}-helper`} sx={{ whiteSpace: "pre-line" }} variant="caption">{error}</Typography> :
          <PublishStatus status={status} testId={`${testId}-helper`} />)}
        select
        fullWidth
        sx={{
          ...(placeholder && !loading ? {
            [`& .${selectClasses.select} span::before`]: {
              content: `"${placeholder}"`,
              color: 'rgba(45, 44, 68, 0.4)'
            }
          } : {})
        }}
        id={id}
        value={value || ""}
        {...rest}
      >
        {!(fieldHasNoOptions && onAddNewCollection) && options?.map((option, optionIndex) => (
          <StyledMenuItem key={option.key} value={option.key} disabled={option.disabled} error={option.error} data-testid={`${testId}-option-${optionIndex}`}>
            {capitalizeAndCleanUnderscore(option.display_key)}
          </StyledMenuItem>
        ))}
        {options?.length > 0 && !fieldHasNoOptions && onAddNewCollection && (
          <Divider
            sx={{ borderColor: "rgba(45, 44, 68, 0.12)" }}
          />
        )}
        {onAddNewCollection &&
          <StyledMenuItem key={ADD_NEW} value={ADD_NEW}>
            <div style={{ display: "flex", marginRight: "8px" }}>
              <AddIcon color="#363554" />
            </div>
            Add New {label}
          </StyledMenuItem>}
      </StyledTextField>
    </>
  );
};

const MenuSx = {
  [`.${buttonBaseClasses.root}[aria-selected='true']`]: {
    background: "#2D2C440A"
  },
  [`.${buttonBaseClasses.root}[aria-selected='true']:hover`]: {
    background: "#2D2C440A"
  }
};

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  "color": error && theme.palette.error.main
}));

export default SingleOption;
