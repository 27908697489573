import { BUILD_STATUSES, ENVIRONMENTS, SEVERITY_VARIANTS } from "../../utils";
import { previewService } from "../../services";
import { get as apiGet } from "src/services/api";
import { SYSTEM_MODES } from "src/stores/slices/createAppSlice";

const SUCCESS_ALERT = {
  message: "Call has been triggered successfully",
  severity: SEVERITY_VARIANTS.SUCCESS
};

const ERROR_ALERT = {
  message: "Something went wrong please try again",
  severity: SEVERITY_VARIANTS.ERROR
};

const createPreviewSlice = (set, get) => ({
  shouldRecreatePreview: false,
  previewAssistantId: null,
  forceDisableBuild: true,
  buildStatusPollingInProgress: false,
  branchDeployerBuildFailed: false,
  disablePublish: false,
  buildDraftResponse: { status: '', url: '' },
  setBuildDraftResponse: data => set({ buildDraftResponse: data }),
  setShouldRecreatePreview: bool => set({ shouldRecreatePreview: bool }),
  updateDisablePublish: bool => set({ disablePublish: bool }),
  pollBuildStatus: async () => {
    const { lastBuildAbort, selectedAssistantId, draft } = get();
    set({ buildStatusPollingInProgress: true, branchDeployerBuildFailed: false });
    try {
      if (lastBuildAbort) {
        lastBuildAbort.abort();
      }
      const abort = new AbortController();
      set({ lastBuildAbort: abort });
      // eslint-disable-next-line no-constant-condition
      const startPolling = async () => {
        const branchDeployerResponse = await apiGet(`/assistants/${selectedAssistantId}/branch-deployer/draft/status?branchName=${draft?.branchName}`, null, abort.signal);
        set({ buildDraftResponse: branchDeployerResponse });

        if (branchDeployerResponse?.status === BUILD_STATUSES.LIVE || branchDeployerResponse?.status === '') {
          const notifications = await apiGet(`/assistants/${selectedAssistantId}/notifications`, null, abort.signal);

          set({
            forceDisableBuild: false,
            lastBuildAbort: null,
            shouldRecreatePreview: false,
            buildStatusPollingInProgress: false,
            branchDeployerBuildFailed: false,
            buildDraftResponse: branchDeployerResponse,
            notifications
          });
          return; // Stop polling as the desired status is reached
        } else if (branchDeployerResponse?.status === BUILD_STATUSES.FAILED) {
          const notifications = await apiGet(`/assistants/${selectedAssistantId}/notifications`, null, abort.signal);
          set({ notifications });
          throw new Error('Branch deployer build failed');
        }

        // Wait for 60 seconds before making the next request
        await new Promise(resolve => setTimeout(resolve, 60000));

        if (selectedAssistantId !== get().selectedAssistantId) return;

        await startPolling(); // Recursively call the function again for the next poll}
      };

      await startPolling();
    } catch (error) {
      set({
        shouldRecreatePreview: false,
        buildStatusPollingInProgress: false,
        branchDeployerBuildFailed: true,
        forceDisableBuild: true
      });
      if (error?.name !== "CanceledError") {
        console.error(error);
        set({ buildDraftResponse: { status: '', url: '' } });
      }
    }
  },

  setIsAssistantPreviewable: () => {
    const {
      selectedAccountId,
      currentCollectionAssistantConfig,
      isSavingChanges,
      isDialogLoading,
      currentMandatoryKeys,
      systemMode
    } = get();
    const isDev = import.meta.env.VITE_ENV === ENVIRONMENTS.DEVELOPMENT;
    const isAssistantPreviewable = (systemMode === SYSTEM_MODES.READ_ONLY && (selectedAccountId?.startsWith('hyro') || isDev))
      || (systemMode === SYSTEM_MODES.EDIT && Object.values(currentCollectionAssistantConfig).length > 0
      && !isSavingChanges
      && !isDialogLoading
      && (!currentMandatoryKeys || currentMandatoryKeys?.length === 0));
    set({ isAssistantPreviewable });
    return isAssistantPreviewable;
  },

  initiateCallToAssistant: async (phoneNumber) => {
    const { previewAssistantId } = get();
    try {
      await previewService.initiateTestCall({
        botId: previewAssistantId,
        phoneNumber
      });
      return SUCCESS_ALERT;
    } catch (error) {
      return ERROR_ALERT;
    }
  }
});

export default createPreviewSlice;
