import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  DialogActions,
  DialogContent,
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  styled
} from "@mui/material";
import React from "react";

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(3)
}));

const HyroDialog = ({
  variant = "passive",
  open,
  title,
  children,
  disableSave,
  disableCancel,
  submitButtonProps = {},
  submitButtonLoading = false,
  submitButtonText = "Confirm",
  cancelButtonText = "Cancel",
  cancelButtonLoading = false,
  handleCancel,
  handleSubmit,
  testId,
  handleClose,
  showCloseButton = false,
  ...props
}) => (
  <MuiDialog
    {...{
      fullWidth: true,
      open,
      ...props
    }}
  >
    <DialogTitle data-testid={`${testId}-dialog-title`}>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    {showCloseButton ? (
      <IconButton
        data-testid={`${testId}-close-icon`}
        onClick={handleClose}
        aria-label="close"
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
    {variant === "acknowledgement" || variant === "transactional" ? (
      <StyledDialogActions>
        {variant === "transactional" ? (
          <LoadingButton
            disabled={disableCancel}
            loading={cancelButtonLoading}
            variant={"text"}
            sx={{ color: (theme) => theme.palette.text.secondary }}
            onClick={handleCancel}
            data-testid={`${testId}-cancel-button`}
          >
            {cancelButtonText}
          </LoadingButton>
        ) : null}
        <LoadingButton
          {...{
            loading: submitButtonLoading,
            disabled: disableSave,
            color: "primary",
            variant: "contained",
            onClick: handleSubmit,
            ...submitButtonProps
          }}
          data-testid={`${testId}-submit-button`}
        >
          {submitButtonText}
        </LoadingButton>
      </StyledDialogActions>
    ) : null}
  </MuiDialog>
);

export default HyroDialog;
