import React from "react";

const PatientRegistrationIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_8232_92)">
    <circle cx="24" cy="24" r="23.5" fill="#E6FDF2" stroke="#B0F0D2"/>
    <path
      d="M24 27.5H19.5C18.1044 27.5 17.4067 27.5 16.8389 27.6722C15.5605 28.06 14.56 29.0605 14.1722 30.3389C14 30.9067 14 31.6044 14 33M26.5 19.5C26.5 21.9853 24.4853 24 22 24C19.5147 24 17.5 21.9853 17.5 19.5C17.5 17.0147 19.5147 15 22 15C24.4853 15 26.5 17.0147 26.5 19.5Z"
      stroke="#3DC684" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M25.4051 32.8892C25.4404 32.7127 25.4581 32.6244 25.4904 32.5421C25.519 32.469 25.5562 32.3996 25.6011 32.3352C25.6517 32.2627 25.7153 32.199 25.8426 32.0717L32.5001 25.4142C33.0524 24.8619 33.9478 24.8619 34.5002 25.4142C35.0525 25.9665 35.0525 26.862 34.5002 27.4143L27.8427 34.0717C27.7154 34.199 27.6517 34.2627 27.5792 34.3132C27.5148 34.3581 27.4453 34.3953 27.3723 34.424C27.2899 34.4563 27.2017 34.4739 27.0251 34.5092L25 34.9142L25.4051 32.8892Z"
      stroke="#3DC684" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8232_92">
      <rect width="48" height="48" fill="white"/>
    </clipPath>
  </defs>
</svg>;


export default PatientRegistrationIcon;