import { NOTIFICATIONS_STATES, NOTIFICATION_TYPES } from "src/utils";

export const NOTIFICATIONS_STATE_TEXTS = {
  [NOTIFICATION_TYPES.DEPLOYMENT]: {
    [NOTIFICATIONS_STATES.IN_PROGRESS]: "Your changes have been submitted and are now going through the testing phase.",
    [NOTIFICATIONS_STATES.PENDING_DEPLOYMENT]: "Yay! Your changes were submitted successfully and are now being processed for going live.",
    [NOTIFICATIONS_STATES.LIVE]: "Your changes are in production!",
    [NOTIFICATIONS_STATES.IN_PROGRESS_FAILED]: "Failed during submission. Someone is taking a look.",
    [NOTIFICATIONS_STATES.PENDING_DEPLOYMENT_FAILED]: "Failed while in review. Someone is taking a look."
  },
  [NOTIFICATION_TYPES.BUILD]: {
    [NOTIFICATIONS_STATES.IN_PROGRESS]: "The build process has started and is currently in progress.",
    [NOTIFICATIONS_STATES.COMPLETED]: "Your assistant has been successfully built. You can now preview or set it live.",
    [NOTIFICATIONS_STATES.IN_PROGRESS_FAILED]: "The build process failed. Someone is taking a look."
  }
};

export const FAILED_REQUEST_TEXT = "Request failed";

export const DIALOGS = {
  BUILD: {
    LABEL: "Are you sure you want to stop the build process? You’ll still be able to go back and edit your latest changes.",
    SUBMIT_BUTTON_TEXT: "Stop Build",
    CANCEL_BUTTON_TEXT: "Resume Build",
    TITLE: "Cancel Build Process?"
  },
  PUBLISH: {
    LABEL: "Once you cancel going live, this action cannot be undone and will delete all the changes you made.",
    SUBMIT_BUTTON_TEXT: "Stop Publishing",
    CANCEL_BUTTON_TEXT: "Resume Publishing",
    TITLE: "Cancel Going Live?"
  }
};
