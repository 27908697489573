import React, { useState, useRef } from 'react';
import { IconButton, TextField, useTheme, inputBaseClasses, outlinedInputClasses, svgIconClasses } from '@mui/material';
import styled from '@emotion/styled';
import SearchIcon from 'src/icons/SearchIcon';
import CloseIcon from 'src/icons/CloseIcon';
import Tooltip from 'src/components/common/Tooltip';

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ open }) => ({
  height: '36px',
  width: open ? '200px' : '36px',
  transition: 'width 0.3s ease-in-out',
  [`& .${outlinedInputClasses.root}`]: { 
    paddingInline: '8px',
    cursor: open ? 'text' : 'pointer',
    backgroundColor: 'white', 
    gap: '8px',
    ':hover': open ? null : { backgroundColor: '#EBEBFD' } 
  },
  [`& .${inputBaseClasses.input}`]: { paddingBlock: '6.5px' },
  [`& .${svgIconClasses.root}`]: { width: '20px' },
  [`& .${inputBaseClasses.root} .${outlinedInputClasses.notchedOutline}`]: { border: '1px solid #E0E0E0' },
  [`:hover .${outlinedInputClasses.notchedOutline}`]: { borderColor: '#7583F7 !important' },
  [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: { border: '1px solid #4548C4 !important' }
}));

const SearchBar = ({ onChange }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [hover, setHover] = useState(false);
  const searchRef = useRef();
  const theme = useTheme();

  const handleSearchIconClick = () => {
    setOpen(true);
    searchRef.current.focus();
  };

  const handleSearchBlur = () => {
    if (!value) {
      setOpen(false);
    }
  };

  const handleSearchTextChange = ({ target: { value } }) => {
    setValue(value);
    onChange(value);
  };

  const handleClearSearch = () => {
    setValue("");
    onChange("");
  };
                
  return (
    <Tooltip title="Search" open={hover && !open}>
      <StyledTextField
        open={open}
        onBlur={handleSearchBlur}
        onChange={handleSearchTextChange}
        onClick={handleSearchIconClick}
        onFocus={handleSearchIconClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        inputRef={searchRef}
        value={value}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <SearchIcon stroke={hover && !open ? theme.palette.primary.main : theme.palette.action.active}  />
          ),
          endAdornment: value ? (
            <IconButton onClick={handleClearSearch} sx={{ p: 0, width: '24px', height: '24px' }}>
              <CloseIcon color={theme.palette.action.active} />
            </IconButton>
          ) : null
        }}
      />
    </Tooltip>
  );
};

export default SearchBar;