import React from "react";

const FaLIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#EEEEFF" stroke="#DEDFFF"/>
    <path d="M25 23H29.8C30.9201 23 31.4802 23 31.908 23.218C32.2843 23.4097 32.5903 23.7157 32.782 24.092C33 24.5198 33 25.0799 33 26.2V33M25 33V18.2C25 17.0799 25 16.5198 24.782 16.092C24.5903 15.7157 24.2843 15.4097 23.908 15.218C23.4802 15 22.9201 15 21.8 15H18.2C17.0799 15 16.5198 15 16.092 15.218C15.7157 15.4097 15.4097 15.7157 15.218 16.092C15 16.5198 15 17.0799 15 18.2V33M34 33H14M18.5 19H21.5M18.5 23H21.5M18.5 27H21.5" stroke="#7583F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>);

export default FaLIcon;
