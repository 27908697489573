import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import React from "react";
import SingleListItem from "./SingleListItem";

const NavItem = ({ navItem, ...props }) => {
  const { openNavSection, navSectionWasClosed, currentPage } = props;
  const [parentPage] = currentPage.split('/');

  const openNavSectionWasNotClosed = navSectionWasClosed !== parentPage && parentPage === navItem.key;
  const navItemIsOpen = openNavSection === navItem.key || openNavSectionWasNotClosed;

  return (
    <>
      <SingleListItem {...{ ...props, currentPage, navItem, navItemIsOpen }} />
      {navItem.children && (
        <Collapse
          in={navItemIsOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" sx={{ padding: 0 }}>
            {navItem.children.map(navItem => (
              <SingleListItem key={navItem.key} {...{ ...props, currentPage, navItem }} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavItem;
