import { CALL_ME_FIELDS } from "src/phrasing";
import { DIALOG_VARIANTS } from "src/components/common/Dialogs/DialogConsts";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";
import React from "react";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SingleOption from "../../../components/common/fields/SingleOption";
import useCallMeDialog from "./useCallMeDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Popover from "../../../components/common/Popover";

const DialogContent = styled("div")(() => ({
  display: "flex",
  flexFlow: "column"
}));

const InstructionsText = styled("div")(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  color: theme.palette.text.secondary
}));

const InstructionsBox = styled("div")(() => ({
  marginTop: "8px",
  marginBottom: "24px"
}));

const CallMeDialog = ({ isOpen, onClose, callRequestSent, handleCallMe }) => {
  const { sites, errors, fields, disableCall, loading, handleChange } = useCallMeDialog();

  return (
    <Dialog
      title="Assistant call preview"
      open={isOpen}
      variant={DIALOG_VARIANTS.transactional}
      handleSubmit={() => handleCallMe(fields)}
      handleCancel={onClose}
      submitButtonLoading={callRequestSent}
      disableSave={!!disableCall}
      submitButtonText="Call me"
    >
      <DialogContent>
        <InstructionsBox>
          <InstructionsText>Please provide the following details to receive a preview call</InstructionsText>
        </InstructionsBox>
        <Stack gap={3}>
          <Field
            disabled={callRequestSent}
            field={CALL_ME_FIELDS.PHONE_NUMBER} placeholder={CALL_ME_FIELDS.PHONE_NUMBER.PLACEHOLDER}
            errors={errors} value={fields[CALL_ME_FIELDS.PHONE_NUMBER.ID]} mandatory onChange={handleChange} />
          <Field
            disabled={callRequestSent}
            field={CALL_ME_FIELDS.CALLER_ID} placeholder={CALL_ME_FIELDS.CALLER_ID.PLACEHOLDER} errors={errors}
            value={fields[CALL_ME_FIELDS.CALLER_ID.ID]} onChange={handleChange} />
          <Field
            disabled={callRequestSent}
            type='select'
            field={CALL_ME_FIELDS.SITES}
            placeholder={CALL_ME_FIELDS.SITES.PLACEHOLDER}
            errors={errors}
            options={sites}
            value={fields[CALL_ME_FIELDS.SITES.ID]}
            onChange={handleChange}
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const Field = ({ field, type = 'text', value, placeholder, errors, onChange, options, loading, disabled, mandatory = false }) => <Stack gap={1}>
  <Stack direction={'row'} gap={.5} alignItems={'center'}>
    <Typography variant={"body2"} color={"text.primary"}>{field.LABEL} {mandatory && <span style={{ color: '#EF404B' }}> *</span>}</Typography>
    <Popover
      title={field.TOOLTIP_TITLE}
      description={field.TOOLTIP_DESCRIPTION}
      Handle={props => (
        <IconButton {...props} size={'small'}>
          <InfoOutlinedIcon fontSize={'16px'} sx={{ color: "action.active" }} />
        </IconButton>
      )}
    />
  </Stack>
  {type === 'select' ?
    <SingleOption
      disabled={disabled}
      placeholder={'Select a site'}
      loading={loading}
      error={!!errors[field.ID]}
      onChange={(value) => onChange({ target: { name: CALL_ME_FIELDS.SITES.ID, value } })}
      label={field.LABEL}
      options={options}
      value={value} /> :
    <TextField
      disabled={disabled}
      name={field.ID}
      label={''}
      placeholder={placeholder}
      error={!!errors[field.ID]}
      helperText={errors[field.ID]}
      value={value}
      onChange={onChange}
    />}

</Stack>;

export default CallMeDialog;
