import useAppStore from "../stores/appStore";

export const setAxiosTokenInterceptor = (
  axiosInstances,
  accessToken
) => {
  axiosInstances.forEach((axiosInstance) => {
    axiosInstance.interceptors.request.use( (requestConfig) => {
      const { selectedAssistantId, selectedAccountId } = useAppStore.getState();
      requestConfig.headers = {
        ...requestConfig.headers,
        "account-id": selectedAccountId,
        "room-id": selectedAssistantId,
        "Authorization": `Bearer ${accessToken}`
      };
      return requestConfig;
    });
  });
};
