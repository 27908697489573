import adminServiceNew from "../../services/adminServiceNew";
import {DIALOG_TYPES} from "../../components/branch-deployer/BranchDeployerConsts";

const createBranchDeployerSlice = (set, get) => ({
  branchDeployerRows: null,
  branchDeployerError: false,
  isBranchDialogLoading: false,
  isRefreshingRowSelector: false,

  setBranchDeployerError: error =>
    set(
      { branchDeployerError: error }
    ),

  getBranchDeployerRows: async (page = 0, perPage = 50) => {
    try {
      const branchDeployerRows = await adminServiceNew.getBranchDeployerRows({
        page,
        perPage
      });

      // add calculated data not sent by server
      const fullBranchDeployerRows = branchDeployerRows.map(bd => {
        if (bd.assistantId && bd['widget_client_tag']) {
          return ({
            ...bd,
            'widget_client_url': `${bd['widget_client_url']}/index.html?id=${bd.assistantId}&widgetServerUrl=${bd['widget-server-url'].replace('https', 'wss')}`
          });
        } else {
          return bd;
        }
      });

      set({ branchDeployerRows: fullBranchDeployerRows});
    }
    catch (e) {
      console.error(e);
    }
  },

  createBranchDeployer: async ( branchDeployerData ) => {
    try {
      const newBranchDeployer = await adminServiceNew.createBranchDeployer( branchDeployerData  );
      set(
        {
          branchDeployerRows: [newBranchDeployer, ...get().branchDeployerRows]
        });
    }
    catch (e) {
      set (
        {
          branchDeployerError: true
        });
      console.error(e);
    }
  },

  editBranchDeployer: async (branchDeployerData) => {
    try {
      const editedBranchDeployer = await adminServiceNew.editBranchDeployer(branchDeployerData);
      const branchDeployerRows = [editedBranchDeployer,...get().branchDeployerRows.filter(bd =>
        bd.release_name !== branchDeployerData.release_name
      )];
      set({ branchDeployerRows });
    }
    catch (e) {
      set(
        {
          branchDeployerError: true
        });
      console.error(e);
    }
  },

  deleteBranchDeployer: async (release_name) => {
    try {
      const editedBranchDeployer = await adminServiceNew.deleteBranchDeployer(release_name);
      const branchDeployerRows = [editedBranchDeployer,...get().branchDeployerRows.filter(bd =>
        bd.release_name !== release_name
      )];
      set({ branchDeployerRows });
    }
    catch (e) {
      set(
        {
          branchDeployerError: true
        });
      console.error(e);
    }
  },

  getBranchDeployerStatus: async (release_name) => {

    const branchDeployerRows = get().branchDeployerRows.map(bd =>
      bd.release_name === release_name ? {...bd, status: DIALOG_TYPES.refresh.id} : bd
    );
    set({ branchDeployerRows });
    try {
      const editedBranchDeployer = await adminServiceNew.getBranchDeployerStatus(release_name);
      const branchDeployerRows = [editedBranchDeployer,...get().branchDeployerRows.filter(bd =>
        bd.release_name !== release_name
      )];
      set({ branchDeployerRows });
    }
    catch (e) {
      set(
        {
          branchDeployerError: true
        });
      console.error(e);
    }
    set({ isRefreshingRowSelector: false});
  }
});

export default createBranchDeployerSlice;
