export const DIALOG_TYPES = {
  create: "create",
  edit: "edit",
  delete: "delete",
  error: "error"
};

export const ACCOUNT_EDITOR_ERRORS = {
  duplicate: "Account name already exists, please choose a different name.",
  general: "Attempt failed, please try again."
};

export const ERROR_DIALOG = {
  title: "Something went wrong",
  buttonText: "Back"
};

export const CREATE_DIALOG = {
  title: "Create new account",
  subtitle: "Please enter the following details to create a new account.",
  buttonText: "Create"
};

export const EDIT_DIALOG = {
  title: "Edit selected account",
  subtitle: "You are about to make some changes to account $accountName.",
  buttonText: "Save"
};

export const DELETE_DIALOG = {
  title: "Delete selected account",
  subtitle: "Are you sure you want to delete the account $accountName?",
  buttonText: "Delete"
};

export const CONFIRMATION_DIALOG = {
  title:
    "Are you sure you want to save the changes you made to the account $accountName?",
  buttonText: "Save changes"
};

export const DIALOG_HELPER_TEXTS = {
  accountName: "Please enter a valid account name.",
  accountId: "Account ids must be in kebab-case. Please enter a valid account id."
};
