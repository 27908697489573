import {
  CREATE_DIALOG,
  DIALOG_TYPES,
  EDIT_DIALOG,
  REACTIVATE_DIALOG,
  DEACTIVATE_DIALOG,
  BRANCH_DEPLOYER_FIELDS,
  VIEW_DETAILS_DIALOG,
  VIEW_DETAILS_FIELDS, BRANCH_DEPLOYER_PRODUCTION_FIELDS
} from "./BranchDeployerConsts";
import { isProd } from "src/utils";

const updateValuesFromArray = (arr, obj) => {
  arr.forEach(item => {
    const key = Object.keys(obj).find(key => key === item.id);
    if (key !== undefined) {
      item.value = obj[key];
    }
  });
  return arr;
};
const useBranchDeployerDialog = ({type, data}) => {
  const fields = isProd() ?
    BRANCH_DEPLOYER_PRODUCTION_FIELDS : BRANCH_DEPLOYER_FIELDS;
  let dialogContent = {};

  if (type === DIALOG_TYPES.create.id) {
    dialogContent = {
      title: CREATE_DIALOG.title,
      subtitle: CREATE_DIALOG.subtitle,
      actionButtonText: CREATE_DIALOG.buttonText,
      data: fields
    };
  } else if (type === DIALOG_TYPES.edit.id) {
    dialogContent = {
      title: EDIT_DIALOG.title,
      subtitle: EDIT_DIALOG.subtitle,
      actionButtonText: EDIT_DIALOG.buttonText,
      data: updateValuesFromArray(fields, data)
    };
  } else if (type === DIALOG_TYPES.reactivate.id) {
    dialogContent = {
      title: REACTIVATE_DIALOG.title,
      subtitle: REACTIVATE_DIALOG.subtitle.replace("$branchDeployerName", data.release_name),
      actionButtonText: REACTIVATE_DIALOG.buttonText
    };
  } else if (type === DIALOG_TYPES.deactivate.id) {
    dialogContent = {
      title: DEACTIVATE_DIALOG.title,
      subtitle: DEACTIVATE_DIALOG.subtitle.replace("$branchDeployerName", data.release_name),
      actionButtonText: DEACTIVATE_DIALOG.buttonText
    };
  } else if (type === DIALOG_TYPES.viewDetails.id) {
    dialogContent = {
      title: VIEW_DETAILS_DIALOG.title,
      data: updateValuesFromArray([...VIEW_DETAILS_FIELDS, ...BRANCH_DEPLOYER_FIELDS], data)
    };
  }
  return {dialogContent};
};

export default useBranchDeployerDialog;
