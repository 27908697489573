import { useState } from 'react';
import { useEffectAsync } from "src/hooks";
import { get, patch } from "src/services/api";
import { BANNER_TYPES, COLORS, STATES } from "./consts";
import { useLocation } from "react-router-dom";
import useAppStore from "../../../stores/appStore";

import { getDashboardSettingsSelector } from "src/stores/selectors/branchDeployerSelector";
import {getErrorPageFromStatus} from "src/layout/ErrorPages/ErrorPage";

export const DASHBOARD_SETTINGS_ENDPOINT = '/assistants/dashboard-settings/1';
export const ADMIN_DASHBOARD_SETTINGS_ENDPOINT = '/admin/dashboard-settings/1';

const useSettings = () => {
  const getDashboardSettings = useAppStore(getDashboardSettingsSelector);
  const [initialData, setInitialData] = useState(null);
  const [data, setData] = useState(null);
  const [ErrorPage, setErrorPage] = useState(null);
  const [customTextError, setCustomTextError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const isSaveDisabled = JSON.stringify(initialData || {}) === JSON.stringify(data || {})
    || !!(!data?.settings?.banner?.text && customTextError);
  const { pathname } = useLocation();
  const endpoint = pathname.includes('admin') ? ADMIN_DASHBOARD_SETTINGS_ENDPOINT : DASHBOARD_SETTINGS_ENDPOINT;

  useEffectAsync(async () => {
    try {
      const tempData = await get(endpoint);
      setData(tempData);
      setInitialData(tempData);
    } catch (error) {
      console.error(error);
      setErrorPage(getErrorPageFromStatus(e.response.status));
    }
  }, []);

  const handleChange = (e, parent) => {
    const { name, value, checked, type } = e.target;
    const actualValue = type === 'checkbox' ? checked : value;
    let mergedData = { [name]: actualValue };
    if (parent) {
      if (name === 'show') {
        mergedData = { [parent]: { ...initialData?.settings?.[parent], ...mergedData } };
        const bannerType = BANNER_TYPES.find(({ color }) => color === data?.settings?.banner?.color);
        const state = bannerType?.color === COLORS.INCIDENT ? STATES.ACTIVE : STATES.INACTIVE;
        mergedData = { ...mergedData, ...(actualValue ? state : STATES.INACTIVE) };
      } else if (name === 'color') {
        const type = BANNER_TYPES.find(({ color }) => color === actualValue);
        const extraSettings = type?.color === COLORS.INCIDENT ?
          STATES.ACTIVE : STATES.INACTIVE;
        mergedData = {
          [parent]: {
            ...data?.settings?.[parent],
            text: type?.text,
            color: type.color,
            customText: ''
          }, ...extraSettings
        };
      } else if (name === 'text') {
        const bannerType = BANNER_TYPES.find(({ color }) => color === data?.settings?.banner?.color);
        const customData = actualValue ? { text: '' } : { customText: '', text: bannerType.text };
        setCustomTextError(validateCustomText(''));
        mergedData = { [parent]: { ...data?.settings?.[parent], ...customData } };
      } else {
        mergedData = { [parent]: { ...data?.settings?.[parent], ...mergedData } };
      }
      if (name === 'customText') {
        setCustomTextError(validateCustomText(actualValue));
      }
    }
    setData({ ...data, settings: { ...data.settings, ...mergedData } });
  };

  const validateCustomText = (val) => val.match(/^[A-Z].*\.$/) && val !== '' ? '' :
    'Custom text must start with a capital letter and end with a period.';

  const handleSave = async () => {
    setIsSaving(true);
    const response = await patch(endpoint, data.settings);
    setData(response);
    setInitialData(response);
    setIsSaving(false);
    await getDashboardSettings(true);
  };

  return { handleChange, handleSave, data, ErrorPage, customTextError, isSaveDisabled, isSaving };
};

export default useSettings;
