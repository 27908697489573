import AssistantNavbar from "./AssistantNavbar";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { not } from "ramda";

const MobileAssistantNavbar = props => {
  const { setOpen } = props;
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={() => setOpen(not)}
      >
        <MenuIcon />
      </IconButton>
      <AssistantNavbar {...props} />
    </>
  );
};

export default MobileAssistantNavbar;
