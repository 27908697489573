import { del, get as apiGet, get as Get, patch, post, put } from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import {collectionNames} from "../../components/collections/consts";
import { SYSTEM_MODES } from "./createAppSlice";

const createCollectionsSlice = (set, get) => ({
  isPageLoading: true,
  isCollectionLoading: true,
  hasPageError: false,
  hasDialogError: false,
  isDialogLoading: false,
  collections: null,
  collectionsLanguages: [],
  defaultCollectionsLanguages: null,
  // assistant config
  currentCollectionAssistantConfig: {},
  collectionAssistantConfig: {},
  collectionOriginalAssistantConfig: {},
  // collection config
  collectionConfig: {},
  originalCollectionConfig: {},
  currentCollectionConfig: {},
  collectionSpec: null,
  selectedCollection: {},
  isUpdatingCollection: false,
  collectionConfigChanged: false,
  isSavingChanges: false,
  collectionName: '',
  mandatoryKeys: [],
  currentMandatoryKeys: null,
  failedToUpdateDraft: false,
  lastCollectionCreatedKey: null,
  collectionBreadCrumbs: [],
  ignoreUrlChange: false,
  setIgnoreUrlChange: value => set({ ignoreUrlChange: value }),
  setLastCollectionCreatedKey: value => set({ lastCollectionCreatedKey: value }),
  addToCollectionBreadCrumbs: (value) => {
    const { collectionBreadCrumbs } = get();
    set({ collectionBreadCrumbs: [...collectionBreadCrumbs, value] });
    set({ ignoreUrlChange: true });
  },
  removeLastItemFromCollectionBreadCrumbs: () => {
    const { collectionBreadCrumbs } = get();
    set({ collectionBreadCrumbs: collectionBreadCrumbs.slice(0, -1) });
  },
  clearCollectionBreadCrumbs: () => {
    set({ collectionBreadCrumbs: [] });
  },
  setCollectionName: value => set({ collectionName: value }),
  setIsUpdatingCollection: value => set({ isUpdatingCollection: value }),
  setCollectionConfigChanged: value => set({ collectionConfigChanged: value }),
  setDialogLoading: value => set({ isDialogLoading: value }),
  setDialogError: value => set({ hasDialogError: value }),
  getMandatoryKeys: async () => {
    const { selectedAssistantId, systemMode, draft } = get();
    try {
      const mandatoryKeys = await Get(`/assistants/collections/${selectedAssistantId}/all/mandatory-keys`);
      set({ mandatoryKeys, currentMandatoryKeys: systemMode === SYSTEM_MODES.EDIT && draft ? mandatoryKeys : null });
    } catch (e) {
      console.error("Failed to load mandatory keys", e);
      return { error: true };
    }
  },
  getCollectionOptions: async (collectionName) => {
    const { selectedAssistantId } = get();
    try {
      return await Get(`/assistants/collections/${selectedAssistantId}/${collectionName}/spec-refs`);
    } catch (e) {
      console.error("Failed to load collection options", e);
      return { error: true };
    }
  },
  resetCollectionsData: () => {
    set({
      collections: {},
      collectionAssistantConfig: {},
      collectionOriginalAssistantConfig: {},
      currentCollectionAssistantConfig: {},
      collectionsLanguages: [],
      defaultCollectionsLanguages: null,
      previewAssistantId: null,
      buildDraftResponse: { status: '', url: '' }
    });
  },
  fetchAllCollections: async (collectionName) => {
    const { selectedAssistantId, systemMode, lastAbort } = get();
    if (lastAbort) {
      lastAbort.abort();
    }
    const abort = new AbortController();
    set({ isPageLoading: true, lastAbort: abort });
    try {
      const collections = await post(`/assistants/collections/${selectedAssistantId}/${collectionName}/all`, {},
        null, null, abort.signal);
      if (!collections) return;
      const configRef = Object.keys(collections?.draftAssistantConfig || {}).length > 0 ? collections.draftConfigRef : collections.configRef;

      const resultAssistantConfig = Object.keys(collections?.draftAssistantConfig || {}).length ?
        collections.draftAssistantConfig
        : collections.assistantConfig;

      set({
        lastAbort: null,
        isPageLoading: false,
        collections: {
          specRef: collections.specRef,
          configRef,
          originalConfig: collections.configRef,
          currentConfig: systemMode === SYSTEM_MODES.EDIT ? configRef : collections.configRef
        },
        collectionsLanguages: resultAssistantConfig.languages?.map(lang => lang.language),
        defaultCollectionsLanguages: resultAssistantConfig.languages?.find(lang => lang.is_default)?.language,
        collectionAssistantConfig: resultAssistantConfig,
        collectionOriginalAssistantConfig: collections.assistantConfig,
        currentCollectionAssistantConfig: systemMode === SYSTEM_MODES.EDIT ? resultAssistantConfig : collections.assistantConfig
      });
    } catch (err) {
      console.error('Error occurred while fetching collections', err);
      set({ hasPageError: err.response.status, isPageLoading: false });
    }
  },

  fetchCollection: async ({ collectionKey, collectionInstanceKey, collectionName }) => {
    const {
      systemMode,
      selectedAssistantId
    } = get();
    set({ isCollectionLoading: true });
    try {
      const {
        assistantConfig,
        draftAssistantConfig,
        draftConfig,
        config,
        spec,
        mandatoryKeys
      } = await post(`/assistants/collections/${selectedAssistantId}/${collectionName}/${collectionKey}/${collectionInstanceKey}`,
        {});
      const collectionConfig = Object.keys(draftAssistantConfig || {}).length ? draftConfig : config;
      const resultAssistantConfig = Object.keys(draftAssistantConfig || {}).length ?
        draftAssistantConfig
        : assistantConfig;

      set({
        collectionAssistantConfig: resultAssistantConfig,
        collectionOriginalAssistantConfig: assistantConfig,
        currentCollectionAssistantConfig: systemMode === SYSTEM_MODES.EDIT ? resultAssistantConfig : assistantConfig,
        collectionsLanguages: resultAssistantConfig.languages?.map(lang => lang.language),
        defaultCollectionsLanguages: resultAssistantConfig.languages?.find(lang => lang.is_default)?.language,
        collectionConfig,
        currentCollectionConfig: systemMode === SYSTEM_MODES.EDIT ? collectionConfig : config,
        originalCollectionConfig: config,
        collectionSpec: spec,
        selectedCollection: { ...(systemMode === SYSTEM_MODES.EDIT ? collectionConfig : config), spec },
        isCollectionLoading: false,
        isPageLoading: false,
        mandatoryKeys,
        currentMandatoryKeys: systemMode === SYSTEM_MODES.EDIT ? mandatoryKeys : null
      });
    } catch (err) {
      console.error('Error occurred while fetching collection', err);
      set({ hasDialogError: true });
      set({ isCollectionLoading: false, isPageLoading: false });
    }
  },
  createCollection: async ({ specKey, displayKey, description, collectionName ,createFromRef }) => {
    const {
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      updateBuildingBlockSliceState,
      pageName
    } = get();
    set({ isDialogLoading: true });
    const lastCollectionCreatedKey =  uuidv4();
    try {
      const {
        draftAssistantConfig,
        specRef,
        configRef,
        draftConfigRef,
        draft,
        mandatoryKeys
      } = await post(`/assistants/collections/${selectedAssistantId}/${collectionName}`, {
        assistant_configuration: collectionAssistantConfig,
        display_key: displayKey,
        description,
        key: lastCollectionCreatedKey,
        spec_key: specKey,
        userData: { name: userData.name, email: userData.email }
      }, null, { pageName });
      updateBuildingBlockSliceState({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft });
      set({ mandatoryKeys, currentMandatoryKeys: mandatoryKeys });
      if (createFromRef) {
        set({ lastCollectionCreatedKey });
      }
      return true;
    } catch (err) {
      console.error('Error occurred while creating collection', err);
      set({ hasDialogError: true });
      return false;
    }
  },
  deleteCollection: async (collection) => {
    const {
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      updateBuildingBlockSliceState,
      collectionName,
      pageName
    } = get();
    set({ isDialogLoading: true });
    try {
      const {
        draftAssistantConfig,
        specRef,
        configRef,
        draftConfigRef,
        draft,
        mandatoryKeys
      } = await del(`/assistants/collections/${selectedAssistantId}/${collectionName}`, {
        assistant_configuration: collectionAssistantConfig,
        userData: { name: userData.name, email: userData.email },
        ...collection
      }, { pageName });
      set({ mandatoryKeys, currentMandatoryKeys: mandatoryKeys });
      updateBuildingBlockSliceState({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft });
      return true;
    } catch (err) {
      console.error('Error occurred while deleting collection', err);
      set({ hasDialogError: true });
      return false;
    }
  },
  editCollection: async (collection) => {
    const {
      pageName,
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      updateBuildingBlockSliceState,
      collectionName
    } = get();
    set({ isDialogLoading: true });
    try {
      const {
        draftAssistantConfig,
        specRef,
        configRef,
        draftConfigRef,
        draft,
        mandatoryKeys
      } = await patch(`/assistants/collections/${selectedAssistantId}/${collectionName}`, {
        assistant_configuration: collectionAssistantConfig,
        userData: { name: userData.name, email: userData.email },
        ...collection
      }, { pageName });
      set({ mandatoryKeys, currentMandatoryKeys: mandatoryKeys });
      updateBuildingBlockSliceState({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft });
      return true;
    } catch (err) {
      console.error('Error occurred while editing collection', err);
      set({ hasDialogError: true });
      return false;
    }
  },
  updateCollection: async (collection) => {
    const {
      discardDraftIfNoChanges,
      selectedAssistantId,
      collectionAssistantConfig,
      userData,
      setSelectedCollectionConfig,
      collectionName,
      pageName,
      infoPopovers
    } = get();
    const { configuration: { key: collectionKey }, key: collectionInstanceKey } = collection;
    set({
      isSavingChanges: true,
      isUpdatingCollection: true
    });
    try {
      const {
        assistantConfig,
        collectionConfig,
        draft,
        mandatoryKeys,
        spec
      } = await put(`/assistants/collections/${selectedAssistantId}/${collectionName}/${collectionKey}/${collectionInstanceKey}`, {
        assistant_configuration: collectionAssistantConfig,
        instance_config: collection,
        userData: { name: userData.name, email: userData.email }
      }, { pageName });
      const branchDeployerResponse = await apiGet(`/assistants/${selectedAssistantId}/branch-deployer/draft/status?branchName=${draft?.branchName}`);
      set({ buildDraftResponse: branchDeployerResponse });

      setSelectedCollectionConfig(collectionConfig, spec);
      set({ currentCollectionAssistantConfig: assistantConfig, draft });
      await discardDraftIfNoChanges();

      const updObj= {
        collectionConfig,
        currentCollectionConfig: collectionConfig,
        isSavingChanges: false,
        isUpdatingCollection: false,
        collectionAssistantConfig: assistantConfig,
        infoPopovers: { ...infoPopovers, draftExpiration: true },
        mandatoryKeys,
        currentMandatoryKeys: mandatoryKeys,
        buildDraftResponse: { status: '', url: '' }
      };

      if (spec) {
        updObj.collectionSpec = spec;
      }

      set(updObj);

      return true;
    } catch (err) {
      set({
        hasDialogError: true,
        isSavingChanges: false,
        isUpdatingCollection: false
      });
      console.error('Error occurred while configure collection', err);

      return false;
    }
  },
  setSelectedCollection: value => set({ selectedCollection: value }),
  setCollectionLoader: value => set({ setCollectionLoader: value }),
  setSelectedCollectionConfig: (config, spec) => {
    const { selectedCollection } = get();
    set({ selectedCollection: { ...config, spec: spec || selectedCollection?.spec } });
  },
  setCollectionConfig: value => set({ collectionConfig: value }),
  updateBuildingBlockSliceState: ({ draftAssistantConfig, specRef, configRef, draftConfigRef, draft }) => {
    const { collections, infoPopovers } = get();
    const updatedConfigRef = Object.keys(draftAssistantConfig).length ? draftConfigRef : configRef;
    set({
      buildDraftResponse: { status: '', url: '' },
      collectionAssistantConfig: draftAssistantConfig,
      currentCollectionAssistantConfig: draftAssistantConfig,
      collections: {
        ...collections,
        specRef,
        configRef: updatedConfigRef,
        currentConfig: updatedConfigRef
      },
      draft,
      infoPopovers: { ...infoPopovers, draftExpiration: true }
    });
  },
  hasSpotCollection: () => {
    const { currentCollectionAssistantConfig } = get();
    return currentCollectionAssistantConfig?.skills?.some(collectionObj => collectionObj?.configuration?.key === collectionNames.SKILL.SPOT.WEB);
  },
  getCollectionRefs: async (collectionId, query_params) => {
    const { selectedAssistantId, currentCollectionAssistantConfig } = get();
    try {
      return await post(`/assistants/${selectedAssistantId}/collection-refs/${collectionId}`, currentCollectionAssistantConfig, query_params);
    } catch (e) {
      console.error("Failed to load collections refs", e);
      return { error: true };
    }
  },
  setFailedToUpdateDraft: (value) => {
    set({ failedToUpdateDraft: value });
  },
  clearCollections: () => {
    set({
      collectionConfig: {},
      originalCollectionConfig: {},
      collectionSpec: null,
      selectedCollection: {},
      collections: null,
      collectionAssistantConfig: {},
      collectionOriginalAssistantConfig: {},
      isPageLoading: true,
      mandatoryKeys: []
    });
  }
});

export default createCollectionsSlice;
