import { HyroHeader, HyroLoader, HyroTable } from "../../hyro-components";
import React, { useEffect, useMemo, useState } from "react";
import {
  accountManagementErrorSelector,
  accountManagementErrorTextSelector,
  createAccountSelector,
  deleteAccountSelector,
  editAccountSelector,
  getAccountsAdminSelector,
  hasPermissionSelector,
  managementAccountsSelector,
  setAccountManagementErrorSelector
} from "src/stores/selectors/appSelectors";
import AccountEditorDialog from "./AccountEditorDialog";
import AddIcon from "@mui/icons-material/Add";
import { DIALOG_TYPES } from "./AccountEditorConsts";
import { USER_PERMISSIONS } from "@hyro/dashboard-commons";
import moment from "moment";
import useAppStore from "../../../stores/appStore";
import { useDashboardSettings } from "../../../hooks";
import HyroTableTitle from "src/components/hyro-components/HyroTableTitle";

const initialDialogState = {
  open: false,
  type: "",
  error: false,
  errorText: "",
  accountData: { accountId: { value: "", errorText: "" }, name: { value: "", errorText: "" } }
};
const AccountManagement = () => {
  const getAccounts = useAppStore(getAccountsAdminSelector);
  const createAccount = useAppStore(createAccountSelector);
  const editAccount = useAppStore(editAccountSelector);
  const deleteAccount = useAppStore(deleteAccountSelector);
  const error = useAppStore(accountManagementErrorSelector);
  const errorText = useAppStore(accountManagementErrorTextSelector);
  const setError = useAppStore(setAccountManagementErrorSelector);
  const rawAccounts = useAppStore(managementAccountsSelector);
  const dashboardSettings = useDashboardSettings();
  const orderedAccounts = useMemo(
    () => rawAccounts?.sort((a, b) => a.name.localeCompare(b.name)),
    [rawAccounts]
  );
  const [filteredAccounts, setFilteredAccounts] = useState(
    orderedAccounts || []
  );
  const hasPermission = useAppStore(hasPermissionSelector);
  const createAccountPermission = hasPermission(
    USER_PERMISSIONS.ACCOUNTS.WRITE
  );
  const [searchText, setSearchText] = useState("");
  const [accountEditorDialog, setAccountEditorDialog]
    = useState(initialDialogState);

  useEffect(() => {
    if (error) {
      setAccountEditorDialog({ ...accountEditorDialog, error, errorText });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorText, setError]);

  useEffect(() => {
    if (!error) {
      setAccountEditorDialog(initialDialogState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawAccounts]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {
    if (!rawAccounts) return;
    setFilteredAccounts(
      rawAccounts.filter(
        ({ name, accountId }) =>
          name.toLowerCase().includes(searchText)
                    || accountId.toLowerCase().includes(searchText)
      )
    );
  }, [searchText, rawAccounts]);

  const handleAccountEditorDialogClose = () => {
    setAccountEditorDialog(initialDialogState);
    setError(null);
  };

  const handleAccountEditorConfirmation = (data) => {
    if (accountEditorDialog.type === DIALOG_TYPES.create) {
      createAccount({
        name: data.name.value
      });
    }
    else if (accountEditorDialog.type === DIALOG_TYPES.edit) {
      editAccount({
        ...data,
        name: data.name.value,
        accountId: data.accountId.value
      });
    }
    else if (accountEditorDialog.type === DIALOG_TYPES.delete) {
      deleteAccount({
        ...data,
        name: data.name.value,
        accountId: data.accountId.value
      });
    }
  };

  const handleResetError = () => {
    setAccountEditorDialog({ ...accountEditorDialog, error: false });
    setError(null);
  };

  if (!rawAccounts) return <HyroLoader />;

  return (
    <>
      <HyroHeader
        title={<HyroTableTitle title="Accounts" rowsLength={filteredAccounts?.length} />}
        testId="account-management"
        handleSearch={setSearchText}
        buttonSpec={
          createAccountPermission
            ? {
              icon: <AddIcon />,
              text: "Create New Account",
              disabled: dashboardSettings?.disableChanges,
              run: () => {
                setAccountEditorDialog({
                  ...accountEditorDialog,
                  open: true,
                  type: DIALOG_TYPES.create
                });
              }
            }
            : null
        }
      />
      <HyroTable
        rows={filteredAccounts}
        testId="account-management"
        columns={[
          { field: "name", headerName: "Name", flex: 1 },
          { field: "accountId", headerName: "Account Id", flex: 1 },
          { field: "creator", headerName: "Creator", flex: 1 },
          {
            field: "createdAt",
            headerName: "Created at",
            flex: 1,
            renderCell: ({ row }) => moment(new Date(row.createdAt)).format("MM/DD/YYYY HH:ss a")
          }
        ]}
        onRowClick={({row}) => {
          window.open(
            `${window.location.protocol}//${window.location.host}/${row.accountId}`
          );
        }}
        rowActions={
          createAccountPermission && !dashboardSettings?.disableChanges
            ? [
              {
                id: "edit",
                display: "Edit account",
                run: (accountData) => {
                  setAccountEditorDialog({
                    open: true,
                    type: DIALOG_TYPES.edit,
                    accountData: {
                      ...accountData,
                      name: { value: accountData.name, errorText: "" },
                      accountId: {
                        value: accountData.accountId,
                        errorText: ""
                      }
                    }
                  });
                }
              },
              {
                id: "delete",
                display: "Delete account",
                run: (accountData) => {
                  setAccountEditorDialog({
                    open: true,
                    type: DIALOG_TYPES.delete,
                    accountData: {
                      ...accountData,
                      name: { value: accountData.name, errorText: "" },
                      accountId: {
                        value: accountData.accountId,
                        errorText: ""
                      }
                    }
                  });
                }
              }
            ]
            : null
        }
      />
      {accountEditorDialog.open && (
        <AccountEditorDialog
          {...accountEditorDialog}
          onResetError={handleResetError}
          onConfirm={handleAccountEditorConfirmation}
          onClose={handleAccountEditorDialogClose}
        />
      )}
    </>
  );
};

export default AccountManagement;
