import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const FolderIcon = ({ fill }) => (<SvgIcon>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 13H13V12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12V13H10C9.45 13 9 13.45 9 14C9 14.55 9.45 15 10 15H11V16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V15H14C14.55 15 15 14.55 15 14C15 13.45 14.55 13 14 13ZM20 18.0684C20 18.3064 19.776 18.5004 19.5 18.5004H4.5C4.224 18.5004 4 18.3064 4 18.0684V13.7554V5.9314C4 5.6934 4.224 5.5004 4.5 5.5004H8.626L11.226 8.6784C11.416 8.9114 11.7 9.0464 12 9.0464H19.5C19.776 9.0464 20 9.2394 20 9.4784V18.0684ZM19.5 7.0464H12.474L9.875 3.8674C9.685 3.6344 9.4 3.5004 9.101 3.5004H4.5C3.122 3.5004 2 4.5904 2 5.9314V18.0684C2 19.4094 3.122 20.5004 4.5 20.5004H19.5C20.878 20.5004 22 19.4094 22 18.0684V9.4784C22 8.1364 20.878 7.0464 19.5 7.0464Z"
    fill={fill}
  />
</SvgIcon>);

export default FolderIcon;
