export const fetchAllCollectionsSelector = state => state.fetchAllCollections;
export const createCollectionSelector = state => state.createCollection;
export const editCollectionSelector = state => state.editCollection;
export const deleteCollectionSelector = state => state.deleteCollection;
export const isDialogLoadingSelector = state => state.isDialogLoading;
export const hasDialogErrorSelector = state => state.hasDialogError;
export const setDialogErrorSelector = state => state.setDialogError;
export const setDialogLoadingSelector = state => state.setDialogLoading;
export const collectionsSelector = state => state.collections;
export const isPageLoadingSelector = state => state.isPageLoading;
export const hasPageErrorSelector = state => state.hasPageError;
export const resetCollectionsDataSelector = state => state.resetCollectionsData;
export const hasAssistantConfigSelector = state => state.currentCollectionAssistantConfig && !!Object.keys(state.currentCollectionAssistantConfig).length;
export const fetchCollectionSelector = state => state.fetchCollection;
export const currentCollectionAssistantConfigSelector = state => state.currentCollectionAssistantConfig;
export const collectionConfigSelector = state => state.currentCollectionConfig;
export const originalCollectionConfigSelector = state => state.originalCollectionConfig;
export const collectionsLanguagesSelector = state => state.collectionsLanguages;
export const defaultCollectionsLanguagesSelector = state => state.defaultCollectionsLanguages;
export const selectedCollectionSelector = state => state.selectedCollection;
export const setSelectedCollectionSelector = state => state.setSelectedCollection;
export const setIsUpdatingCollectionSelector = state => state.setIsUpdatingCollection;
export const isUpdatingCollectionSelector = state => state.isUpdatingCollection;
export const updateCollectionSelector = state => state.updateCollection;
export const collectionConfigChangedSelector = state => state.collectionConfigChanged;
export const setCollectionConfigChangedSelector = state => state.setCollectionConfigChanged;
export const isCollectionLoadingSelector = state => state.isCollectionLoading;
export const isSavingChangesSelector = state => state.isSavingChanges;
export const setCollectionNameSelector = state => state.setCollectionName;
export const collectionNameSelector = state => state.collectionName;
export const getCollectionRefsSelector = state => state.getCollectionRefs;
export const getCollectionOptionsSelector = state => state.getCollectionOptions;
export const failedToUpdateDraftSelector = state => state.failedToUpdateDraft;

export const setFailedToUpdateDraftSelector = state =>
  state.setFailedToUpdateDraft;

export const mandatoryKeysSelector = state => state.currentMandatoryKeys;

export const lastCollectionCreatedKeySelector = state => state.lastCollectionCreatedKey;
export const setLastCollectionCreatedKeySelector = state => state.setLastCollectionCreatedKey;
export const collectionBreadCrumbsSelector = state => state.collectionBreadCrumbs;

export const collectionBreadCrumbsLastItemSelector = state => state.collectionBreadCrumbs[state.collectionBreadCrumbs.length - 1];
export const addToCollectionBreadCrumbsSelector = state => state.addToCollectionBreadCrumbs;
export const removeLastItemFromCollectionBreadCrumbsSelector = state => state.removeLastItemFromCollectionBreadCrumbs;

export const clearCollectionBreadCrumbsSelector = state => state.clearCollectionBreadCrumbs;

export const setIgnoreUrlChangeSelector = state => state.setIgnoreUrlChange;
export const ignoreUrlChangeSelector = state => state.ignoreUrlChange;
export const hasSpotCollectionSelector = state => state.hasSpotCollection;
