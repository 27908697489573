import {
  ACCOUNT_MANAGEMENT,
  ADMIN,
  ALL_ASSISTANTS,
  AUDIT_LOG,
  BACK_OFFICE,
  BRANCH_DEPLOYER,
  BUILDING_BLOCKS,
  CHANNEL_SETTINGS,
  CHAT_INSIGHTS,
  CONVERSATIONS,
  CORE_HYRO_KPIS,
  DATA_SOURCES,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  HOME,
  INSIGHTS,
  INSIGHTS_DASHBOARD_NAMES,
  INTEGRATIONS,
  KNOWLEDGE_DEBUGGER,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  PIPELINE_PERFORMANCE,
  PROMPT_PLAYGROUND,
  QUESTION_GENERATOR,
  RESPONSES,
  SETTINGS,
  SKILLS,
  SYNONYMS,
  VOICE_INSIGHTS
} from "../utils";
import { Navigate, Outlet, Route, Routes, useLocation, useParams, useSearchParams } from "react-router-dom";
import React, { lazy, useEffect, useLayoutEffect } from "react";
import {
  accountIdsSelector,
  allAssistantIdsSelector,
  assistantIdSelector,
  getAccountByAssistantSelector,
  hasAccountPermissionSelector,
  hasPermissionSelector,
  selectedAccountIdSelector,
  setAccountWithoutAssistantsSelector,
  setAssistantIdSelector,
  setPageNameSelector,
  setSelectedAccountSelector,
  systemModeSelector,
  userPermissionsSelector
} from "../stores/selectors/appSelectors";
import AccountLayout from "./SubLayouts/AccountLayout";
import AccountManagement from "../components/widgets/account-management/AccountManagement";
import AdminLayout from "./SubLayouts/AdminLayout";
import AssistantInsights from "../pages/AssistantInsights";
import AssistantLayout from "./SubLayouts/AssistantLayout";
import { Box, GlobalStyles } from "@mui/material";
import ForbiddenErrorPage from "./ErrorPages/ForbiddenErrorPage";
import Header from "./Header";
import HyroLoader from "../components/hyro-components/HyroLoader";
import NotFoundErrorPage from "./ErrorPages/NotFoundErrorPage";
import { USER_PERMISSIONS } from "@hyro/dashboard-commons";
import useAppStore from "../stores/appStore";
import { HYRO_THEME } from "@hyro/ui";
import { logout } from "../auth/frontegg";
import AuditLog from "../pages/audit-log/AuditLog";
import BranchDeployer from "../pages/branch-deployer/BranchDeployer";
import DraftPublishHeader from "./Header/header-types/status-header/DraftPublishing/DraftPublishHeader";
import {useDashboardSettings, useErrorPage, useIsCurrentPageReadonly, useIsPortalPage} from "../hooks";
import ReadOnlyHeader from "./Header/header-types/status-header/Permissions/ReadOnlyHeader";
import { SYSTEM_MODES } from "../stores/slices/createAppSlice";
import { resetCollectionsDataSelector } from "../stores/selectors/collectionsSelectors";
import DashboardSettingsBanner from "src/components/common/DashboardSettingsBanner";

const AllAssistants = lazy(() => import("../components/widgets/all-assistants/AllAssistants"));
const PipelinePerformance = lazy(() => import("../pages/PipelinePerformance"));
const ChannelSettings = lazy(() => import("../pages/Settings/ChannelSettings/ChannelSettings"));
const Conversation = lazy(() => import("../pages/Conversations/Conversation"));
const DataSources = lazy(() => import("../pages/DataSources"));
const FindAProvider = lazy(() => import("../pages/FindAProvider"));
const Conversations = lazy(() => import("../pages/Conversations/Conversations"));
const GeneralSettings = lazy(() => import("../pages/Settings/GeneralSettings/GeneralSettings"));
const KnowledgeDebugger = lazy(() => import("../pages/KnowledgeDebugger"));
const KnowledgeEditor = lazy(() => import("../pages/KnowledgeEditor/KnowledgeEditor"));
const KnowledgeExplorer = lazy(() => import("../pages/KnowledgeExplorer/KnowledgeExplorer"));
const LookerInsightsDashboard = lazy(() => import("../components/hyro-components/LookerAdminInsightsDashboard"));
const Overview = lazy(() => import("../pages/Overview"));
const PromptPlayground = lazy(() => import("../pages/PrompPlayground"));
const QuestionGenerator = lazy(() => import("../pages/QuestionGenerator"));
const Responses = lazy(() => import("../pages/Responses"));
const Collections = lazy(() => import("../pages/Collections"));
const Synonyms = lazy(() => import("../pages/Synonyms"));
const DashboardSettings = lazy(() => import("../pages/admin/Settings"));
const CollectionPage = lazy(() => import("../components/collections/assistant-configuration/CollectionPage"));

const AccessAssistant = () => {
  const { assistantId } = useParams();
  const assistantIds = useAppStore(allAssistantIdsSelector);
  const setSelectAssistantId = useAppStore(setAssistantIdSelector);
  const selectAssistantId = useAppStore(assistantIdSelector);
  const resetCollectionsData = useAppStore(resetCollectionsDataSelector);

  useLayoutEffect(() => {
    if (selectAssistantId !== assistantId) {
      setSelectAssistantId(assistantId);
      resetCollectionsData();
    }
  }, [resetCollectionsData, assistantId, selectAssistantId, setSelectAssistantId]);

  if (!assistantIds) return <HyroLoader height="100vh" />;
  if (!assistantIds?.includes(assistantId)) return <NotFoundErrorPage />;
  return <AssistantLayout assistantId={assistantId} />;
};

const SelectAccount = () => {
  const userPermissions = useAppStore(userPermissionsSelector);
  const accountIds = useAppStore(accountIdsSelector);
  const DEFAULT_ACCOUNT_ID = "hyro";
  const ErrorPage = useErrorPage();

  if (ErrorPage) return <ErrorPage />;
  if (!accountIds) return <HyroLoader height="100vh" />;

  const accountToNavigateTo = accountIds.includes(DEFAULT_ACCOUNT_ID) ? DEFAULT_ACCOUNT_ID : accountIds[0];
  if (userPermissions?.accounts?.length === 0 || userPermissions?.permissions?.length === 0) {
    return logout({ returnTo: window.location.origin });
  }
  return <Navigate to={`/${accountToNavigateTo}`} replace />;
};

const AccessAccount = () => {
  const userPermissions = useAppStore(userPermissionsSelector);
  const { accountId } = useParams();
  const accountIds = useAppStore(accountIdsSelector);
  const location = useLocation();
  const setPageName = useAppStore(setPageNameSelector);

  useEffect(() => {
    setPageName(location.pathname.split('/')?.[3]);
  }, [location, setPageName]);

  const ErrorPage = useErrorPage();

  if (ErrorPage) return <ErrorPage />;
  if (!accountIds || !userPermissions?.permissions) return <HyroLoader height="100vh" />;
  if (userPermissions?.accounts?.length === 0 || userPermissions?.permissions?.length === 0) {
    return logout({ returnTo: window.location.origin });
  }

  if (!accountIds.includes(accountId)) return <ForbiddenErrorPage />;

  return <AccountOutlet accountId={accountId} />;
};

const AccessAdminPanel = () => {
  const accountId = "hyro-admin-panel";
  const selectedAccountId = useAppStore(selectedAccountIdSelector);
  const selectAccount = useAppStore(setAccountWithoutAssistantsSelector);
  const dashboardSettings = useDashboardSettings();

  useEffect(() => {
    if (selectedAccountId !== accountId) selectAccount(accountId);
  }, [accountId, selectAccount, selectedAccountId]);

  return <>
    {dashboardSettings?.banner?.show ? <DashboardSettingsBanner dashboardSettings={dashboardSettings}/>: null}
    <AdminLayout />
  </>;
};

const AccessOldDashboardRedirect = () => {
  const userPermissions = useAppStore(userPermissionsSelector);
  const { assistantId } = useParams();
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get("conversationId");
  const selectedAccountId = useAppStore(selectedAccountIdSelector);
  const getAccountByAssistant = useAppStore(getAccountByAssistantSelector);

  useEffect(() => {
    userPermissions?.permissions && getAccountByAssistant(assistantId);
  }, [userPermissions, getAccountByAssistant, assistantId]);

  if (!userPermissions?.permissions || !selectedAccountId) return <HyroLoader height="100vh" />;
  return (<Navigate
    to={`/${selectedAccountId}/${assistantId}/conversations/${conversationId}`}
    replace
  />);
};

const AccountOutlet = ({ accountId }) => {
  const selectedAccountId = useAppStore(selectedAccountIdSelector);
  const selectAccount = useAppStore(setSelectedAccountSelector);
  const isPortalPage = useIsPortalPage();
  const isPageReadonly = useIsCurrentPageReadonly();
  const systemMode = useAppStore(systemModeSelector);
  const dashboardSettings = useDashboardSettings();

  useEffect(() => {
    if (selectedAccountId !== accountId) selectAccount(accountId);
  }, [accountId, selectAccount, selectedAccountId]);

  if (!selectedAccountId) return <HyroLoader height="100vh" />;
  return <>
    {dashboardSettings?.banner?.show ? <DashboardSettingsBanner dashboardSettings={dashboardSettings}/> : <>
      {systemMode === SYSTEM_MODES.EDIT ? <>
        {(dashboardSettings?.banner?.show || (isPortalPage && !isPageReadonly)) && <DraftPublishHeader />}
        {isPageReadonly && !dashboardSettings?.banner?.show && <ReadOnlyHeader />}
      </> : null}
    </>}
    <Outlet />
  </>;
};

const WithAccountPermission = ({ permission, customRedirect, children }) => {
  const hasAccountPermission = useAppStore(hasAccountPermissionSelector);
  const redirect = customRedirect || "/403";
  if (!hasAccountPermission(permission)) return <Navigate to={redirect} replace />;
  return children;
};

const WithPermission = ({ permission, children }) => {
  const userPermissions = useAppStore(userPermissionsSelector);
  const hasPermission = useAppStore(hasPermissionSelector);
  if (!userPermissions?.permissions) return <HyroLoader height="100vh" />;
  if (userPermissions?.accounts?.length === 0 || userPermissions?.permissions?.length === 0) {
    return logout({ returnTo: window.location.origin });
  }
  if (!hasPermission(permission)) return <ForbiddenErrorPage />;
  return children;
};

const MainLayout = () => <Box>
  <GlobalStyles styles={{
    body: {
      color: HYRO_THEME.palette.text.primary,
      "& .MuiDrawer-root .MuiBackdrop-root": { backgroundColor: "rgba(22, 20, 83, 0.5)" }
    }
  }} />
  <Header />
  <Routes>
    <Route index element={<SelectAccount />} />

    <Route
      path="/dashboard/:assistantId"
      name="Old Dashboard Redirect"
      element={<AccessOldDashboardRedirect />}
    />

    <Route path=":accountId" name="Account" element={<AccessAccount />}>
      <Route index name="Account Home" element={<AccountLayout />} />

      <Route
        path=":assistantId"
        name="Assistant"
        element={<AccessAssistant />}
      >
        <Route
          index
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.HOME_PAGE.READ}
            customRedirect={CONVERSATIONS}
          >
            <Navigate to={HOME} replace />
          </WithAccountPermission>)}
        />

        <Route
          path={HOME}
          name="Home"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.HOME_PAGE.READ}
          >
            <Overview />
          </WithAccountPermission>)}
        />

        <Route
          path={INSIGHTS}
          name="Insights"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.INSIGHTS_PAGE.READ}
          >
            <AssistantInsights />
          </WithAccountPermission>)}
        />
        <Route
          path={CONVERSATIONS + "/:conversationId"}
          name="Conversations"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.CONVERSATIONS_PAGE.READ}
          >
            <Conversation />
          </WithAccountPermission>)}
        />
        <Route
          path={RESPONSES}
          name="Responses"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.RESPONSES_PAGE.READ}
          >
            <Responses />
          </WithAccountPermission>)}
        />
        <Route
          path={RESPONSES + "/:responseType"}
          name="Responses"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.RESPONSES_PAGE.READ}
          >
            <Responses />
          </WithAccountPermission>)}
        />
        <Route
          path={KNOWLEDGE_EXPLORER}
          name="Knowledge Explorer"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.KNOWLEDGE_EXPLORER_PAGE.READ}
          >
            <KnowledgeExplorer />
          </WithAccountPermission>)}
        />
        <Route
          path={CONVERSATIONS}
          name="Conversations"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.CONVERSATIONS_PAGE.READ}
          >
            <Conversations />
          </WithAccountPermission>)}
        />
        <Route
          path={SKILLS}
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.SKILLS_PAGE.READ}
          >
            <Collections writePermission={USER_PERMISSIONS.SKILLS_PAGE.WRITE} />
          </WithAccountPermission>)}
        >
          <Route
            path={':collectionKey/:collectionInstanceKey'}
            element={(<WithAccountPermission permission={USER_PERMISSIONS.SKILLS_PAGE.READ}>
              <CollectionPage writePermission={USER_PERMISSIONS.SKILLS_PAGE.WRITE} />
            </WithAccountPermission>)}
          />
        </Route>
        <Route
          path={BUILDING_BLOCKS}
          element={<WithAccountPermission permission={USER_PERMISSIONS.BUILDING_BLOCKS_PAGE.READ}>
            <Collections writePermission={USER_PERMISSIONS.BUILDING_BLOCKS_PAGE.WRITE} />
          </WithAccountPermission>}>
          <Route
            path={':collectionKey/:collectionInstanceKey'}
            element={(<WithAccountPermission
              permission={USER_PERMISSIONS.BUILDING_BLOCKS_PAGE.READ}
            >
              <CollectionPage writePermission={USER_PERMISSIONS.BUILDING_BLOCKS_PAGE.WRITE} />
            </WithAccountPermission>)}>
          </Route>
        </Route>
        <Route
          path={INTEGRATIONS}
          name="Integrations - New!"
          element={<WithAccountPermission permission={USER_PERMISSIONS.INTEGRATIONS_PAGE.READ}>
            <Collections writePermission={USER_PERMISSIONS.INTEGRATIONS_PAGE.WRITE} />
          </WithAccountPermission>}>
          <Route
            path={':collectionKey/:collectionInstanceKey'}
            element={(<WithAccountPermission
              permission={USER_PERMISSIONS.INTEGRATIONS_PAGE.READ}
            >
              <CollectionPage writePermission={USER_PERMISSIONS.INTEGRATIONS_PAGE.WRITE} />
            </WithAccountPermission>)}>
          </Route>
        </Route>
        <Route path={SETTINGS} name="Settings" element={<Outlet />}>
          <Route index element={<Navigate to={GENERAL_SETTINGS} replace />} />
          <Route
            path={GENERAL_SETTINGS}
            name="General"
            element={(<WithAccountPermission
              permission={USER_PERMISSIONS.SETTINGS_PAGE.READ}
            >
              <GeneralSettings />
            </WithAccountPermission>)}
          />
          <Route
            path={CHANNEL_SETTINGS}
            name="Channel"
            element={(<WithAccountPermission
              permission={USER_PERMISSIONS.SETTINGS_PAGE.READ}
            >
              <ChannelSettings />
            </WithAccountPermission>)}
          />
        </Route>
        <Route
          path={DATA_SOURCES}
          name="Data Sources"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.DATA_SOURCES_PAGE.READ}
          >
            <DataSources />
          </WithAccountPermission>)}
        />
        <Route
          path={FIND_A_PROVIDER}
          name="Find a Provider"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.FIND_A_PROVIDER_PAGE.READ}
          >
            <FindAProvider />
          </WithAccountPermission>)}
        />
        <Route
          path={KNOWLEDGE_EDITOR}
          name="Knowledge Editor"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.KNOWLEDGE_EDITOR_PAGE.READ}
          >
            <KnowledgeEditor />
          </WithAccountPermission>)}
        />
        <Route
          path={SYNONYMS}
          name="Synonyms"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.SYNONYMS_PAGE.READ}
          >
            <Synonyms />
          </WithAccountPermission>)}
        />
        <Route
          path={AUDIT_LOG}
          name="Audit Log"
          element={(<WithAccountPermission
            permission={USER_PERMISSIONS.AUDIT_LOG.READ}
          >
            <AuditLog />
          </WithAccountPermission>)}
        />
        <Route
          path={BRANCH_DEPLOYER}
          name="Branch Deployer"
          element={(
            <WithAccountPermission
              permission={USER_PERMISSIONS.ADMIN_PANEL.READ}
            >
              <BranchDeployer />
            </WithAccountPermission>
          )}
        />
      </Route></Route>
    <Route
      path={ADMIN}
      name="Admin Panel"
      element={(<WithPermission permission={USER_PERMISSIONS.ADMIN_PANEL.READ}>
        <AccessAdminPanel />
      </WithPermission>)}
    >
      <Route index element={<Navigate to={ALL_ASSISTANTS} replace />} />
      <Route
        path={ALL_ASSISTANTS}
        name="All Assistants"
        element={<AllAssistants />}
      />
      <Route
        path={ACCOUNT_MANAGEMENT}
        name="Account Management"
        element={<AccountManagement />}
      />
      <Route
        path={AUDIT_LOG}
        name="Audit Log"
        element={<WithPermission permission={USER_PERMISSIONS.AUDIT_LOG.READ}>
          <AuditLog /></WithPermission>}
      />
      <Route
        path={BRANCH_DEPLOYER}
        name="Branch Deployer"
        element={<WithPermission permission={USER_PERMISSIONS.ADMIN_PANEL.READ}>
          <BranchDeployer />
        </WithPermission>}
      />
      <Route
        path={SETTINGS}
        element={<WithPermission permission={USER_PERMISSIONS.ADMIN_PANEL.READ}>
          <DashboardSettings />
        </WithPermission>}
      />
      <Route path={INSIGHTS} name="Insights" element={<Outlet />}>
        <Route index element={<Navigate to={VOICE_INSIGHTS} replace />} />
        <Route
          path={CORE_HYRO_KPIS}
          element={(<LookerInsightsDashboard
            dashboardName={INSIGHTS_DASHBOARD_NAMES.coreHyroKpis}
          />)}
        />
        <Route
          path={PIPELINE_PERFORMANCE}
          element={<PipelinePerformance />}
        />
        <Route
          path={CHAT_INSIGHTS}
          element={(<LookerInsightsDashboard
            dashboardName={INSIGHTS_DASHBOARD_NAMES.chat}
          />)}
        />
        <Route
          path={VOICE_INSIGHTS}
          element={(<LookerInsightsDashboard
            dashboardName={INSIGHTS_DASHBOARD_NAMES.voice}
          />)}
        />
      </Route>
      <Route path={BACK_OFFICE} name="Back office" element={<Outlet />}>
        <Route index element={<Navigate to={KNOWLEDGE_DEBUGGER} replace />} />
        <Route
          path={KNOWLEDGE_DEBUGGER}
          name="Knowledge Debugger"
          element={<KnowledgeDebugger />}
        />
        <Route
          path={KNOWLEDGE_EXPLORER}
          name="Knowledge Explorer"
          element={<KnowledgeExplorer />}
        />
        <Route
          path={PROMPT_PLAYGROUND}
          name="Prompt Playground"
          element={<PromptPlayground />}
        />
        <Route
          path={QUESTION_GENERATOR}
          name="Question Generator"
          element={<QuestionGenerator />}
        />
      </Route>
    </Route>

    <Route path="*" element={<NotFoundErrorPage />} />
  </Routes>
</Box>;

export default MainLayout;
