import React from "react";

const NewDeploymentIcon = ({ fill }) => (<svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill={fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5.82732 5.07L4.67232 4.11L0.614819 9L4.67232 13.89L5.82732 12.93L2.56482 9L5.82732 5.07ZM5.24982 9.75H6.74982V8.25H5.24982V9.75ZM12.7498 8.25H11.2498V9.75H12.7498V8.25ZM8.24982 9.75H9.74982V8.25H8.24982V9.75ZM13.3273 4.11L12.1723 5.07L15.4348 9L12.1723 12.93L13.3273 13.89L17.3848 9L13.3273 4.11Z"
    fill={fill}
    fillOpacity="1"
  />
</svg>);

export default NewDeploymentIcon;
