import React from "react";

const ArrowLeftIcon = ({stroke, size}) => (<svg xmlns="http://www.w3.org/2000/svg" width={size ? size:  "24"} height={size ? size :"24"} viewBox="0 0 24 24" fill="none">
  <path
    d="M19 12H5M5 12L12 19M5 12L12 5"
    stroke={stroke? stroke :"#4548C4"}
    strokeWidth="1.8"
    strokeLinecap="round"
    strokeLinejoin="round"/>
</svg>);

export default ArrowLeftIcon;
