import { Typography } from "@mui/material";
import { isDraftOwnerSelector, submittedDraftSelector, userDataSelector } from "src/stores/selectors/appSelectors";
import React from "react";
import { draftSelector, isFirstDraftSelector } from "src/stores/selectors/tripletsSelectors";
import { BUILD_STATUSES, extractNameFromEmail } from "src/utils";
import useAppStore from "../../../../../stores/appStore";
import { StyledDivider } from "../PortalHeaderStyles";

const HeaderTextContent = ({ isReadOnly }) => {
  const { given_name, name } = useAppStore(userDataSelector);
  const displayName = given_name || name?.split(" ")[0];
  const isDraftOwner = useAppStore(isDraftOwnerSelector);
  const submittedDraft = useAppStore(submittedDraftSelector);
  const draft = useAppStore(draftSelector);
  const isFirstDraft = useAppStore(isFirstDraftSelector);
  const buildDraftResponse = useAppStore(state => state.buildDraftResponse);

  const getMainText = () => {
    if (isReadOnly) {
      if (submittedDraft) {
        return isDraftOwner
          ? `${displayName}, your changes are being processed, and will be published soon`
          : `${extractNameFromEmail(
            submittedDraft?.createdBy
          )}'s change request is being processed`;
      } else if (draft) {
        return `This page is currently being edited by ${extractNameFromEmail(
          draft?.userEmail
        )}`;
      }
    }
    if ([BUILD_STATUSES.PENDING, BUILD_STATUSES.KG_UPDATE].includes(buildDraftResponse?.status)) {
      return 'Your changes are being built, we’ll notify you when they’re ready';
    } else if (buildDraftResponse?.status === BUILD_STATUSES.LIVE){
      return 'Your changes have been built, and are now ready to preview';
    }
    if (isFirstDraft) {
      return 'Please remember to set your assistant live, otherwise it will be discarded after 7 days';
    }
    return 'Your changes have been submitted';
  };

  return (
    <>
      <Typography variant="subtitle2">{getMainText()}</Typography>
      {isReadOnly ? <>
        <StyledDivider isReadOnly={isReadOnly} orientation="vertical" />
        <Typography variant="subtitle2">Read only mode</Typography>
      </> : null}
    </>
  );
};

export default HeaderTextContent;
