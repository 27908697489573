import { Box, Typography } from "@mui/material";
import React from "react";
import emptyBoxIcon from "../static/images/empty_box.svg";
import { APP_HEADER_HEIGHT } from "../layout/consts";

const NothingHereYet = ({ isNotFullPage, customHeight, testId }) => (<Box
  mt={4}
  mx={4}
  display="flex"
  flexDirection="column"
  height={customHeight ?? (isNotFullPage ? "unset" : `calc(100vh - ${APP_HEADER_HEIGHT}px)`)}
>
  <Box
    display="flex"
    flexDirection="column"
    flex={1}
    alignItems="center"
    justifyContent="center"
  >
    <img width="102px" height="56px" src={emptyBoxIcon} alt="" />
    <Typography data-testid={testId || 'nothing-here-yet'} mt={2} variant="body1" color="text.secondary">
              Nothing here yet
    </Typography>
  </Box>
</Box>);

export default NothingHereYet;
