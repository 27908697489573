import React from "react";

const CheckStatusIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_8766_143)">
    <circle cx="24" cy="24" r="23.5" fill="#EEEEFF" stroke="#DEDFFF"/>
    <path d="M18 27L20 29L24.5 24.5M20 20V17.2C20 16.0799 20 15.5198 20.218 15.092C20.4097 14.7157 20.7157 14.4097 21.092 14.218C21.5198 14 22.0799 14 23.2 14H30.8C31.9201 14 32.4802 14 32.908 14.218C33.2843 14.4097 33.5903 14.7157 33.782 15.092C34 15.5198 34 16.0799 34 17.2V24.8C34 25.9201 34 26.4802 33.782 26.908C33.5903 27.2843 33.2843 27.5903 32.908 27.782C32.4802 28 31.9201 28 30.8 28H28M17.2 34H24.8C25.9201 34 26.4802 34 26.908 33.782C27.2843 33.5903 27.5903 33.2843 27.782 32.908C28 32.4802 28 31.9201 28 30.8V23.2C28 22.0799 28 21.5198 27.782 21.092C27.5903 20.7157 27.2843 20.4097 26.908 20.218C26.4802 20 25.9201 20 24.8 20H17.2C16.0799 20 15.5198 20 15.092 20.218C14.7157 20.4097 14.4097 20.7157 14.218 21.092C14 21.5198 14 22.0799 14 23.2V30.8C14 31.9201 14 32.4802 14.218 32.908C14.4097 33.2843 14.7157 33.5903 15.092 33.782C15.5198 34 16.0799 34 17.2 34Z" stroke="#7583F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8766_143">
      <rect width="48" height="48" fill="white"/>
    </clipPath>
  </defs>
</svg>);

export default CheckStatusIcon;
