import React from "react";

const ForbiddenIcon = () => (<svg
  width="192"
  height="168"
  viewBox="0 0 192 168"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M146.14 144.189C125.595 160.532 100.382 169.977 77.3475 165.307C54.313 160.638 33.4574 141.854 22.7702 118.508C11.9793 95.0547 11.1492 67.1447 21.9401 45.4959C32.6273 23.847 54.9355 8.67164 78.0738 4.42677C101.316 0.288026 125.492 7.07982 145.725 21.8307C165.854 36.4755 182.04 59.0795 182.248 81.8956C182.455 104.818 166.684 127.952 146.14 144.189Z"
    fill="#EAF1F8"
  />
  <path
    d="M48.772 57.2356L143.452 53.837C147.905 53.8205 151.543 56.89 151.585 60.7083L152.249 116.905C152.299 120.781 148.636 123.933 144.117 123.916L47.825 123.62C43.3472 123.604 39.7252 120.477 39.7501 116.642L40.6972 64.1481C40.7221 60.338 44.3192 57.2521 48.772 57.2356Z"
    fill="white"
  />
  <path
    d="M104.25 94.468C104.25 94.468 114.954 95.3067 122.359 94.468C129.764 93.6293 138 94.468 138 94.468"
    stroke="#24244E"
    strokeWidth="5"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M105 79.1848H110.25"
    stroke="#24244E"
    strokeWidth="5"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M117 79.9304H124.5"
    stroke="#24244E"
    strokeWidth="5"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M130.5 79.9304C133.071 79.9304 136.5 79.9304 136.5 79.9304"
    stroke="#24244E"
    strokeWidth="5"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M57.4902 105.475C55.824 104.033 55.1359 101.775 55.6913 99.6494C56.9099 95.0101 60.5326 87.4537 71.2015 88.1706C80.254 88.7721 84.9294 93.3703 87.2423 97.0207C88.8256 99.5258 88.1542 102.83 85.7584 104.585C80.055 108.763 68.383 114.894 57.4902 105.475Z"
    fill="#4548C4"
  />
  <path
    d="M65.4652 72.7281C65.4652 72.7281 58.9909 78.3068 67.0071 84.52C75.0234 90.7332 83.5784 78.6117 79.5247 74.7799C75.471 70.9481 71.5499 68.0558 65.4652 72.7281Z"
    fill="#4548C4"
  />
  <path
    d="M137.612 15.6746C137.612 15.6746 117.975 24.515 118.511 41.1405C119.047 57.766 118.637 61.3398 127.541 69.4906C136.445 77.6414 157.806 78.9581 166.805 74.6737C175.803 70.3893 190.216 57.7765 184.077 37.713C176.361 12.4874 149.081 9.30022 137.612 15.6746Z"
    fill="#E9416A"
  />
  <path
    d="M160.779 41.633C161.223 41.633 161.578 41.7214 161.934 41.7214C163.533 41.898 164.688 43.223 164.688 44.7246C164.688 48.8762 164.688 53.0277 164.688 57.1792C164.688 59.2992 163.355 60.6241 161.223 60.6241C155.091 60.6241 148.96 60.6241 142.829 60.6241C140.607 60.6241 139.363 59.3875 139.363 57.1792C139.363 53.2927 139.363 49.3178 139.363 45.4313C139.363 42.958 140.429 41.8097 142.918 41.7214C143.006 41.7214 143.184 41.7214 143.362 41.633C143.362 41.3681 143.451 41.1031 143.451 40.8381C143.451 39.0715 143.451 37.3048 143.451 35.4499C143.451 31.8283 146.205 29.0901 149.849 29.0901C151.448 29.0901 152.959 29.0901 154.558 29.0901C158.024 29.0901 160.779 31.8283 160.867 35.2732C160.867 37.1282 160.867 38.8948 160.867 40.7497C160.779 41.0147 160.779 41.2797 160.779 41.633ZM156.513 41.633C156.513 39.3365 156.602 37.1282 156.513 34.9199C156.513 33.9483 155.536 33.33 154.469 33.33C152.959 33.33 151.448 33.33 149.938 33.33C148.605 33.33 147.805 34.1249 147.716 35.4499C147.716 36.5099 147.716 37.5698 147.716 38.6298C147.716 39.6015 147.716 40.6614 147.716 41.633C150.648 41.633 153.581 41.633 156.513 41.633ZM150.826 53.381C150.826 53.911 150.826 54.3527 150.826 54.8826C150.826 56.3843 150.915 56.4726 152.426 56.4726C153.225 56.4726 153.492 56.1193 153.492 55.4126C153.492 54.2643 153.492 53.2044 153.492 52.0561C153.492 51.3494 153.581 50.7311 154.203 50.2011C154.469 49.9361 154.647 49.4945 154.736 49.0528C155.003 47.9045 154.292 46.5796 153.403 46.1379C152.159 45.6079 150.737 46.0496 150.026 47.1095C149.315 48.1695 149.404 49.4945 150.293 50.3778C150.737 50.8194 150.915 51.2611 150.826 51.7911C150.737 52.2327 150.826 52.7627 150.826 53.381Z"
    fill="white"
  />
</svg>);

export default ForbiddenIcon;
