import { Box, Button, Alert  } from "@mui/material";
import React, { forwardRef } from "react";
import { styled } from "@mui/material/styles";

const StyledAlert = styled(Alert)(({ theme }) => ({
  ".MuiAlert-icon": {
    color: theme.palette.common.white
  }
}));

/**
 * Severity can be "info", "success" or "warning"
 * @param alertText
 * @param dismissText
 * @param severity
 * @param handleDismiss
 * @returns {JSX.Element}
 */
export const HyroAlert = forwardRef(
  ({ alertText, dismissText, severity, handleDismiss }, ref) => (
    <StyledAlert
      ref={ref}
      variant="filled"
      severity={severity}
      sx={{zIndex: '1301'}}
      action={(
        <Button
          size="small"
          onClick={handleDismiss}
          sx={theme => ({
            fontWeight: 500,
            color: theme.palette.common.white
          })}
        >
          {dismissText}
        </Button>
      )}
    >
      <Box
        fontWeight={400}
        fontSize={16}
        sx={theme => ({ color: theme.palette.common.white })}
      >
        {alertText}
      </Box>
    </StyledAlert>
  )
);

export default HyroAlert;
