import { Stack, TextField } from "@mui/material";
import { DIALOG_HELPER_TEXTS } from "./AccountEditorConsts";
import React from "react";

const validateAccountName = (value) => {
  const regexp = /^(?! )+[a-zA-Z0-9 ]*(?<! )$/i;
  const matches = value.match(regexp);
  return value.length && matches ? "" : DIALOG_HELPER_TEXTS.accountName;
};
const validateAccountId = (value) => {
  const regexp = /^(?! )+[a-z0-9]+(?:-[a-z0-9]+)*(?<! )$/i;
  const matches = value.match(regexp);
  return value.length && matches ? "" : DIALOG_HELPER_TEXTS.accountId;
};

const AccountEditorFields = ({ data, onChange, type }) => (
  <Stack gap={2} my={3}>
    <TextField
      onChange={e => onChange(e, validateAccountName)}
      value={data?.name.value}
      data-testid='create-account-input-name'
      name="name"
      label="Account name"
      error={!!data?.name.errorText}
      helperText={data?.name.errorText || " "}
    />

    {type === 'edit' && <TextField
      onChange={e => onChange(e, validateAccountId)}
      value={data?.accountId.value}
      data-testid='create-account-input-id'
      name="accountId"
      label="Account Id"
      error={!!data?.accountId.errorText}
      helperText={data?.accountId.errorText || " "}
    />}
  </Stack>
);

export default AccountEditorFields;
