import React from "react";

const SipIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#FEF9DD" stroke="#FEE799"/>
    <path d="M32 24.5V18.8C32 17.1198 32 16.2798 31.673 15.638C31.3854 15.0735 30.9265 14.6146 30.362 14.327C29.7202 14 28.8802 14 27.2 14H20.8C19.1198 14 18.2798 14 17.638 14.327C17.0735 14.6146 16.6146 15.0735 16.327 15.638C16 16.2798 16 17.1198 16 18.8V29.2C16 30.8802 16 31.7202 16.327 32.362C16.6146 32.9265 17.0735 33.3854 17.638 33.673C18.2798 34 19.1198 34 20.8 34H24M26 23H20M22 27H20M28 19H20M26.5 31L28.5 33L33 28.5" stroke="#F1B00A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>;

export default SipIcon;
