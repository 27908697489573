import ErrorPage from "./ErrorPage";
import InternalErrorIcon from "./InternalErrorIcon";
import React from "react";

const InternalErrorPage = () => {
  const pageLinks = [
    { url: window.location.pathname, text: "Reload" },
    { url: "mailto:support@hyro.ai", text: "Contact support" }
  ];

  return (<ErrorPage
    code="500"
    message="An error occurred"
    explanation="This page isn’t working"
    links={pageLinks}
    Icon={InternalErrorIcon}
  />);
};

export default InternalErrorPage;
