import React from "react";

const LegacyIcon = ({ fill }) => (<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="Icon/Outline/code">
    <path
      id="Mask"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9513 5.37306L21.7793 11.3731C22.0783 11.7441 22.0733 12.2741 21.7683 12.6411L16.7683 18.6411C16.5703 18.8781 16.2863 19.0001 15.9993 19.0001C15.7733 19.0001 15.5473 18.9241 15.3593 18.7691C14.9353 18.4151 14.8783 17.7841 15.2313 17.3591L19.7083 11.9891L15.3923 6.62706C15.0473 6.19606 15.1143 5.56706 15.5453 5.22106C15.9753 4.87306 16.6043 4.94306 16.9513 5.37306ZM7.23155 5.35946C7.58455 4.93546 8.21355 4.87846 8.64055 5.23146C9.06455 5.58546 9.12155 6.21546 8.76855 6.64046L4.29155 12.0105L8.60755 17.3735C8.95255 17.8035 8.88555 18.4325 8.45455 18.7795C8.27055 18.9275 8.04855 19.0005 7.82855 19.0005C7.53655 19.0005 7.24655 18.8725 7.04855 18.6275L2.22055 12.6275C1.92155 12.2555 1.92655 11.7255 2.23155 11.3595L7.23155 5.35946Z"
      fill={fill}
      fillOpacity="1"
    />
  </g>
</svg>);

export default LegacyIcon;
