import React from "react";

const ErrorIcon = () => (<svg
  width="84"
  height="85"
  viewBox="0 0 84 85"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <mask
    id="mask0_1138_2616"
    style={{ maskType: "alpha" }}
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="84"
    height="85"
  >
    <rect y="0.5" width="84.0001" height="84.0001" fill="#C4C4C4" />
  </mask>
  <g mask="url(#mask0_1138_2616)">
    <path
      d="M29.5071 14.6539C29.5071 14.6539 13.4505 23.7565 13.4502 36.2789C13.4499 48.8013 13.5412 53.4416 21.001 60.3982C28.4609 67.3319 46.4054 68.4761 53.9563 64.8376C61.5071 61.1991 73.6294 50.4896 68.4893 33.4413C62.053 11.9765 39.1503 9.2534 29.5071 14.6539Z"
      fill="#EF4068"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.4682 31.0763C57.4747 27.9643 52.3525 26.449 52.3525 26.449C50.3076 26.5479 46.0291 29.8828 41.6084 34.0317C37.819 30.4966 34.2853 27.6033 32.5819 26.711C30.9336 25.8476 28.6846 26.4867 28.1855 30.4795C29.0731 32.1279 32.3958 35.5969 36.258 39.3631C32.3461 43.4826 29.0565 47.4482 28.0943 49.2852C27.2309 50.9336 27.87 53.1826 31.8628 53.6817C33.6545 52.7169 37.5973 48.8751 41.7347 44.5906C43.2969 46.058 44.8075 47.4628 46.15 48.7114C48.2672 50.6803 49.9665 52.2605 50.7908 53.0849C53.9028 56.0914 55.4182 50.9692 55.4182 50.9692C55.3116 48.7654 51.4466 43.9674 46.8548 39.193C48.0025 37.9669 49.0984 36.7885 50.0947 35.7171C52.0636 33.6 53.6438 31.9007 54.4682 31.0763Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.6261 69.8246C76.6261 69.8246 73.5441 76.1915 72.7036 74.787C69.8083 69.731 68.314 66.3602 66.7263 66.4539C65.1386 66.5475 65.6989 75.9106 64.765 76.0979C63.9244 76.2851 60.4688 69.2628 59.2547 66.173C58.0405 63.0832 52.9972 53.7201 52.1567 52.5029C51.3161 51.192 49.7284 48.8513 54.7717 50.8175C59.8151 52.7838 77.3733 58.8698 77.2799 59.9933C77.2799 61.1169 69.9951 62.5214 69.4347 63.1768C68.9678 63.9258 76.5327 68.1392 76.6261 69.8246Z"
      fill="#616161"
    />
  </g>
</svg>);

export default ErrorIcon;
