import React from "react";

const SpotIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#EEEEFF" stroke="#DEDFFF"/>
    <path d="M32 34L27.65 29.65M22 19C24.7614 19 27 21.2386 27 24M30 24C30 28.4183 26.4183 32 22 32C17.5817 32 14 28.4183 14 24C14 19.5817 17.5817 16 22 16C26.4183 16 30 19.5817 30 24Z" stroke="#7583F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M35 14L35.6036 15.5693C35.6882 15.7893 35.7305 15.8993 35.7963 15.9918C35.8546 16.0738 35.9262 16.1454 36.0082 16.2037C36.1007 16.2695 36.2107 16.3118 36.4307 16.3964L38 17L36.4307 17.6036C36.2107 17.6882 36.1007 17.7305 36.0082 17.7963C35.9262 17.8546 35.8546 17.9262 35.7963 18.0082C35.7305 18.1007 35.6882 18.2107 35.6036 18.4307L35 20L34.3964 18.4307C34.3118 18.2107 34.2695 18.1007 34.2037 18.0082C34.1454 17.9262 34.0738 17.8546 33.9918 17.7963C33.8993 17.7305 33.7893 17.6882 33.5693 17.6036L32 17L33.5693 16.3964C33.7893 16.3118 33.8993 16.2695 33.9918 16.2037C34.0738 16.1454 34.1454 16.0738 34.2037 15.9918C34.2695 15.8993 34.3118 15.7893 34.3964 15.5693L35 14Z" stroke="#7583F7" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>;

export default SpotIcon;
