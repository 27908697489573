import React, {useEffect, useState} from "react";
import {HyroHeader, HyroLoader, HyroTable} from "../../components/hyro-components";
import HyroFeedbackMessage from "../../components/hyro-components/HyroFeedbackMessage";
import AddIcon from "@mui/icons-material/Add";
import {
  BRANCH_DEPLOYER_INITIAL_DATA,
  COLUMNS, COPY_SNIPPET_SUCCESS_MESSAGE,
  DIALOG_TYPES, KEYS_TO_FILTER,BD_STATUS_TO_CHIP
} from "../../components/branch-deployer/BranchDeployerConsts";
import ErrorDialog from "../../components/branch-deployer/ErrorDialog";
import {CircularProgress, IconButton, Link} from "@mui/material";
import BranchDeployerDialog from "../../components/branch-deployer/BranchDeployerDialog";
import useAppStore from "../../stores/appStore";
import {filterObjectByKeys} from "../../utils";
import {makeBranchDeployerSnippet} from "../../snippetGenerator";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CodeIcon from 'src/icons/CodeIcon';
import ExternalIcon from "src/icons/ExternalIcon";
import {Chip} from "@hyro/ui";
import {
  branchDeployerErrorSelector, branchDeployerRowsSelector, createBranchDeployerSelector,
  deleteBranchDeployerSelector,
  editBranchDeployerSelector, getBranchDeployerRowsSelector,
  getBranchDeployerStatusSelector,
  setBranchDeployerErrorSelector
} from "src/stores/selectors/branchDeployerSelector";

const initialDialogState = {
  open: false,
  type: ""
};
const BranchDeployer = () => {
  const branchDeployerRows = useAppStore(branchDeployerRowsSelector);
  const getBranchDeployerRows = useAppStore(getBranchDeployerRowsSelector);
  const createBranchDeployer = useAppStore(createBranchDeployerSelector);
  const editBranchDeployer = useAppStore(editBranchDeployerSelector);
  const deleteBranchDeployer = useAppStore(deleteBranchDeployerSelector);
  const getBranchDeployerStatus = useAppStore(getBranchDeployerStatusSelector);
  const error = useAppStore(branchDeployerErrorSelector);
  const setError = useAppStore(setBranchDeployerErrorSelector);
  const [searchText, setSearchText] = useState("");
  const [branchDeployerDialog, setBranchDeployerDialog] = useState({});
  const handleBranchDeployerDialogClose = () => {
    setBranchDeployerDialog(initialDialogState);
  };
  const [filteredBranchDeployer, setFilteredBranchDeployer] = useState([]);
  const [feedbackConfig, setFeedbackConfig] = useState({
    show: false,
    message: "",
    severity: "success"
  });

  useEffect(() => {
    if (!branchDeployerRows) return;
    setFilteredBranchDeployer(
      branchDeployerRows.filter(bdObject =>
        Object.values(bdObject).some(value =>
          value && value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      ).map(row => ({ ...row, id: row._id }))
    );
  }, [searchText, branchDeployerRows]);

  useEffect(() => {
    getBranchDeployerRows();
  }, [getBranchDeployerRows]);


  const onSubmit = async (data, type) => {
    if (type === DIALOG_TYPES.create.id) {
      const error = await createBranchDeployer(data);
      if (error) {
        return;
      }
    } else if (type === DIALOG_TYPES.edit.id) {
      const filteredData = filterObjectByKeys(data, KEYS_TO_FILTER);
      await editBranchDeployer(filteredData);
    } else if (type === DIALOG_TYPES.reactivate.id) {
      const filteredData = filterObjectByKeys(data, KEYS_TO_FILTER);
      await editBranchDeployer(filteredData);
    } else if (type === DIALOG_TYPES.deactivate.id) {
      await deleteBranchDeployer(data.release_name);
    }
    setBranchDeployerDialog({...branchDeployerDialog, open: false});
  };
  if (!branchDeployerRows) return <HyroLoader/>;

  const openSnackbar = () => {
    setFeedbackConfig({
      show: true,
      message: COPY_SNIPPET_SUCCESS_MESSAGE,
      severity: "success"
    });
  };
  return (
    <>
      <HyroHeader
        title={""}
        testId="branch-deployer"
        handleSearch={setSearchText}
        buttonSpec={{
          icon: <AddIcon/>,
          text: "Add new",
          run: () => {
            setBranchDeployerDialog({
              open: true,
              type: DIALOG_TYPES.create.id,
              data: BRANCH_DEPLOYER_INITIAL_DATA
            });
          }
        }}
      />
      <HyroTable
        showPagination={true}
        rows={filteredBranchDeployer}
        alwaysShowActionsColumn={true}
        columns={[
          {field: COLUMNS.release_name.id, headerName: COLUMNS.release_name.label},
          {field: COLUMNS.services.id, headerName: COLUMNS.services.label, width: 250, valueGetter: (services) => services?.join(", ")},
          {
            field: COLUMNS.link.id, headerName: COLUMNS.link.label, width: 125, renderCell: ({ value: link }) => <Link href={link} target="_blank">
              <IconButton>
                <ExternalIcon />
              </IconButton>
            </Link>
          },
          {
            field: COLUMNS.chat.id, headerName: COLUMNS.chat.label, width: 125, renderCell: (row) =>
            {
              const { url, assistantId } = row.value;
              return url ? <CopyToClipboard text={makeBranchDeployerSnippet(assistantId, url)}>
                <IconButton onClick={openSnackbar}>
                  <CodeIcon />
                </IconButton>
              </CopyToClipboard> : null;
            }
          },
          {field: COLUMNS.userEmail.id, headerName: COLUMNS.userEmail.label},
          {field: COLUMNS.editorEmail.id, headerName: COLUMNS.editorEmail.label},
          {
            field: COLUMNS.expired.id,
            headerName: COLUMNS.expired.label,
            renderCell: ({ value: expired }) => expired > 0 ? expired + ' hours' : "Expired"
          },
          {
            field: COLUMNS.status.id, headerName: COLUMNS.status.label, renderCell: ({ row }) =>
            {
              const { status } = row;
              return status === DIALOG_TYPES.refresh.id ? <CircularProgress  size={30} /> :
                <Chip
                  sx={{ fontSize: '12px' }}
                  size="small"
                  backgroundColorPath={BD_STATUS_TO_CHIP[status].backgroundColorPath}
                  textColorPath={BD_STATUS_TO_CHIP[status].textColorPath}
                  label={status}/>;
            }
          }
        ]}
        rowActions={
          [
            {
              id: DIALOG_TYPES.edit.id,
              display: DIALOG_TYPES.edit.display,
              run: (row) => {
                setBranchDeployerDialog({
                  ...branchDeployerDialog,
                  open: true,
                  type: DIALOG_TYPES.edit.id,
                  data: row
                });
              }
            },
            {
              id: DIALOG_TYPES.refresh.id,
              display: DIALOG_TYPES.refresh.display,
              run: (row) => {
                getBranchDeployerStatus(row.release_name);
              }
            },
            {
              id: DIALOG_TYPES.viewDetails.id,
              display: DIALOG_TYPES.viewDetails.display,
              run: (row) => {
                setBranchDeployerDialog({
                  ...branchDeployerDialog,
                  open: true,
                  type: DIALOG_TYPES.viewDetails.id,
                  data: row
                });
              }
            },
            {
              id: DIALOG_TYPES.reactivate.id,
              display: DIALOG_TYPES.reactivate.display,
              run: (row) => {
                setBranchDeployerDialog({
                  ...branchDeployerDialog,
                  open: true,
                  type: DIALOG_TYPES.reactivate.id,
                  data: row
                });
              }
            },
            {
              id: DIALOG_TYPES.deactivate.id,
              display: DIALOG_TYPES.deactivate.display,
              run: (row) => {
                setBranchDeployerDialog({
                  ...branchDeployerDialog,
                  open: true,
                  type: DIALOG_TYPES.deactivate.id,
                  data: row
                });
              }
            }
          ]
        }
      />
      {
        branchDeployerDialog.open && (
          <BranchDeployerDialog
            {...branchDeployerDialog}
            onClose={handleBranchDeployerDialogClose}
            onSubmit={onSubmit}
          />
        )
      }
      <ErrorDialog
        isOpen={error}
        onSubmit={() => setError(false)}
      />
      <HyroFeedbackMessage
        message={feedbackConfig.message}
        severity={feedbackConfig.severity}
        showFeedback={feedbackConfig.show}
        closeFeedback={() =>
          setFeedbackConfig(state => ({ ...state, show: false }))}
      />
    </>
  )
  ;
}
  ;

export default BranchDeployer;