import ErrorPage from "./ErrorPage";
import NotFoundIcon from "./NotFoundIcon";
import React from "react";

const NotFoundErrorPage = () => {
  const pageLinks = [
    { url: "/", text: "Go to home page" },
    { url: "mailto:support@hyro.ai", text: "Contact support" }
  ];

  return (
    <ErrorPage
      code="404"
      message="Page not found"
      explanation="We can't find the page you're looking for."
      links={pageLinks}
      Icon={NotFoundIcon}
    />
  );
};

export default NotFoundErrorPage;
