import React from "react";
import { Box, Chip, Skeleton, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../hooks";
import { Header, StyledDivider } from "./HeaderStyles";

const HeaderSkeleton = () => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  return (
    <Header>
      <Stack direction={'row'} alignItems={'center'}>
        <Skeleton
          variant="rounded"
          sx={{ height: 24, width: 61 }}
        />
        {pathname.includes("admin")
          ? (
            <Skeleton
              variant="rounded"
              sx={{ ml: "10px" }}
            >
              <Chip label="Admin" sx={{ height: "24px", width: 55 }}></Chip>
            </Skeleton>
          )
          : null}
        <StyledDivider orientation="vertical" flexItem />
        <Skeleton
          variant="rounded"
          sx={{ ml: 2, height: 24, width: isMobile ? 24 : 210 }}
        />
        <Stack direction='row' sx={{ ml: 'auto' }} gap={1}>
          <Box sx={{ height: isMobile ? 24 : 36, width: isMobile ? 24 : 36 }}>
            <Skeleton
              variant="circular"
              sx={{ height: isMobile ? 24 : 36, width: isMobile ? 24 : 36 }}
            />
          </Box>
          <Box sx={{ height: isMobile ? 24 : 36, width: isMobile ? 24 : 36 }}>
            <Skeleton
              variant="circular"
              sx={{ height: isMobile ? 24 : 36, width: isMobile ? 24 : 36 }}
            />
          </Box>
          <Box sx={{ height: isMobile ? 24 : 36, width: isMobile ? 24 : 36 }}>
            <Skeleton
              variant="circular"
              sx={{ height: isMobile ? 24 : 36, width: isMobile ? 24 : 36 }}
            />
          </Box>
        </Stack>
      </Stack>
    </Header>
  );
};

export default HeaderSkeleton;
