import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import {styled} from "@mui/system";

const StyledSvg = styled(SvgIcon, {
  shouldForwardProp: prop => prop !== "shouldFlip"
})`
  transform: ${props => props.shouldFlip ? 'rotate(180deg)' : 'none'};
`;

const ArrowDownIcon = ({color = '#4548C4', sx, shouldFlip}) => <StyledSvg sx={sx} shouldFlip={shouldFlip} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M9.99988 12.9167C9.78655 12.9167 9.57322 12.835 9.41072 12.6725L6.07738 9.33921C5.75155 9.01338 5.75155 8.48671 6.07738 8.16088C6.40322 7.83505 6.92988 7.83505 7.25572 8.16088L10.0099 10.915L12.754 8.26505C13.0865 7.94588 13.6124 7.95505 13.9324 8.28588C14.2524 8.61671 14.2432 9.14505 13.9124 9.46421L10.579 12.6825C10.4165 12.8392 10.2082 12.9167 9.99988 12.9167Z" fill={color}/>
</StyledSvg>;

export default ArrowDownIcon;
