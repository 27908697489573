import React from "react";
import styled from "@emotion/styled";
import { STATUS_TO_CHIP_COLOR, STATUS_VARIANTS, STATUS_COLORS } from "./consts";

const theme = (variant, status) => {
  switch (variant) {
  case STATUS_VARIANTS.TEXT:
    return {
      backgroundColor: 'transparent',
      color: STATUS_COLORS[status],
      padding: '0'
    };
  case STATUS_VARIANTS.FILLED:
    return {
      backgroundColor: STATUS_TO_CHIP_COLOR.BACKGROUND[status],
      color: STATUS_TO_CHIP_COLOR.TEXT[status],
      padding: '4px 12px'
    };
  }
};

const StyledStatus = styled.span`
  background-color: ${({ status, variant }) => theme(variant, status).backgroundColor};
  color: ${({ status, variant }) => theme(variant, status).color};
  padding: ${({ variant }) => theme(variant).padding};
  display: inline-block;
  width: fit-content;
  font-size: 12px;
  border-radius: 8px;
  `;

const PublishStatus = ({ status, variant = STATUS_VARIANTS.TEXT, testId, text }) => (
  status ? <StyledStatus status={status} variant={variant} data-testid={testId}>{text || status}</StyledStatus> : null
);

export default PublishStatus;
