import { Box } from "@mui/material";
import React from "react";

const ContainerWithEllipses = ({ shouldHighlight, children }) => (<Box
  sx={{
    display: "block",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...(shouldHighlight && { color: "#5C65E6" })
  }}
>
  {children}
</Box>);

export default ContainerWithEllipses;
