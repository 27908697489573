import React, { useMemo, useState } from "react";
import {
  assistantIdSelector,
  isLoggedInToLookerSelector,
  setIsLoggedInToLookerSelector
} from "../stores/selectors/appSelectors";
import { Box } from "@mui/material";
import { HyroLoader } from "../components/hyro-components";
// eslint-disable-next-line no-duplicate-imports
import { HyroTabs } from "../components/hyro-components";
import { INSIGHTS_LOADER_TEXT } from "../components/widgets/insights/InsightsConfig.ts";
import InsightsBase from "../components/widgets/insights/InsightsBase";
import InsightsTab from "../components/widgets/insights/InsightsTab";
import NothingHereYet from "./NothingHereYet";
import useAppStore from "../stores/appStore";
import { useEffectAsync } from "../hooks";
import {get} from "../services/api";
import { useSearchParams } from "react-router-dom";

const OVERVIEW = "Overview";

const getTabsSpec = dashboards =>
  dashboards?.sort((a, b) => {
    if (a.dashboardName === OVERVIEW && b.dashboardName !== OVERVIEW) {
      return -1;
    } else if (a.dashboardName !== OVERVIEW && b.dashboardName ===OVERVIEW) {
      return 1;
    } else {
      return 0;
    }
  })?.map(dashboard => ({
    label: dashboard.dashboardName,
    value: dashboard.dashboardName,
    content: <InsightsTab {...dashboard} />
  }));

const AssistantInsights = () => {
  const [searchParams] = useSearchParams();
  const selectedAssistantId = useAppStore(assistantIdSelector);
  const isLoggedInToLooker = useAppStore(isLoggedInToLookerSelector);
  const setIsLoggedInToLooker = useAppStore(setIsLoggedInToLookerSelector);

  const [skillsDashboards, setSkillsDashboards] = useState(null);

  useEffectAsync(async () => {
    await setIsLoggedInToLooker(false);
    let dashboards;
    try {
      dashboards = await get(`assistants/${selectedAssistantId}/insights/dashboards`);
      setSkillsDashboards(dashboards);
    }
    catch (err) {
      if (err.response.status === 404) {
        setSkillsDashboards([]);
      }
    }

    if (dashboards?.length > 0) {
      // find the min session length, after it expires we'll refresh it
      const sessionLength = Math.min(
        ...dashboards.map(
          dashboard => dashboard.embeddedDashboardData.sessionLength
        )
      );

      const interval = setInterval(async () => {
        const dashboards =  await get(`assistants/${selectedAssistantId}/insights/dashboards`);
        setSkillsDashboards(dashboards);
        setIsLoggedInToLooker(false);
      }, sessionLength * 1000);

      return () => clearInterval(interval);
    }
  }, [selectedAssistantId]);

  const renderInsights = useMemo(() => {
    if (skillsDashboards?.length === 1) {
      return (
        <Box mt={3}>
          <InsightsBase
            key={selectedAssistantId}
            embedUrl={
              isLoggedInToLooker
                ? skillsDashboards[0].nonLoginEmbedUrl
                : skillsDashboards[0].embeddedDashboardData.embedUrl
            }
          />
        </Box>
      );
    }
    else {
      return <HyroTabs tabsSpec={getTabsSpec(skillsDashboards)} selectedValue={searchParams.get('dashboard')} />;
    }
  }, [searchParams, skillsDashboards, selectedAssistantId, isLoggedInToLooker]);

  if (skillsDashboards === null) {
    return <HyroLoader {...{ text: INSIGHTS_LOADER_TEXT }} />;
  }
  return skillsDashboards?.length > 0 ? renderInsights : <NothingHereYet />;

};

export default AssistantInsights;
