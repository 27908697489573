import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CloseIcon = ({ color = '#000', opacity = 1 }) => (<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17 7L7 17M7 7L17 17" stroke={color} strokeOpacity={opacity} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</SvgIcon>);

export default CloseIcon;



