import "./styles/App.css";
import "./fonts/fonts.css";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { FronteggProvider } from '@frontegg/react';
import { HYRO_THEME } from "@hyro/ui";
import React from "react";
import initDatadog from "./datadog";
import { useLocation } from "react-router-dom";
import HeaderSkeleton from "./layout/Header/HeaderSkeleton";
import { ClientSocketProvider } from "./contexts/ClientSocketContext";
import { DateTimeProvider } from "./contexts/DateTimeContext";
import ErrorBoundary from "./layout/ErrorBoundries";
import InitializePermissions from "./InitializePermissions";
import { showHeaderSkeletonSelector } from "./stores/selectors/appSelectors";
import useAppStore from "./stores/appStore";
import { APP_HEADER_HEIGHT, APP_MOBILE_HEADER_HEIGHT } from "./layout/consts";
import { ENVIRONMENTS, FRONTEGG_DASHBOARD_BASE_URL, FRONTEGG_DASHBOARD_CLIENT_ID } from "./utils";

let theme = createTheme();
const MUI_OVERRIDES = {
  mixins: {
    toolbar: {
      [theme.breakpoints.down("sm")]: {
        minHeight: APP_MOBILE_HEADER_HEIGHT
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: APP_HEADER_HEIGHT
      },
      backgroundColor: "#ffffff"
    }
  }
};

theme = createTheme({ ...HYRO_THEME, ...MUI_OVERRIDES });

if (import.meta.env.VITE_ENV === ENVIRONMENTS.PRODUCTION) {
  initDatadog();
}

const App = () => {
  const location = useLocation();
  const showHeaderSkeleton = useAppStore(showHeaderSkeletonSelector);
  const contextOptions = {
    baseUrl: FRONTEGG_DASHBOARD_BASE_URL,
    clientId: FRONTEGG_DASHBOARD_CLIENT_ID
  };

  const authOptions = {
    keepSessionAlive: true
  };

  return (
    <FronteggProvider
      contextOptions={contextOptions}
      hostedLoginBox={true}
      authOptions={authOptions}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {showHeaderSkeleton ? <HeaderSkeleton /> : null}
          <ClientSocketProvider>
            <DateTimeProvider>
              <ErrorBoundary key={location.pathname}>
                <InitializePermissions />
              </ErrorBoundary>
            </DateTimeProvider>
          </ClientSocketProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </FronteggProvider>
  );
};

export default App;
