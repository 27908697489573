import React from "react";

const SiteInformationIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#EEEEFF" stroke="#DEDFFF"/>
    <g>
      <path d="M16.3 26.515C14.2635 27.4134 13 28.6653 13 30.05C13 32.7838 17.9249 35 24 35C30.0751 35 35 32.7838 35 30.05C35 28.6653 33.7365 27.4134 31.7 26.515M30.6 19.6C30.6 24.0701 26.75 26.2 24 29.5C21.25 26.2 17.4 24.0701 17.4 19.6C17.4 15.9549 20.3549 13 24 13C27.6451 13 30.6 15.9549 30.6 19.6ZM25.1 19.6C25.1 20.2075 24.6075 20.7 24 20.7C23.3925 20.7 22.9 20.2075 22.9 19.6C22.9 18.9925 23.3925 18.5 24 18.5C24.6075 18.5 25.1 18.9925 25.1 19.6Z" stroke="#7583F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </g>
</svg>;

export default SiteInformationIcon;
