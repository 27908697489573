/* eslint-disable import/namespace */
/* eslint-disable import/default */
import { devtools, subscribeWithSelector } from "zustand/middleware";
import create from "zustand";
import createAccountManagementSlice from "./slices/createAccountManagementSlice";
import createAppSlice from "./slices/createAppSlice";
import createConversationsSlice from "./slices/createConversationsSlice";
import createKnowledgeEditorSlice from "./KnowledgeEditor/createKnowledgeEditorSlice";
import createPreviewSlice from "./slices/createPreviewSlice";
import createTripletsSlice from "./slices/createTripletsSlice";
import createCollectionsSlice from "./slices/createCollectionsSlice";
import createBranchDeployerSlice from "./slices/createBranchDeployerSlice";

const devtoolsEnabled
  = import.meta.env.VITE_ENV && import.meta.env.VITE_ENV !== "production";
const useAppStore = create(
  devtools(
    subscribeWithSelector((...args) => ({
      ...createAppSlice(...args),
      ...createPreviewSlice(...args),
      ...createTripletsSlice(...args),
      ...createConversationsSlice(...args),
      ...createKnowledgeEditorSlice(...args),
      ...createCollectionsSlice(...args),
      ...createAccountManagementSlice(...args),
      ...createBranchDeployerSlice(...args)
    })),
    { name: "appStore", enabled: devtoolsEnabled }
  )
);
export default useAppStore;
