import {
  PUBLISH_STATUS,
  PUBLISH_STATUSES
} from "../../pages/KnowledgeEditor/Table/utils";
import {
  Relations,
  object,
  objectEq,
  relationEq,
  subject,
  subjectEq,
  subjectIn,
  tripletsToObjects
} from "../../triplets";
import {
  always,
  both,
  equals,
  filter,
  find,
  ifElse,
  isNil,
  juxt,
  map,
  mergeAll,
  not,
  of,
  pipe,
  prepend,
  prop,
  reduce,
  reject,
  split,
  toPairs,
  unnest
} from "ramda";
import { default as gamla } from "gamlajs";
import { v4 as uuidv4 } from "uuid";

const FIELD_TYPES = {
  TEXT_FIELD: "textfield",
  RADIO: "radio",
  MULTIPLE_VALUES: "multipleValues",
  IMAGE: "image",
  REF: "ref",
  PHONE_NUMBER: "phoneNumber",
  SELECT_ONE: "selectOne"
};

const getFieldsSpecByEntity = entity => triplets =>
  pipe(
    filter(subjectIn(getFieldIdsByEntity(entity)(triplets))),
    tripletsToObjects
  )(triplets);

const getAllEntities = entityType =>
  pipe(
    filter(both(relationEq(Relations.RELATION_TYPE), objectEq(entityType))),
    map(subject)
  );

const getFieldIdsByEntity = entityType =>
  pipe(
    find(both(subjectEq(entityType), relationEq(Relations.ENTITY_FIELDS))),
    object,
    ifElse(isNil, always([]), split(","))
  );

const tripletsToRowsByEntity = entityType => triplets =>
  pipe(
    filter(subjectIn(getAllEntities(entityType)(triplets))),
    reject(relationEq(Relations.RELATION_TYPE)),
    tripletsToObjects
  )(triplets);

const getEntityTripletsFromRows = (entity, columnIdToRelationMap, rows) => {
  const renamedRows = rows.map(gamla.renameKeys(columnIdToRelationMap));
  const tripletsByRow = renamedRows.map(row =>
    pipe(
      toPairs,
      map(([relation, object_s]) =>
        ifElse(
          Array.isArray,
          map(object => [row.id, relation, object]),
          pipe(object => [row.id, relation, object], of)
        )(object_s)
      ),
      unnest,
      reject(objectEq(row.id)),
      prepend([row.id, Relations.RELATION_TYPE, entity])
    )(row)
  );
  return unnest(tripletsByRow);
};

const getColumnSpec = map(field => ({
  id: field.id,
  label: field[Relations.FIELD_LABEL],
  columnType: field[Relations.FIELD_TYPE],
  required: Boolean(field[Relations.FIELD_REQUIRED]),
  ...([
    FIELD_TYPES.RADIO,
    FIELD_TYPES.SELECT_ONE,
    FIELD_TYPES.MULTIPLE_VALUES
  ].includes(field[Relations.FIELD_TYPE]) && {
    options: field[Relations.FIELD_OPTION]
  }),
  ...(field[Relations.FIELD_TYPE] === FIELD_TYPES.REF && {
    refEntity: field[Relations.FIELD_ENTITY]
  })
}));

const PUBLISH_STATUS_COLUMN = {
  id: PUBLISH_STATUS,
  label: "Status",
  columnType: PUBLISH_STATUS
};

const getRelationToColumnIdMap = reduce(
  (acc, curr) => ({ ...acc, [curr[Relations.FIELD_RELATION]]: curr.id }),
  {}
);

const getColumnIdToRelationMap = reduce(
  (acc, curr) => ({ ...acc, [curr.id]: curr[Relations.FIELD_RELATION] }),
  {}
);

const formatMultipleValuesColumns = (rowsBeforeCheck, columnSpec) => {
  const multipleValuesColumns = columnSpec.reduce(
    (acc, column) =>
      column.columnType === FIELD_TYPES.MULTIPLE_VALUES
        ? [...acc, column.id]
        : acc,
    []
  );
  const rowsAfterCheck = rowsBeforeCheck.map((row) => {
    let rowNew = row;
    for (const colId of multipleValuesColumns) {
      rowNew = {
        ...rowNew,
        ...(rowNew[colId] && {
          [colId]: Array.isArray(rowNew[colId])
            ? rowNew[colId]
            : rowNew[colId].includes(",")
              ? rowNew[colId].split(",")
              : [rowNew[colId]]
        })
      };
    }
    return rowNew;
  });
  return rowsAfterCheck;
};

const getRowPublishStatus = (row, renamedOriginalRows) => {
  for (const originalRow of renamedOriginalRows) {
    if (equals(originalRow, row)) return PUBLISH_STATUSES.LIVE;
    if (originalRow.id === row.id) return PUBLISH_STATUSES.EDITED;
  }
  return PUBLISH_STATUSES.NEW;
};

const getRowsWithPublishStatus = (currentRows, originalRows, draft) =>
  currentRows.map(row => ({
    ...row,
    publishStatus: draft
      ? getRowPublishStatus(row, originalRows)
      : PUBLISH_STATUSES.LIVE
  }));

const publishStatusComparator = (a, b) => {
  const ordering = {
    [PUBLISH_STATUSES.NEW]: 1,
    [PUBLISH_STATUSES.EDITED]: 2,
    [PUBLISH_STATUSES.LIVE]: 3
  };
  return (
    (ordering[a[PUBLISH_STATUS]] || 4) - (ordering[b[PUBLISH_STATUS]] || 4)
  );
};

const addIdsToRows = entity =>
  map(row => ({ ...row, id: `${uuidv4()}_${entity}` }));

const getEntityRowsFromTriplets = (
  triplets,
  entity,
  columnSpec,
  relationToColumnIdMap
) => {
  const relationRowsWithoutMultipleValuesCheck
    = tripletsToRowsByEntity(entity)(triplets);
  const rowsWithoutMultipleValuesCheck
    = relationRowsWithoutMultipleValuesCheck.map(
      gamla.renameKeys(relationToColumnIdMap)
    );
  const rows = formatMultipleValuesColumns(
    rowsWithoutMultipleValuesCheck,
    columnSpec
  );
  return rows;
};

const calcRefEntityOptions = (entity, currentTriplets, byDisplay) =>
  pipe(
    tripletsToRowsByEntity(entity),
    map(
      pipe(
        juxt([
          prop("id"),
          ifElse(
            pipe(prop(Relations.CONCEPT_DISPLAY), isNil, not),
            prop(Relations.CONCEPT_DISPLAY),
            ifElse(
              // Specific usecase for Properties (ENG-5900)
              pipe(prop(Relations.RELATION_PROPERTY_NAME), isNil, not),
              prop(Relations.RELATION_PROPERTY_NAME),
              prop("id")
            )
          )
        ]),
        ([value, display]) => ({
          ...(byDisplay ? { [display]: value } : { [value]: display })
        })
      )
    ),
    mergeAll
  )(currentTriplets);

export {
  FIELD_TYPES,
  PUBLISH_STATUS_COLUMN,
  calcRefEntityOptions,
  getEntityTripletsFromRows,
  getAllEntities,
  getFieldIdsByEntity,
  addIdsToRows,
  publishStatusComparator,
  getRowsWithPublishStatus,
  formatMultipleValuesColumns,
  getRelationToColumnIdMap,
  getColumnIdToRelationMap,
  getColumnSpec,
  tripletsToRowsByEntity,
  getFieldsSpecByEntity,
  getEntityRowsFromTriplets
};
