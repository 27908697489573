export const previewAssistantIdSelector = state => state.previewAssistantId;
export const branchDeployerBuildFailedSelector = state => state.branchDeployerBuildFailed;
export const buildStatusPollingInProgressSelector = state =>
  state.buildStatusPollingInProgress;
export const createPreviewAssistantIdSelector = state =>
  state.createAssistantForPreview;
export const initiateCallToAssistantSelector = state =>
  state.initiateCallToAssistant;
export const shouldRecreatePreviewSelector = state => state.shouldRecreatePreview;
export const setShouldRecreatePreviewSelector = state => state.setShouldRecreatePreview;
export const disablePublishSelector = state => state.disablePublish;
export const updateDisablePublishSelector = state =>
  state.updateDisablePublish;
