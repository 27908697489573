import { Box } from "@mui/material";
import React from "react";
import starsLoader from "../../static/lottie/stars_loader.json";
import { useLottie } from "lottie-react";

const StarsLottie = () => {
  const { View } = useLottie(
    { animationData: starsLoader, loop: true, autoplay: true },
    { height: 80 }
  );
  return View;
};

const HyroLoader = ({ text = "Just a moment...", height }) => (<Box
  display="flex"
  flexDirection="column"
  height={height || "calc(100vh - 124px)"}
  alignItems="center"
  justifyContent="center"
  bgcolor="white"
>
  <Box mb="18px">
    <StarsLottie animationData={starsLoader} />
  </Box>
  <Box fontWeight={400} fontSize="14px" color="#00000099">
    {text}
  </Box>
</Box>);

export default HyroLoader;
