import { IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ThreeDotMenu = ({ menuItems, testId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.target);
  };
  const handleMenuClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        data-testid={`${testId}-three-dot-menu`}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="small"
        sx={{ 
          ':hover': { backgroundColor: '#4548C414' },
          ...(Boolean(anchorEl) && { backgroundColor: '#EBEBFD' })
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default ThreeDotMenu;
