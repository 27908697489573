import { ADMIN, ALL_ASSISTANTS } from "../../utils";
import {
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
  ListItemIcon
} from "@mui/material";
import React, { useState } from "react";
import Avatar from "../../components/common/Avatar";
import { isAdminSelector, userDataSelector } from "../../stores/selectors/appSelectors";
import CrownIcon from "../../icons/CrownIcon";
import ExternalLink from "../../icons/ExternalLink";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import useAppStore from "../../stores/appStore";
import { useIsAdminPanel, useIsMobile } from "../../hooks";
import { logout } from "../../auth/frontegg";

const UserIconButton = styled(IconButton)(() => ({
  "padding": 0,
  "&:active, &:hover": {
    outline: '2px solid #E0E0FF'
  }
}));

const StyledUserMenu = styled(props => (
  <Menu
    id="menu-appbar"
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "marginTop": theme.spacing(1.5),

  "& .MuiList-root": {
    padding: theme.spacing()
  },
  "& .MuiButtonBase-root": {
    "padding": `${theme.spacing()}`,
    "gap": theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      borderRadius: theme.spacing()
    }
  }
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  "justifyContent": "center"
}));

const UserMenu = () => {
  const isMobile = useIsMobile();
  const userData = useAppStore(userDataSelector);
  const userName = userData?.name || userData?.email;
  const isAdmin = useAppStore(isAdminSelector);
  const isAdminPanel = useIsAdminPanel();
  const [clientMenuAnchorEl, setClientMenuAnchorEl] = useState(null);
  const toggleMenu = ({ currentTarget }) =>
    clientMenuAnchorEl
      ? setClientMenuAnchorEl(null)
      : setClientMenuAnchorEl(currentTarget);

  const handleMenuClose = () => {
    setClientMenuAnchorEl(null);
  };

  return (
    <>
      <UserIconButton
        sx={{ ml: .5 }}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="false"
        color="inherit"
        onClick={toggleMenu}
        size="large"
        data-testid="user-menu-avatar-button"
      >
        <Avatar sx={{ fontWeight: 500 }} fontSize={11} size={isMobile ? 24 : 32} name={userName} />
      </UserIconButton>
      <StyledUserMenu
        anchorEl={clientMenuAnchorEl}
        elevation={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(clientMenuAnchorEl)}
        onClose={toggleMenu}
      >
        <MenuItem>
          <StyledListItemIcon>
            <Avatar sx={{ fontWeight: 500 }} fontSize={11} size={isMobile ? 24 : 32} name={userName} />
          </StyledListItemIcon>
          <Typography variant="subtitle2">{userName}</Typography>
        </MenuItem>
        {isAdmin && !isAdminPanel && (
          <MenuItem
            sx={{ marginTop: 1 }}
            onClick={() => {
              window.open(
                `${window.location.protocol}//${window.location.host}/${ADMIN}/${ALL_ASSISTANTS}`
              );
              handleMenuClose();
            }}
          >
            <StyledListItemIcon>
              <CrownIcon />
            </StyledListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2" color="text.secondary">
                Admin Panel
              </Typography>
            </ListItemText>
            <ListItemIcon sx={{ justifyContent: "end" }}>
              <ExternalLink fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        )}

        <Divider />
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            localStorage.clear();
            logout({ returnTo: window.location.origin });
          }}
        >
          <StyledListItemIcon>
            <LogoutIcon />
          </StyledListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2" color="text.secondary">
              Log out
            </Typography>
          </ListItemText>
        </MenuItem>
      </StyledUserMenu>
    </>
  );
};

export default UserMenu;
