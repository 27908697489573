import React from "react";
import { Avatar as MuiAvatar } from "@mui/material";
import { getStringInitials, stringToColor } from "../../utils";

const Avatar = ({ variant, name, size, fontSize, sx = {}, ...rest }) => (
  <MuiAvatar
    variant={variant}
    sx={{ width: size, height: size, fontSize, bgcolor: stringToColor(name), ...sx }}
    {...rest}
  >
    {getStringInitials(name)}
  </MuiAvatar>
);

export default Avatar;
