import { Box, Chip } from "@mui/material";
import { length, map, slice } from "ramda";
import React from "react";
import { styled } from "@mui/material/styles";

const CHIP_LIMIT = 1;

const ChipCountBox = styled(Box)(() => ({
  border: "1px solid rgba(45, 44, 68, 0.24)",
  borderRadius: "16px",
  height: "32px",
  padding: "0 10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  fontWeight: 500,
  flexShrink: 0
}));

const StyledChip = styled(Chip)(() => ({
  border: "1px solid rgba(45, 44, 68, 0.24)",
  marginRight: "8px",
  fontSize: "12px",
  fontWeight: 500,
  textTransform: 'lowercase'
}));

const ChipContainer = ({ values }) => (<Box display="flex" justifyContent="flex-start">
  {map(chipLabel => (
    <StyledChip label={chipLabel} key={chipLabel} variant="outlined" />
  ))(slice(0, CHIP_LIMIT)(values))}
  {length(values) > CHIP_LIMIT && (
    <ChipCountBox>
              +
      {length(values) - CHIP_LIMIT}
    </ChipCountBox>
  )}
</Box>);

export default ChipContainer;
