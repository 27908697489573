import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const EditorIcon = () => (<SvgIcon>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19 2H17V5H14C14.01 5.01 14 7 14 7H17V9.99C17.01 10 19 9.99 19 9.99V7H22V5H19V2ZM17 19.22H5V7H12V5H5C3.9 5 3 5.9 3 7V19C3 20.1 3.9 21 5 21H17C18.1 21 19 20.1 19 19V12H17V19.22ZM7 9H15V11H7V9ZM7 12V14H15V12H12H7ZM7 15H15V17H7V15Z"
  />
</SvgIcon>);

export default EditorIcon;
