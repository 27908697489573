import React from "react";

const CallToTextIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" fill="#EEEEFF" stroke="#DEDFFF"/>
    <path d="M19.5 22.5H19.51M24 22.5H24.01M28.5 22.5H28.51M19 30V32.3355C19 32.8684 19 33.1348 19.1092 33.2716C19.2042 33.3906 19.3483 33.4599 19.5005 33.4597C19.6756 33.4595 19.8837 33.2931 20.2998 32.9602L22.6852 31.0518C23.1725 30.662 23.4162 30.4671 23.6875 30.3285C23.9282 30.2055 24.1844 30.1156 24.4492 30.0613C24.7477 30 25.0597 30 25.6837 30H28.2C29.8802 30 30.7202 30 31.362 29.673C31.9265 29.3854 32.3854 28.9265 32.673 28.362C33 27.7202 33 26.8802 33 25.2V19.8C33 18.1198 33 17.2798 32.673 16.638C32.3854 16.0735 31.9265 15.6146 31.362 15.327C30.7202 15 29.8802 15 28.2 15H19.8C18.1198 15 17.2798 15 16.638 15.327C16.0735 15.6146 15.6146 16.0735 15.327 16.638C15 17.2798 15 18.1198 15 19.8V26C15 26.93 15 27.395 15.1022 27.7765C15.3796 28.8117 16.1883 29.6204 17.2235 29.8978C17.605 30 18.07 30 19 30ZM20 22.5C20 22.7761 19.7761 23 19.5 23C19.2239 23 19 22.7761 19 22.5C19 22.2239 19.2239 22 19.5 22C19.7761 22 20 22.2239 20 22.5ZM24.5 22.5C24.5 22.7761 24.2761 23 24 23C23.7239 23 23.5 22.7761 23.5 22.5C23.5 22.2239 23.7239 22 24 22C24.2761 22 24.5 22.2239 24.5 22.5ZM29 22.5C29 22.7761 28.7761 23 28.5 23C28.2239 23 28 22.7761 28 22.5C28 22.2239 28.2239 22 28.5 22C28.7761 22 29 22.2239 29 22.5Z" stroke="#7583F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>;

export default CallToTextIcon;

