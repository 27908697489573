export const APP_HEADER_HEIGHT = 64;
export const APP_MOBILE_HEADER_HEIGHT = 44;
export const APP_STATUS_BAR_HEIGHT = 34;

export const EXIT_EDIT_MODE_DIALOG = {
  TEXT: "You are currently in edit mode for this assistant. Leaving now will discard all changes that were not saved",
  SUBMIT_BUTTON_TEXT: "Save changes",
  CANCEL_BUTTON_TEXT: "Discard changes",
  TITLE: "You are about to exit edit mode"
};

export const DISCARD_DRAFT_DIALOG = {
  TEXT: "Once you discard your draft, this action cannot be undone and will delete all the changes you made.",
  SUBMIT_BUTTON_TEXT: "Discard draft",
  CANCEL_BUTTON_TEXT: "Keep editing",
  TITLE: "You are about to discard your draft"
};

export const UNSAVED_CHANGES_DIALOG = {
  TEXT: "You have unsaved changes on this page. Leaving now will discard these changes. Are you sure you want to continue?",
  SUBMIT_BUTTON_TEXT: "Save changes",
  CANCEL_BUTTON_TEXT: "Discard changes",
  TITLE: "You are about to leave this page"
};

export const COLORS = {
  DISABLED_BTN_BG: '#5C66DB',
  DISABLED_BTN_COLOR: '#BCC2FB',
  HEADER_READ_ONLY_BG: '#FFFFFF',
  HEADER_EDIT_BG: '#EBEBFF',
  BTN_BORDER_COLOR: '#7583F7',
  BTN_HOVER_BG: '#4548C414',
  ICON_BTN_HOVER_BG: '#E0E0FF',
  DISABLED_BTN_BORDER_COLOR: '#2D2C443D',
  OUTLINED_HOVER_BTN_BG: 'rgba(69, 72, 196, 0.08)'
};
