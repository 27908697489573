import { useEffect, useState } from "react";

const useSnackbar = () => {
  const [snackPack, setSnackPack] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !snackbarMessage) {
      // Set a new snack when we don't have an active one
      setSnackbarMessage(snackPack[0]);
      setSnackPack(prev => prev.slice(1));
      setIsSnackbarOpen(true);
    }
    else if (snackPack.length && snackbarMessage && isSnackbarOpen) {
      // Close an active snack when a new one is added
      setIsSnackbarOpen(false);
    }
  }, [snackPack, snackbarMessage, isSnackbarOpen]);

  const addSnackbarMessage = (message) => {
    setSnackPack(prev => [...prev, message]);
  };

  const exitSnackbar = () => {
    setSnackbarMessage(undefined);
  };

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  return [
    isSnackbarOpen,
    snackbarMessage,
    addSnackbarMessage,
    exitSnackbar,
    closeSnackbar
  ];
};

export default useSnackbar;
