import {
  CREATE_DIALOG,
  DELETE_DIALOG,
  DIALOG_TYPES,
  EDIT_DIALOG,
  ERROR_DIALOG
} from "./AccountEditorConsts";

const useAccountEditorDialog = ({ type, accountData, error, errorText }) => {
  let dialogContent = {};
  if (error) {
    dialogContent = {
      title: ERROR_DIALOG.title,
      subtitle: errorText,
      actionButtonText: ERROR_DIALOG.buttonText
    };
  }
  else if (type === DIALOG_TYPES.create) {
    dialogContent = {
      title: CREATE_DIALOG.title,
      subtitle: CREATE_DIALOG.subtitle,
      actionButtonText: CREATE_DIALOG.buttonText
    };
  }
  else if (type === DIALOG_TYPES.edit) {
    dialogContent = {
      title: EDIT_DIALOG.title,
      subtitle: EDIT_DIALOG.subtitle.replace(
        "$accountName",
        accountData?.name.value
      ),
      actionButtonText: EDIT_DIALOG.buttonText
    };
  }
  else if (type === DIALOG_TYPES.delete) {
    dialogContent = {
      title: DELETE_DIALOG.title,
      subtitle: DELETE_DIALOG.subtitle.replace(
        "$accountName",
        accountData?.name.value
      ),
      actionButtonText: DELETE_DIALOG.buttonText
    };
  }

  return { dialogContent };
};

export default useAccountEditorDialog;
