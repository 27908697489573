import ErrorPage from "./ErrorPage";
import React from "react";
import GenericErrorIcon from "src/layout/ErrorPages/GenericErrorIcon";

const GenericErrorPage = () => {
  const pageLinks = [
    { url: window.location.pathname, text: "Reload" },
    { url: "mailto:support@hyro.ai", text: "Contact support" }
  ];

  return (
    <ErrorPage
      code="Oops!"
      message="Something went wrong"
      explanation="This page isn't working"
      links={pageLinks}
      Icon={GenericErrorIcon}
    />
  );
};

export default GenericErrorPage;
