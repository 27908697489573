import ContainerWithEllipses from "./ContainerWithEllipses";
import React from "react";
import { refEntityOptionsSelector } from "../../../stores/KnowledgeEditor/knowledgeEditorSelectors";
import useAppStore from "../../../stores/appStore";

const DisplayReference = ({ value, refEntity }) => {
  const refEntityOptions = useAppStore(refEntityOptionsSelector);

  if (!refEntityOptions[refEntity]) return "Loading...";

  return (
    <ContainerWithEllipses>
      {refEntityOptions[refEntity][value]}
    </ContainerWithEllipses>
  );
};

export default DisplayReference;
