import React from 'react';
import { Tooltip, tooltipClasses, Stack } from "@mui/material";

const CustomTooltip = ({ title, placement = "top", children, open }) => (
  <Tooltip
    title={title}
    placement={placement}
    open={open}
    componentsProps={{
      popper: {
        sx: {
          [`&.${tooltipClasses.popper}[data-popper-placement*=${placement}] .${tooltipClasses.tooltip}`]:
            {
              marginBottom: '8px'
            }
        }
      }
    }}>
    <Stack alignItems={'center'} direction={'row'}>
      {children}
    </Stack>
  </Tooltip>
);

export default CustomTooltip;
