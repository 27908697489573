import { compressSync, strToU8 } from "fflate";
import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { pipe } from "ramda";
import useAppStore from "../stores/appStore";
import { LOCKED_STATUS_CODE } from "../components/admin/Settings/consts";

const disableGzip = import.meta.env.VITE_ENV === "local";
axios.interceptors.response.use(
  response => response,
  (error) => {
    datadogRum.addError(error);
    if (error?.response?.status === LOCKED_STATUS_CODE){
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Delay of 1 second
    }
    return Promise.reject(error);
  }
);

const gzipJSON = pipe(JSON.stringify, strToU8, compressSync);

const handleContentType = (requestData) => {
  const { selectedAssistantId, selectedAccountId } = useAppStore.getState();

  let contentType = "application/json";

  if (requestData?.constructor === FormData) {
    contentType = "multipart/form-data";
    requestData.append("roomId", selectedAssistantId);
    requestData.append("accountId", selectedAccountId);
  }
  else {
    if (typeof requestData === "object") {
      requestData.accountId = selectedAccountId;
      requestData.roomId = selectedAssistantId;
    }
    else {
      requestData = {
        accountId: selectedAccountId,
        roomId: selectedAssistantId
      };
    }
    requestData = disableGzip ? requestData : gzipJSON(requestData);
  }
  return { contentType, requestData };
};

const createRequest = (service, subService, method) => async (data, headers) => {
  const { contentType, requestData } = handleContentType(data);
  const response = await axios({
    url: `${import.meta.env.VITE_DASHBOARD_API_URL}/${service}/${subService}`,
    method,
    headers: {
      ...(disableGzip ? {} : { "Content-Encoding": "gzip" }),
      "Content-Type": contentType,
      ...(headers || {})
    },
    data: requestData
  });

  return response.data;
};

const makeService = ({ service, subservices }) => {
  const routes = {};
  Object.keys(subservices).forEach((method) => {
    const subServices = subservices[method];
    subServices.forEach(
      subService =>
        (routes[subService] = createRequest(service, subService, method))
    );
  });
  return routes;
};

export default makeService;
