import React, { useEffect, useState } from "react";
import { assistantIdSelector, assistantsSelector } from "../stores/selectors/appSelectors";
import { pathOr, pipe } from "ramda";
import moment from "moment-timezone";
import { objectByAssistantId } from "../utils";
import useAppStore from "../stores/appStore";

const DateTimeContext = React.createContext(null);

const DEFAULT_DATE_RANGE = moment.duration(1, "w");
const DEFAULT_ET_TIME_ZONE = "America/New_York";

const DateTimeProvider = ({ children }) => {
  const assistants = useAppStore(assistantsSelector);
  const [timeZone, setTimeZone] = useState(DEFAULT_ET_TIME_ZONE);
  const selectedAssistantId = useAppStore(assistantIdSelector);

  const timeZoneMoment = timeString => moment(timeString).tz(timeZone);
  const localMoment = timeString => moment(timeString);

  const formattedMomentTimezone = (timeString) => timeZoneMoment(timeString).format("L LT");

  const formattedMomentTimezoneSeconds = timeString =>
    timeZoneMoment(timeString).format("hh:mm:ss A");

  const formattedMomentTimezoneMilliSeconds = timeString =>
    timeZoneMoment(timeString).format("hh:mm:ss.SS A");

  const [fromDate, setFromDate] = useState(
    timeZoneMoment().subtract(DEFAULT_DATE_RANGE).startOf("d").toDate()
  );
  const [toDate, setToDate] = useState(timeZoneMoment().toDate());

  const ctxValue = {
    timeZone,
    setTimeZone,
    timeZoneMoment,
    localMoment,
    formattedMomentTimezone,
    formattedMomentTimezoneSeconds,
    formattedMomentTimezoneMilliSeconds,
    fromDate,
    toDate,
    setFromDate,
    setToDate
  };

  useEffect(() => {
    if (selectedAssistantId && assistants?.length > 0) {
      setTimeZone(
        pipe(
          objectByAssistantId,
          pathOr(DEFAULT_ET_TIME_ZONE, ["config", "analysis", "timezone"])
        )(selectedAssistantId, assistants)
      );
    }
  }, [assistants, selectedAssistantId, setTimeZone]);

  return (
    <DateTimeContext.Provider value={ctxValue}>
      {children}
    </DateTimeContext.Provider>
  );
};

export default DateTimeContext;

export { DateTimeProvider };
