import { Badge, badgeClasses, Divider, Popover, Stack, Tooltip, Typography } from "@mui/material";
import React, { forwardRef, useState } from "react";
import { assistantIdSelector, notificationsSelector } from "src/stores/selectors/appSelectors";
import Box from "@mui/material/Box";
import { NOTIFICATION_TYPES, NOTIFICATIONS_STATES } from "src/utils";
import NotificationItem from "./NotificationItem";
import LastUpdateMenuItem from "./LastUpdateItem";
import { deletePublishedDraftSelector } from "src/stores/selectors/tripletsSelectors";
import { notifyNotificationsMenu } from "src/analytics/notifyCustomActions";
import useAppStore from "../../../stores/appStore";
import { useEffectAsync } from "src/hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DIALOGS } from "./consts";
import BellIcon from "../../../icons/BellIcon";
import { StyledIconButton } from "../HeaderStyles";
import { DIALOG_VARIANTS } from "src/components/common/Dialogs/DialogConsts";
import { del, get } from "src/services/api";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";

const NotificationsPopover = forwardRef((_, ref) => {
  const deletePublishedDraft = useAppStore(deletePublishedDraftSelector);
  const selectedAssistantId = useAppStore(assistantIdSelector);
  const notifications = useAppStore(notificationsSelector);
  const draft = useAppStore(state => state.draft);
  const setNotifications = useAppStore(state => state.setNotifications);
  const [lastNotification, setLastNotification] = useState({});
  const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] = useState(null);
  const setBuildDraftResponse = useAppStore(state => state.setBuildDraftResponse);
  const [itemToDelete, setItemToDelete] = useState(null);
  const dialogData = itemToDelete?.type === NOTIFICATION_TYPES.BUILD ? DIALOGS.BUILD : DIALOGS.PUBLISH;
  const [submitButtonLoading, setSubmitButtonLoading] = useState(null);

  useEffectAsync(async () => {
    if (selectedAssistantId) {
      const lastNotification = await get(`/assistants/${selectedAssistantId}/notifications/last-commit`);
      setLastNotification(lastNotification);
    }
  }, [selectedAssistantId]);

  const discardChange = async () => {
    setSubmitButtonLoading(true);
    if (itemToDelete?.type === NOTIFICATION_TYPES.BUILD) {
      await del(`/assistants/${selectedAssistantId}/branch-deployer/draft/build`, { branchName: draft?.branchName });
      const notifications = await get(`/assistants/${selectedAssistantId}/notifications`);
      setBuildDraftResponse({ status: '', url: '' });
      setNotifications(notifications);
    } else {
      await deletePublishedDraft(itemToDelete);
    }
    setSubmitButtonLoading(false);
    setItemToDelete(null);
  };

  const getNumberOfNotifications = () =>
    notifications?.filter(notification => Object.values(NOTIFICATIONS_STATES).includes(notification.status))?.length;

  return (
    <>
      <Dialog
        submitButtonLoading={submitButtonLoading}
        handleCancel={() => setItemToDelete(null)}
        handleSubmit={discardChange}
        open={Boolean(itemToDelete)}
        submitButtonText={dialogData.SUBMIT_BUTTON_TEXT}
        cancelButtonText={dialogData.CANCEL_BUTTON_TEXT}
        title={dialogData.TITLE}
        variant={DIALOG_VARIANTS.transactional}
      >
        <Typography>{dialogData.LABEL}</Typography>
      </Dialog>
      <Tooltip title={'Notifications'}>
        <span>
          <StyledIconButton
            ref={ref}
            onClick={({ currentTarget }) =>
              setNotificationsMenuAnchorEl(currentTarget)}
            size="small"
          >
            <Badge
              overlap={'circular'}
              color="primary"
              sx={{ fontSize: 11, [`.${badgeClasses.badge}`]: { minWidth: 18, height: 18 } }}
              badgeContent={getNumberOfNotifications()}
              invisible={!notifications?.length}
            >
              <BellIcon />
            </Badge>
          </StyledIconButton>
        </span>
      </Tooltip>
      <Popover
        open={Boolean(notificationsMenuAnchorEl)}
        anchorEl={notificationsMenuAnchorEl}
        onClose={() => {
          setNotificationsMenuAnchorEl(null);
          notifyNotificationsMenu("openMenu");
        }}
        sx={{ mt: 2 }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box width="300px" maxHeight="60vh">
          <Stack sx={{ p: "6px 16px", minHeight: "48px" }} justifyContent="center">
            <Typography variant="subtitle1">Notifications</Typography>
          </Stack>
          <Divider />
          {notifications?.length > 0
          && getNumberOfNotifications()
            ? (
              <>
                {notifications.map(notification => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    setItemToDelete={setItemToDelete}
                  />
                ))}
              </>
            )
            : (
              <Stack gap={1} sx={{ height: 150 }} alignItems="center" justifyContent="center">
                <CheckCircleIcon sx={{ color: "action.disabled", width: 40, height: 40 }} />
                <Typography variant="body2" color="text.secondary">
                  There are no change
                  requests
                </Typography>
              </Stack>
            )}
          <Divider />
          <LastUpdateMenuItem notification={lastNotification} />
        </Box>
      </Popover>
    </>
  );
});

export default NotificationsPopover;
