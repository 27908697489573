import React from "react";

const GenericErrorIcon = () => (<svg width="210" height="167" viewBox="0 0 210 167" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2102_3571)">
    <rect width="210" height="167" fill="white"/>
    <path d="M52.1534 108.383C43.7332 108.713 33.6296 121.545 28.4095 116.446C24.3116 112.443 39.0198 101.145 45.0798 96.2087C51.1433 91.2729 67.63 88.3502 89.7041 96.7026C108.354 103.761 119.821 110.713 125.568 103.761C127.269 101.703 130.979 95.5887 126.061 93.1872C118.74 89.6106 115.24 111.932 137.865 107.126C160.494 102.32 145.081 32.4244 214.378 47.4703C247.855 54.7395 252.796 116.391 225.155 115.71C221.234 115.615 216.925 114.406 215.051 110.941C210.647 102.8 216.14 94.2705 222.669 91.2729C234.384 85.8942 247.538 83.8947 274.612 101.839C291.894 113.295 320.015 113.915 327.394 97.5712" stroke="#7583F7" strokeWidth="2.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M27.583 114.97C24.1415 115.931 19.8259 115.512 16.5034 114.565C15.4798 114.272 14.1365 113.925 12.426 114.075C8.72258 114.405 5.33886 121.545 20.3292 124.341C51.7995 130.214 58.1929 115.437 64.1135 112.494C75.8936 106.638 93.2476 125.162 93.2476 125.162C93.2476 125.162 126.34 155.527 156.358 134.373C161.439 130.793 167.23 125.193 170.451 121.231" stroke="#7583F7" strokeWidth="2.0625" strokeMiterlimit="10"/>
    <path d="M18.5338 114.926C18.5338 114.926 -1.22108 110.13 5.05665 104.71C9.43338 100.929 21.0673 110.027 29.4875 110.371" stroke="#7583F7" strokeWidth="2.0625" strokeMiterlimit="10"/>
    <path d="M12.3818 104.346C12.3818 104.346 12.2798 98.5114 17.8434 100.252C23.2336 101.938 29.0658 105.27 34.1192 105.27" stroke="#7583F7" strokeWidth="2.0625" strokeMiterlimit="10"/>
    <path d="M26.454 103.034C26.454 103.034 26.454 99.1887 32.1333 100.098C35.9013 100.701 37.3975 101.532 40.8425 99.6111" stroke="#7583F7" strokeWidth="2.0625" strokeMiterlimit="10"/>
    <path d="M15.8179 34.439C29.9875 27.1142 47.8655 30.6071 53.3182 42.5203C58.7708 54.4335 56.4724 69.7984 42.3028 77.1273C28.1333 84.4522 12.7004 82.5672 7.24775 70.654C1.7951 58.7408 1.65248 41.768 15.8179 34.439Z" fill="#EE4168"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.3213 60.8628C28.787 60.9105 28.736 59.5138 28.9571 58.0253C29.1781 56.5333 31.8069 55.869 33.4664 54.0705C35.1294 52.2719 36.1326 46.5322 32.5585 44.5054C28.9843 42.4786 20.7443 45.6635 20.7987 48.2183C20.8531 50.7731 20.9279 51.0456 23.7267 49.8806C26.5255 48.7156 28.583 47.4825 30.2085 47.8743C31.834 48.266 32.9087 50.6062 31.494 51.8699C30.0759 53.1337 27.4029 52.9975 26.5086 56.3459C25.6142 59.6944 26.1106 60.8253 27.3213 60.8628ZM27.7022 68.1728C29.8923 68.7145 30.9158 68.1933 31.0417 66.0302C31.1675 63.8672 28.3211 63.0667 26.4235 64.4292C24.5259 65.7952 25.5121 67.6312 27.7022 68.1728Z" fill="white"/>
    <g opacity="0.5">
      <path d="M44.2525 14.1716C43.4318 14.2112 42.7172 14.8851 42.6563 15.6769C42.579 16.6836 42.4879 17.6519 42.3954 18.6338L42.3949 18.6391C42.3029 19.6159 42.2097 20.6062 42.1304 21.6388C42.0696 22.4306 42.6856 23.0404 43.5063 23.0008C44.327 22.9613 45.0416 22.2873 45.1024 21.4956C45.1798 20.4889 45.2709 19.5205 45.3634 18.5387L45.3639 18.5334C45.4558 17.5566 45.5491 16.5662 45.6284 15.5336C45.6892 14.7418 45.0732 14.132 44.2525 14.1716Z" fill="#E9416A"/>
      <path d="M55.881 18.7865C56.3057 18.0621 57.2214 17.736 57.9263 18.058C58.6313 18.3801 58.8584 19.2284 58.4337 19.9528L54.4922 26.676C54.0675 27.4004 53.1518 27.7265 52.4468 27.4045C51.7419 27.0825 51.5148 26.2341 51.9395 25.5098L55.881 18.7865Z" fill="#E9416A"/>
      <path d="M60.7968 35.4532C59.9864 35.6865 59.2134 35.2613 59.0703 34.5037C58.9272 33.746 59.4682 32.9427 60.2786 32.7095C62.4443 32.0861 64.5659 31.5527 66.7065 31.0823C66.9823 31.0097 67.2532 30.9557 67.4708 30.9124L67.4908 30.9084C67.7412 30.8585 67.9278 30.8207 68.1008 30.774C68.9137 30.5547 69.6766 30.9946 69.8047 31.7564C69.9328 32.5182 69.3777 33.3136 68.5648 33.5328C68.2752 33.6109 67.9882 33.6681 67.76 33.7136L67.74 33.7175C67.4896 33.7675 67.303 33.8053 67.13 33.8519C67.1046 33.8588 67.0791 33.865 67.0536 33.8706C64.9663 34.3286 62.9022 34.8472 60.7968 35.4532Z" fill="#E9416A"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_2102_3571">
      <rect width="210" height="167" fill="white"/>
    </clipPath>
  </defs>
</svg>
);

export default GenericErrorIcon;
