import { HyroLoader } from "../../hyro-components";
import { INSIGHTS_LOADER_TEXT } from "./InsightsConfig.ts";
import React from "react";
import ResponsiveIframe from "../../hyro-components/ResponsiveIframe";

const InsightsBase = ({ embedUrl, homepage }) => embedUrl
  ? (
    <ResponsiveIframe src={embedUrl} homepage={homepage} />
  )
  : (
    <HyroLoader {...{ text: INSIGHTS_LOADER_TEXT }} />
  );

export default InsightsBase;
