import React from "react";

const VoiceEssentialIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="#EBEBFD"/>
  <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#E0E0FF"/>
  <path d="M30.2451 19.9999C31.036 21.1338 31.4998 22.5127 31.4998 23.9999C31.4998 25.4872 31.036 26.8661 30.2451 27.9999M24.1343 16.3656L20.9686 19.5313C20.7957 19.7042 20.7092 19.7907 20.6083 19.8526C20.5188 19.9074 20.4213 19.9478 20.3192 19.9723C20.2041 19.9999 20.0818 19.9999 19.8373 19.9999H18.1C17.5399 19.9999 17.2599 19.9999 17.046 20.1089C16.8578 20.2048 16.7049 20.3578 16.609 20.5459C16.5 20.7598 16.5 21.0399 16.5 21.5999V26.3999C16.5 26.96 16.5 27.24 16.609 27.4539C16.7049 27.6421 16.8578 27.7951 17.046 27.8909C17.2599 27.9999 17.5399 27.9999 18.1 27.9999H19.8373C20.0818 27.9999 20.2041 27.9999 20.3192 28.0276C20.4213 28.0521 20.5188 28.0925 20.6083 28.1473C20.7092 28.2091 20.7957 28.2956 20.9686 28.4686L24.1343 31.6342C24.5627 32.0626 24.7769 32.2768 24.9608 32.2913C25.1203 32.3038 25.2763 32.2392 25.3802 32.1175C25.5 31.9773 25.5 31.6744 25.5 31.0686V16.9313C25.5 16.3255 25.5 16.0226 25.3802 15.8823C25.2763 15.7606 25.1203 15.696 24.9608 15.7086C24.7769 15.723 24.5627 15.9372 24.1343 16.3656Z" stroke="#7583F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);

export default VoiceEssentialIcon;
