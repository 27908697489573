import Rx from "../../icons/collections/skills/RxIcon";
import CallToText from "../../icons/collections/skills/CallToTextIcon";
import AppointmentVerification from "../../icons/collections/skills/ApptVerificationIcon";
import Spot from "../../icons/collections/skills/SpotIcon";
import EmptyCircle from "../../icons/collections/EmptyCircle";
import TransferToLiveAgent from "../../icons/collections/building-blocks/TransferToLiveAgentIcon";
import Sip from "../../icons/collections/building-blocks/SipIcon";
import PatientIdentification from "../../icons/collections/building-blocks/PatientIdentificationIcon";
import EpicIcon from "../../icons/collections/integrations/EpicIcon";
import LiveChatIcon from "../../icons/collections/building-blocks/LiveChatIcon";
import SmartTransferIcon from "../../icons/collections/skills/SmartTransferIcon";
import ApptCancellationIcon from "../../icons/collections/skills/ApptCancellationIcon";
import ApptReschedulingIcon from "../../icons/collections/skills/ApptReschedulingIcon";
import PlaygroundIcon from "../../icons/collections/skills/PlaygroundIcon";
import GenesysIcon from "../../icons/collections/integrations/GenesysIcon";
import { USER_PERMISSIONS } from "@hyro/dashboard-commons";
import { capitalize } from "src/utils";
import ArteraIcon from "../../icons/collections/integrations/ArteraIcon";
import SalesforceIcon from "../../icons/collections/integrations/SalesforceIcon";
import AWSConnectIcon from "../../icons/collections/integrations/AWSConnectIcon";
import ApptConfirmationOutBoundIcon from "../../icons/collections/skills/ApptConfirmationOutBoudIcon";
import CheckStatusIcon from "../../icons/collections/skills/CheckStatusIcon";
import OpenSchedulingIcon from "../../icons/collections/skills/OpenSchedulingIcon";
import SiteInformationIcon from "../../icons/collections/skills/SiteInformationIcon";
import FindAProviderIcon from "../../icons/collections/skills/FindAProviderIcon";
import CallDetailsRecordIcon from "../../icons/collections/building-blocks/CallDetailsRecordIcon";
import HyroInternalIcon from "../../icons/collections/integrations/HyroInternalIcon";
import SpinSciIcon from "../../icons/collections/integrations/SpinSciIcon";
import TwilioIcon from "../../icons/collections/integrations/TwilioIcon";
import ConversationServiceIcon from "../../icons/collections/building-blocks/ConversationServiceIcon";
import EnglishFlagIcon from "../../icons/flags/EnglishFlagIcon";
import SpanishFlagIcon from "../../icons/flags/SpanishFlagIcon";
import VoiceEssentialIcon from "../../icons/collections/VoiceEssentialIcon";
import WebEssentialIcon from "../../icons/collections/WebEssentialIcon";
import NiceIcon from "src/icons/collections/integrations/NiceIcon";
import PatientRegistrationIcon from "src/icons/collections/building-blocks/PatientRegistrationIcon";
import CernerIcon from "src/icons/collections/integrations/CernerIcon";
import LinkToChatIcon from "src/icons/collections/building-blocks/LinkToChatIcon";
import ServiceNowIcon from "src/icons/collections/integrations/ServiceNowIcon";
import { FIELD_TYPES } from "src/components/common/fields/utils";
import ControlledResponseIcon from "src/icons/collections/skills/ControlledResponceIcon";
import FaLIcon from "src/icons/collections/skills/FaLIcon";

export const PRIMITIVE_INNER_TYPE = 'FROM_CONSTANT';
export const BUILDING_BLOCKS = 'building-blocks';
export const INTEGRATIONS = 'integrations';
export const SKILLS = 'skills';
export const COLLECTION_TO_TITLE = {
  [BUILDING_BLOCKS]: 'Building Blocks',
  [INTEGRATIONS]: 'Integrations',
  [SKILLS]: 'Skills'
};
export const COLLECTIONS_NAMES = {
  [BUILDING_BLOCKS]: 'building block',
  [INTEGRATIONS]: 'integration',
  [SKILLS]: 'skill'
};
export const getAddNewCollectionButtonText = (collectionName) => `+ Add ${COLLECTIONS_NAMES[collectionName]}`;

export const COLLECTION_TO_PERMISSIONS = {
  [BUILDING_BLOCKS]: USER_PERMISSIONS.BUILDING_BLOCKS_PAGE.ADD_BUILDING_BLOCK,
  [INTEGRATIONS]: USER_PERMISSIONS.INTEGRATIONS_PAGE.ADD_INTEGRATION,
  [SKILLS]: USER_PERMISSIONS.SKILLS_PAGE.ADD_SKILL
};

export const getDialogConst = (collectionName) => {
  const collectionTitle = COLLECTIONS_NAMES[collectionName];
  return {
    FIRST_STEP_TITLE: `Select ${collectionTitle} type`,
    SECOND_STEP_TITLE: `Create new ${collectionTitle}`,
    SUBTITLE: collectionName === SKILLS ? `Name your new ${collectionTitle} instance and give a brief description.` : `Name your new ${collectionTitle} a unique name and provide a brief description.`,
    FIRST_FIELD_LABEL: collectionName === SKILLS ? `${capitalize(collectionTitle)} instance name` : `${capitalize(collectionTitle)} name`,
    SECOND_FIELD_LABEL: "Description",
    DIALOG_ERROR_TITLE: "Error",
    DIALOG_ERROR_CONTENT_CREATE: `It seems like there was an error while trying to create a ${collectionTitle}. This could be a temporary issue. Please try again later.`,
    DIALOG_ERROR_CONTENT_SAVE: `It seems like there was an error while trying to save your changes. This could be a temporary issue. Please try again later.`,
    DIALOG_ERROR_BUTTON_TEXT: `Got it`,
    DISPLAY_KEY_ERROR: `You already have a building block named '$instanceName.'`,
    EMPTY_FIELD_ERROR: `Please provide a value`,
    SELECT_PLACEHOLDER: `${capitalize(collectionTitle)}s`
  };
};

export const COLLECTION_DIALOG_TYPES = {
  DUPLICATE: 'duplicate',
  EDIT: 'edit',
  ENABLE: 'enable',
  DISABLE: 'disable',
  DELETE: 'delete',
  DELETE_LAST_COLLECTION: 'delete_last_collection',
  DISABLE_LAST_COLLECTION: 'disable_last_collection'
};

export const getDialogsConstants = (collectionName) => {
  const collectionTitle = COLLECTIONS_NAMES[collectionName];
  return {
    [COLLECTION_DIALOG_TYPES.DUPLICATE]: {
      TITLE: `Duplicate ${collectionTitle.charAt(0).toUpperCase() + collectionTitle.slice(1)}`,
      BUTTON_TEXT: 'Done',
      SUBTITLE: `Modify the details of the duplicated ${collectionTitle} below.`
    },
    [COLLECTION_DIALOG_TYPES.EDIT]: {
      TITLE: 'Edit name and description',
      BUTTON_TEXT: 'Done',
      SUBTITLE: 'Modify the details below.'
    },
    [COLLECTION_DIALOG_TYPES.ENABLE]: {
      TITLE: `Confirm ${collectionTitle} enablement`,
      BUTTON_TEXT: 'Enable',
      SUBTITLE: `You're about to enable the ${collectionTitle}. Doing so will activate the skill and its configurations in the assistant. Please confirm if you wish to proceed with this action.`
    },
    [COLLECTION_DIALOG_TYPES.DISABLE]: {
      TITLE: `Confirm ${collectionTitle} disablement`,
      BUTTON_TEXT: 'Disable',
      SUBTITLE: `You're about to disable the ${collectionTitle}. Doing so will deactivate the skill and its configurations in the assistant. Please confirm if you wish to proceed with this action.`
    },
    [COLLECTION_DIALOG_TYPES.DELETE]: {
      TITLE: `Confirm ${collectionTitle} deletion`,
      BUTTON_TEXT: 'Delete',
      SUBTITLE: `You are about to delete the ${collectionTitle}. It will result in the permanent deletion all custom configurations associated with it. Please confirm that you want to proceed with this deletion.`
    },
    [COLLECTION_DIALOG_TYPES.DELETE_LAST_COLLECTION]: {
      TITLE: `${collectionTitle.charAt(0).toUpperCase() + collectionTitle.slice(1)} Deletion`,
      SUBTITLE: 'A minimum of 1 skill is required in an assistant, you cannot remove this skill.'
    },
    [COLLECTION_DIALOG_TYPES.DISABLE_LAST_COLLECTION]: {
      TITLE: `${collectionTitle.charAt(0).toUpperCase() + collectionTitle.slice(1)} Disablement`,
      SUBTITLE: 'To disable all skills, please contact your support team.'
    }
  };
};

export const collectionNames = {
  SKILL: {
    VOICE_ESSENTIALS: "f538ddd3-e2dd-461e-bb36-b3a4e0ed3475",
    WEB_ESSENTIALS: "800b0742-241f-49cf-bca3-13dfd5bb14a4",
    SPOT: {
      WEB: "ccf91839-88d7-49a1-aa6d-31d6fa6ae4e5",
      VOICE: "4f8ac705-9987-4156-a0c7-afa76ee8a83a"
    },
    APPOINTMENT_CANCELLATION: "614196f6-85a7-432d-8951-555a6be5f79b",
    APPOINTMENT_RESCHEDULING: "29634771-5218-42b9-a5d6-21866cf472cf",
    APPOINTMENT_VERIFICATION: "54d056e9-d1d3-4c62-8839-11f4860187c4",
    OPEN_APPOINTMENT_SCHEDULING: "c16819f5-794c-4126-a905-e5a03c76af60",
    OUTBOUND_APPOINTMENT_CONFIRMATION: "06620b1c-ff3b-4823-a8a8-594e418e1681",
    APPOINTMENT_MANAGEMENT: "f14ce644-0419-4b67-8506-527b30deb6b0",
    RX: "e860f45b-e45f-41dd-8d86-7c891149281a",
    SMART_TRANSFER: "fa7669e5-ee03-4f4f-9a71-95b31378c975",
    CALL_TO_TEXT: "8e23c273-783d-4091-a740-6fb0f83c3285",
    PLAYGROUND: "222c7eae-6524-49db-80b0-378b06b0eb06",
    SITE_INFORMATION: "edd8e124-8666-473b-a432-a403819e0b3c",
    FIND_A_PROVIDER: "b3f1db83-6c0c-4420-a3f2-69f3c14b0661",
    FIND_A_LOCATION: "e83fad99-5bf8-4788-a8c4-71134de39399",
    CONTROLLED_RESPONSE: "f8415f89-3a55-464e-972e-aa781954c5a0",
    CHECK_STATUS: "2ec78c28-b899-4ca8-999a-047d9ae858d4"
  },
  BUILDING_BLOCK: {
    TRANSFER_TO_LIVE_AGENT: "9cdd288f-d17f-4185-996b-cd26050f6bef",
    PATIENT_IDENTIFICATION: {
      WEB: "53f6f600-a220-4ae0-909c-08003993cd7d",
      VOICE: "dcb24c0f-0942-42eb-862c-c82ae9267fcb"
    },
    SIP: "4474ec70-ebcd-48a3-b51c-40c56a6b3029",
    LIVE_CHAT: "87bc1237-d256-4a5d-a70d-a478337ea87d",
    CONVERSATION_SERVICE: "9e531d58-17de-4498-9f3d-58017389eb4f",
    CALL_DETAILS_RECORD: "7952a877-8e38-47b5-938e-9a322f32a9df",
    PATIENT_REGISTRATION: "581be36d-c7c5-4102-b6c4-90cfa34dde58",
    LINK_TO_CHAT: "f4f6f9f2-7b8f-4f9b-8c2c-5c5d7c3f1d7e"
  },
  INTEGRATION: {
    EPIC: "0d0149e3-cf16-445e-a5b0-6ceba426b3ad",
    TCH_EPIC: "36bdbb85-ee74-4ccc-9e7f-c535c396f0c2",
    GENESYS: "43dd337c-8bce-4b6e-8766-0fe846bc95f2",
    ARTERA: "3f5cd43d-a4c6-44c8-94e1-ee1e3181e3a0",
    AWS_CONNECT: "6c2a6b44-03d7-4d20-8dd0-ab24620fb984",
    SALESFORCE_LIVE_CHAT: "8572351d-9467-4179-87b1-00caed164eb9",
    IMH_SALESFORCE: "d4691f16-bcf5-475f-9a06-141d42041bb4",
    SALESFORCE: "74a70bff-874f-413c-b97c-b0724f0d74a6",
    TWILIO: "9ce85c24-e79f-45b4-9780-d6a505cc1acf",
    TWILIO_CUSTOM: "310ee60e-02d8-46fe-9cbb-4730fe3c6b73",
    SPIN_SCI: "19830aba-c924-47b0-94e1-99c4ffbd79ef",
    LIVE_CHAT: "a7c40fad-5de5-4f40-9036-31e69863c2d5",
    IDENTITY_INTEGRATION: "2b5b7a11-8492-45fb-a255-fd7ae2c9d93e",
    CONVERSION_INFORMATION: "6f47c1b2-820a-4d14-8106-cea689e063f8",
    EHR_SIMULATION: "63bee665-1ea4-434a-8378-c7e6fb9973a2",
    NICE: "a34c8b09-9581-4fef-bc09-dd925a20b085",
    CERNER: "27eb16e8-34ad-40a8-a666-2b5527f43e1c", // aka Oracle Health
    SALESFORCE_KNOWLEDGE: "e6057671-c7c6-46af-82e0-b625051df487",
    SERVICENOW_KNOWLEDGE: "28f7049d-235a-4f20-9544-cfd04d31d885",
    SERVICENOW_IT: "6a37bd9d-d013-4bec-b62a-839e32140640"
  }
};

export const getCollectionIcon = (collectionKey) => {
  switch (collectionKey) {
  case collectionNames.SKILL.VOICE_ESSENTIALS : {
    return VoiceEssentialIcon;
  }
  case collectionNames.SKILL.WEB_ESSENTIALS : {
    return WebEssentialIcon;
  }
  case collectionNames.SKILL.RX : {
    return Rx;
  }
  case collectionNames.SKILL.CALL_TO_TEXT : {
    return CallToText;
  }
  case collectionNames.SKILL.PLAYGROUND : {
    return PlaygroundIcon;
  }
  case collectionNames.SKILL.APPOINTMENT_VERIFICATION : {
    return AppointmentVerification;
  }
  case collectionNames.SKILL.APPOINTMENT_CANCELLATION : {
    return ApptCancellationIcon;
  }
  case collectionNames.SKILL.APPOINTMENT_RESCHEDULING : {
    return ApptReschedulingIcon;
  }
  case collectionNames.SKILL.OPEN_APPOINTMENT_SCHEDULING :
  case collectionNames.SKILL.APPOINTMENT_MANAGEMENT : {
    return OpenSchedulingIcon;
  }
  case collectionNames.SKILL.OUTBOUND_APPOINTMENT_CONFIRMATION : {
    return ApptConfirmationOutBoundIcon;
  }
  case collectionNames.SKILL.CHECK_STATUS : {
    return CheckStatusIcon;
  }
  case collectionNames.SKILL.SPOT.WEB:
  case collectionNames.SKILL.SPOT.VOICE: {
    return Spot;
  }
  case collectionNames.SKILL.SMART_TRANSFER: {
    return SmartTransferIcon;
  }
  case collectionNames.SKILL.SITE_INFORMATION: {
    return SiteInformationIcon;
  }
  case collectionNames.SKILL.FIND_A_PROVIDER: {
    return FindAProviderIcon;
  }
  case collectionNames.SKILL.FIND_A_LOCATION: {
    return FaLIcon;
  }
  case collectionNames.SKILL.CONTROLLED_RESPONSE: {
    return ControlledResponseIcon;
  }
  case collectionNames.BUILDING_BLOCK.TRANSFER_TO_LIVE_AGENT : {
    return TransferToLiveAgent;
  }
  case collectionNames.BUILDING_BLOCK.SIP : {
    return Sip;
  }
  case collectionNames.BUILDING_BLOCK.CONVERSATION_SERVICE : {
    return ConversationServiceIcon;
  }
  case collectionNames.BUILDING_BLOCK.PATIENT_IDENTIFICATION.WEB :
  case collectionNames.BUILDING_BLOCK.PATIENT_IDENTIFICATION.VOICE :
    return PatientIdentification;
  case collectionNames.BUILDING_BLOCK.CALL_DETAILS_RECORD : {
    return CallDetailsRecordIcon;
  }
  case collectionNames.BUILDING_BLOCK.LIVE_CHAT : {
    return LiveChatIcon;
  }
  case collectionNames.BUILDING_BLOCK.PATIENT_REGISTRATION : {
    return PatientRegistrationIcon;
  }
  case collectionNames.BUILDING_BLOCK.LINK_TO_CHAT : {
    return LinkToChatIcon;
  }
  case collectionNames.INTEGRATION.TCH_EPIC :
  case collectionNames.INTEGRATION.EPIC : {
    return EpicIcon;
  }
  case collectionNames.INTEGRATION.ARTERA : {
    return ArteraIcon;
  }
  case collectionNames.INTEGRATION.TWILIO :
  case collectionNames.INTEGRATION.TWILIO_CUSTOM : {
    return TwilioIcon;
  }
  case collectionNames.INTEGRATION.GENESYS : {
    return GenesysIcon;
  }
  case collectionNames.INTEGRATION.SALESFORCE :
  case collectionNames.INTEGRATION.IMH_SALESFORCE :
  case collectionNames.INTEGRATION.SALESFORCE_LIVE_CHAT :
  case collectionNames.INTEGRATION.SALESFORCE_KNOWLEDGE : {
    return SalesforceIcon;
  }
  case collectionNames.INTEGRATION.SPIN_SCI : {
    return SpinSciIcon;
  }
  case collectionNames.INTEGRATION.AWS_CONNECT : {
    return AWSConnectIcon;
  }
  case collectionNames.INTEGRATION.EHR_SIMULATION :
  case collectionNames.INTEGRATION.LIVE_CHAT :
  case collectionNames.INTEGRATION.IDENTITY_INTEGRATION :
  case collectionNames.INTEGRATION.CONVERSION_INFORMATION : {
    return HyroInternalIcon;
  }
  case collectionNames.INTEGRATION.NICE : {
    return NiceIcon;
  }
  case collectionNames.INTEGRATION.CERNER : {
    return CernerIcon;
  }
  case collectionNames.INTEGRATION.SERVICENOW_KNOWLEDGE :
  case collectionNames.INTEGRATION.SERVICENOW_IT : {
    return ServiceNowIcon;
  }
  default: {
    return EmptyCircle;
  }
  }
};

export const LANGUAGES = {
  ENGLISH: "ENGLISH",
  SPANISH: "SPANISH"
};

export const getLanguageIcon = (language) => {
  switch (language) {
  case LANGUAGES.ENGLISH: {
    return EnglishFlagIcon;
  } case LANGUAGES.SPANISH: {
    return SpanishFlagIcon;
  }
  }
};
export const MENU_ITEMS_LABEL = {
  VIEW_CONFIGURATIONS: "View Configurations",
  DUPLICATE: "Duplicate",
  CONFIGURE: "Configure",
  EDIT: "Edit name",
  COLLECTION_EDIT: "Edit",
  ENABLE: "Enable",
  DISABLE: "Disable",
  COLLECTION_DELETE: "Delete"
};

export const DIALOG_TYPES = {
  add: "add",
  rename: "rename",
  delete: "delete",
  enable: "enable",
  disable: "disable"
};

export const STATUS_TYPES = {
  DRAFT: "Draft",
  PENDING: "Pending Publish",
  LIVE: "Live",
  DISABLED: "Disabled",
  INCOMPLETE: "Incomplete mandatory fields"
};
export const STATUS_COLORS = {
  [STATUS_TYPES.DRAFT]: "#5C65E6",
  [STATUS_TYPES.PENDING]: "#5C65E6",
  [STATUS_TYPES.LIVE]: "rgba(21, 22, 71, 0.5)"
};

export const STATUS_TO_CHIP_COLOR = {
  BACKGROUND: {
    [STATUS_TYPES.DRAFT]: "#FFF2CA",
    [STATUS_TYPES.INCOMPLETE]: "#F9EEEF",
    [STATUS_TYPES.LIVE]: "#DDF8EF",
    [STATUS_TYPES.PENDING]: "#EDF5FF",
    [STATUS_TYPES.DISABLED]: "#EEEEEE"
  },
  TEXT: {
    [STATUS_TYPES.DRAFT]: "#B08310",
    [STATUS_TYPES.INCOMPLETE]: "#C13842",
    [STATUS_TYPES.LIVE]: "#428E6E",
    [STATUS_TYPES.PENDING]: "#41689E",
    [STATUS_TYPES.DISABLED]: "#2D2C44B2"
  }
};

export const STATUS_VARIANTS = {
  TEXT: "text",
  FILLED: "filled"
};

export const STATUS = "Status";

export const DEFAULT_TEXT_FIELD_ERROR = "Please provide a value";

export const NO_OPTIONS_MESSAGE = "No options available. Create a $label to fill the list.";
export const VAGUE_NO_OPTIONS_MESSAGE = "No options available.";
export const NO_OPTIONS_ERROR_MESSAGE ="Error retrieving $label. Please Refresh the page.";

export const STATICS_SKILLS_KEYS = [collectionNames.SKILL.VOICE_ESSENTIALS, collectionNames.SKILL.WEB_ESSENTIALS];

export const getMandatoryMessageForType = (type, language) => {
  if (type === FIELD_TYPES.CHOOSE_ONE ||type === FIELD_TYPES.CHOOSE_AT_LEAST_ONE || type === FIELD_TYPES.COLLECTION_REF )
    return "This field is mandatory. Please select an option from the list.";
  else if (type === FIELD_TYPES.MULTI_LANGUAGE_TEXT)
    return {[language]: "This field is mandatory. Please enter a value for this field."};
  else
    return "This field is mandatory. Please enter a value for this field.";
};


export const TABS = {
  titles: {
    general: "General Configurations",
    utterances: "Utterances"
  },
  names: {
    general: "General",
    utterances: "Utterances"
  }
};
export const WRAPPER_OBJECT_NAME = "OBJECT";
export const LIST_TYPES = {
  TABLE: "OBJECT"
};


export const SKILL_TABLE = {
  EDIT: "Edit",
  DELETE: "Delete",
  NO_RESULTS: "Nothing here yet"
};

export const COLLECTIONS_TYPE_TO_GET_OPTIONS = [INTEGRATIONS];
