import { AppWrapper, ContentWrapper, TopContainer } from "../LayoutWrappers";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import {
  filterNavItemByPermission,
  getAdminNavItems
} from "../Navigation/navigationItems";
import {
  hasPermissionSelector,
  isAdminSelector, setPageNameSelector
} from "../../stores/selectors/appSelectors";
import { useCurrentAdminPage, useIsMobile, useStatusBarDisplayed } from "../../hooks";
import ErrorBoundary from "../ErrorBoundries";
import MobileAdminNavbar from "../Navigation/AdminNavigation/MobileAdminNavbar";
import Navbar from "../Navigation/Navbar";
import PageTitle from "../PageTitle";
import useAppStore from "../../stores/appStore";
import { useMediaQuery, useTheme } from "@mui/material";

const AdminLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const setPageName = useAppStore(setPageNameSelector);
  const isMediumSizeDevice = useMediaQuery(
    theme.breakpoints.between("xs", "md")
  );
  const isAdmin = useAppStore(isAdminSelector);

  const isMobile = useIsMobile();

  const [open, setOpen] = useState(true);

  const hasPermission = useAppStore(hasPermissionSelector);
  const showStatusBar = useStatusBarDisplayed();
  const adminNavItems = getAdminNavItems().filter(
    filterNavItemByPermission(hasPermission)
  );

  useEffect(() => {
    setPageName(location.pathname.split('/')?.[2]);
  }, [location, setPageName]);

  useEffect(() => {
    setOpen(!isMediumSizeDevice);
  }, [isMediumSizeDevice]);

  if (!isAdmin) return <Navigate to="/403" replace />;
  return (
    <div style={{ display: "flex" }}>
      {!isMobile && (
        <Navbar
          coreNavItems={adminNavItems}
          useCurrentPage={useCurrentAdminPage}
          open={open}
          setOpen={setOpen}
        />
      )}
      <Suspense>
        <AppWrapper className="app" statusBarDisplayed={showStatusBar}>
          <ContentWrapper component="main">
            <ErrorBoundary key={location.pathname}>
              <div style={{ flexGrow: 1 }}>
                <TopContainer>
                  {isMobile && (
                    <MobileAdminNavbar {...{ open, setOpen, adminNavItems }} />
                  )}
                  <PageTitle />
                </TopContainer>
                <Outlet />
              </div>
            </ErrorBoundary>
          </ContentWrapper>
        </AppWrapper>
      </Suspense>
    </div>
  );
};

export default AdminLayout;
