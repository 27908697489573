import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";
import { compressSync, strToU8 } from "fflate";
import { LOCKED_STATUS_CODE } from "../components/admin/Settings/consts";
import { ENVIRONMENTS } from "src/utils";

const disableGzip = import.meta.env.VITE_ENV === ENVIRONMENTS.LOCAL;
const gzipJSON = data => disableGzip ? data : compressSync(strToU8(JSON.stringify(data)));

export const api = axios.create({
  baseURL: import.meta.env.VITE_DASHBOARD_API_URL,
  headers: {
    ...(disableGzip ? {} : { "Content-Encoding": "gzip" }),
    "Content-Type": "application/json"
  },
  paramsSerializer: {
    indexes: null
  }
});

api.interceptors.response.use(
  response => response,
  (error) => {
    datadogRum.addError(error);
    if (error?.response?.status === LOCKED_STATUS_CODE){
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Delay of 1 second
    }
    return Promise.reject(error);
  }
);

export const get = async (endpoint, params = {}, signal) => {
  const response = await api.get(endpoint, { params, signal });
  return response.data;
};

export const post = async (endpoint, data = {}, params, headers, signal) => {
  try{
    const requestData = gzipJSON(data);
    const response = await api.post(endpoint, requestData , { params, headers, signal });
    return response.data;
  } catch (error){
    if (!axios.isCancel(error)) {
      throw error;
    }
  }
};

export const put = async (endpoint, data = {}, headers) => {
  const requestData = gzipJSON(data);
  const response = await api.put(endpoint, requestData, { headers });
  return response.data;
};

export const patch = async (endpoint, data = {}, headers) => {
  const requestData = gzipJSON(data);
  const response = await api.patch(endpoint, requestData, { headers });
  return response.data;
};

export const del = async (endpoint, data, headers) => {
  let config = { headers };
  if (data) {
    config = { data: gzipJSON(data) };
  }
  const response = await api.delete(endpoint, config);
  return response.data;
};
