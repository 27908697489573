import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CrownIcon = () => (<SvgIcon>
  <path
    d="M3.3 7.36877C3.32429 7.1402 3.61 7.08306 3.74286 7.27306L5.94714 10.4616C6.08666 10.6637 6.27589 10.8263 6.49657 10.9339C6.71726 11.0415 6.96195 11.0904 7.20705 11.0759C7.45214 11.0614 7.68936 10.984 7.89581 10.8511C8.10227 10.7183 8.271 10.5344 8.38571 10.3173L11.6286 4.18163C11.6572 4.12685 11.7003 4.08096 11.7532 4.04895C11.8061 4.01693 11.8667 4 11.9286 4C11.9904 4 12.051 4.01693 12.1039 4.04895C12.1568 4.08096 12.1999 4.12685 12.2286 4.18163L15.4714 10.3159C15.5861 10.533 15.7549 10.7168 15.9613 10.8497C16.1678 10.9826 16.405 11.06 16.6501 11.0745C16.8952 11.089 17.1399 11.0401 17.3606 10.9325C17.5813 10.8249 17.7705 10.6622 17.91 10.4602L20.1143 7.27163C20.2471 7.08163 20.5329 7.13734 20.5571 7.36734C20.72 8.88734 20.8571 10.4473 20.8571 12.0373C20.8571 13.6273 20.72 15.1873 20.5571 16.7059C20.4437 17.7109 19.9931 18.6478 19.2789 19.3638C18.5647 20.0798 17.629 20.5328 16.6243 20.6488C15.0957 20.8202 13.5286 20.9659 11.9286 20.9659C10.3286 20.9659 8.76 20.8202 7.23286 20.6488C6.22819 20.5328 5.29245 20.0798 4.57823 19.3638C3.86402 18.6478 3.41345 17.7109 3.3 16.7059C3.13714 15.1888 3 13.6302 3 12.0388C3 10.4473 3.13714 8.88877 3.3 7.36877Z"
    stroke="#2D2C44"
    strokeOpacity="0.7"
    strokeWidth="2"
    strokeLinejoin="round"
    fill="white"
  />
</SvgIcon>);

export default CrownIcon;
