export const DEVELOPMENT_ENV = "development";
export const PRODUCTION_ENV = "production";
export const LOCAL_ENV = "local";

const DEV_SNIPPET_LOCATION = "https://demo.airbud.io/widgets/dev/widget.js";
const PROD_SNIPPET_LOCATION = "https://api.airbud.io/widgets/widget.js";

export const resolveEnvironmentSnippetLocation = environment =>
  ({
    [DEVELOPMENT_ENV]: DEV_SNIPPET_LOCATION,
    [PRODUCTION_ENV]: PROD_SNIPPET_LOCATION,
    [LOCAL_ENV]: DEV_SNIPPET_LOCATION
  }[environment]);

export const makeSnippet = ({
  assistantId,
  previewAssistantId,
  environment,
  usePreviewUrl
// eslint-disable-next-line no-secrets/no-secrets
}) => `<script>
    window.HYRO_WIDGET_SERVER_URL = "${usePreviewUrl ? import.meta.env.VITE_PREVIEW_WIDGET_SERVER_URL : import.meta.env.VITE_WIDGET_SERVER_URL}";
    window.HYRO_WIDGET_ID = "${usePreviewUrl ? previewAssistantId : assistantId}";
    (function() {
      const f = function() {
        const d = document;
        const l = d.createElement('script');
        l.type = 'text/javascript';
        l.async = true;
        l.src = '${resolveEnvironmentSnippetLocation(
    environment
  )}?id=' + window.HYRO_WIDGET_ID;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(l, x);
      };
      window.addEventListener('load', f, false);
    })();
  </script>`;

export const makeBranchDeployerSnippet =
// eslint-disable-next-line no-secrets/no-secrets
(assistantId, widgetServerUrl) => `<script>
    window.HYRO_WIDGET_SERVER_URL = "${widgetServerUrl.replace("https", "wss")}";
    window.HYRO_WIDGET_ID = "${assistantId}";
    (function() {
      const f = function() {
        const d = document;
        const l = d.createElement('script');
        l.type = 'text/javascript';
        l.async = true;
        l.src = 'https://demo.airbud.io/widgets/dev/widget.js?id=' + window.HYRO_WIDGET_ID;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(l, x);
      };
      window.addEventListener('load', f, false);
    })();
  </script>`;
