import { filter, head, join, pathOr, pipe, propEq } from "ramda";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import CodelessIcon from "./components/widgets/assistant-creation/CodelessIcon";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import DemoIcon from "./components/widgets/assistant-creation/DemoIcon";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LegacyIcon from "./components/widgets/assistant-creation/LegacyIcon";
import NewDeploymentIcon from "./components/widgets/assistant-creation/NewDeploymentIcon";
import PhoneIcon from "./components/widgets/assistant-creation/PhoneIcon";
import ScreenIcon from "./components/widgets/assistant-creation/ScreenIcon";
import assistantTypeDemoIcon from "./static/images/demo_icon.svg";
import assistantTypeLiveIcon from "./static/images/production_icon.svg";
import assistantTypeNewDeploymentIcon from "./static/images/new_deployment.svg";
import assistantTypeNoLongerActiveIcon from "./static/images/no_longer_active_icon.svg";
import assistantTypeTestIcon from "./static/images/test_icon.svg";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { USER_PERMISSIONS } from "@hyro/dashboard-commons";

export const FRONTEGG_DASHBOARD_CLIENT_ID
  = import.meta.env.VITE_FRONTEGG_DASHBOARD_CLIENT_ID;
export const FRONTEGG_DASHBOARD_BASE_URL
  = import.meta.env.VITE_FRONTEGG_DASHBOARD_BASE_URL;
const CONFIGURATIONS_DIRECTORY_NAME = "data";
const ADMIN = "admin";
const CONVERSATIONS = "conversations";
const HOME = "home";
const DATA_SOURCES = "data-sources";
const FIND_A_PROVIDER = "find-a-provider";
const VOICE_INSIGHTS = "voice";
const CHAT_INSIGHTS = "chat";
const INSIGHTS = "insights";
const BACK_OFFICE = "back-office";
const KNOWLEDGE_DEBUGGER = "knowledge-debugger";
const KNOWLEDGE_EDITOR = "knowledge-editor";
const KNOWLEDGE_EXPLORER = "knowledge-explorer";
const RESPONSES = "responses";
const SETTINGS = "settings";
const GENERAL_SETTINGS = "general";
const CHANNEL_SETTINGS = "channel";
const SKILLS = "skills";
const BUILDING_BLOCKS = "building-blocks";
const INTEGRATIONS = "integrations";
const AUDIT_LOG = "audit-log";
const BRANCH_DEPLOYER = "branch-deployer";
const CORE_HYRO_KPIS = "core-hyro-kpis";
const TEMPLATE_SETTINGS = "template";
const ALL_ASSISTANTS = "all-assistants";
const ACCOUNT_MANAGEMENT = "account-management";
const TRIGGER = "trigger";
const SYNONYM = "synonym";
const SYNONYMS = "synonyms";
const PROMPT_PLAYGROUND = "prompt-playground";
const QUESTION_GENERATOR = "question-generator";
const PIPELINE_PERFORMANCE = "pipeline-performance";
const breadcrumbAllowedPages = [SKILLS, BUILDING_BLOCKS, INTEGRATIONS];

export const OPERATIONS = {
  ADD: 'Add',
  DELETE: 'Delete',
  UPDATE: 'Update'
};

const SEVERITY_VARIANTS = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning"
};

const COLOR_VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  ...SEVERITY_VARIANTS
};

const DOWNLOAD_BTN_VARIANTS = {
  TEXT: "text",
  OUTLINED: "outlined"
};

const DOWNLOAD_BTN_SIZES = {
  LARGE: "large",
  MEDIUM: "medium"
};

const COLLECTION_PAGES = [
  SKILLS,
  BUILDING_BLOCKS,
  INTEGRATIONS
];

const PORTAL_PAGES = [
  DATA_SOURCES,
  FIND_A_PROVIDER,
  HOME,
  INSIGHTS,
  CONVERSATIONS,
  TEMPLATE_SETTINGS,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  RESPONSES,
  SYNONYMS,
  SKILLS,
  BUILDING_BLOCKS,
  INTEGRATIONS
];

const PAGE_PERMISSIONS = {
  [DATA_SOURCES]: USER_PERMISSIONS.DATA_SOURCES_PAGE,
  [FIND_A_PROVIDER]: USER_PERMISSIONS.FIND_A_PROVIDER_PAGE,
  [SETTINGS]: USER_PERMISSIONS.SETTINGS_PAGE,
  [KNOWLEDGE_EDITOR]: USER_PERMISSIONS.KNOWLEDGE_EDITOR_PAGE,
  [RESPONSES]: USER_PERMISSIONS.RESPONSES_PAGE,
  [SYNONYMS]: USER_PERMISSIONS.SYNONYMS_PAGE,
  [SKILLS]: USER_PERMISSIONS.SKILLS_PAGE,
  [BUILDING_BLOCKS]: USER_PERMISSIONS.BUILDING_BLOCKS_PAGE,
  [INTEGRATIONS]: USER_PERMISSIONS.INTEGRATIONS_PAGE
};

export const NOTIFICATION_TYPES = {
  DEPLOYMENT: 'Deployment',
  BUILD: 'Build'
};

const NOTIFICATIONS_STATES = {
  IN_PROGRESS: "In progress",
  PENDING_DEPLOYMENT: "Pending deployment",
  LIVE: "Live",
  IN_PROGRESS_FAILED: "In progress failed",
  PENDING_DEPLOYMENT_FAILED: "Pending deployment failed",
  COMPLETED: 'Completed'
};

const DRAFT_STATES = {
  READ_ONLY: "read-only",
  EDIT: "edit",
  OPEN: "open"
};

const CONNECTOR_TYPES = {
  BOT_SOCKET_CONNECTOR: "botSocketConnector",
  WEB_CONNECTOR: "webConnector",
  PHONE_CONNECTOR: "phoneConnector",
  PROACTIVE_PHONE_CONNECTOR: "proactivePhoneConnector"
};

const INSIGHTS_DASHBOARD_NAMES = {
  healthcare: "healthcare",
  overview: "overview",
  rAndD: "rnd",
  coreHyroKpis: "coreHyroKpis",
  voice: 'voice',
  chat: 'chat'
};

const INPUT_TYPES = {
  COLOR: "color",
  ADD_ONE: "add_one",
  PROMPT_BUTTON: "button",
  DESCRIPTION: "description",
  DROPZONE: "dropzone",
  DROPDOWN: "dropdown",
  BOOLEAN: "boolean",
  COMMA_SEPARATED_ARRAY: "comma_separated_array",
  MULTIPLE_CHOICE: "multiple_choice",
  TEXT: "text",
  TEXT_VERIFY: "text_verify"
};

const DATA_SOURCE_TYPES = {
  EXTERNAL_FILE: "External File",
  API: "API",
  MANUAL: "Manual"
};

const ASSISTANT_TYPES = {
  NEW_DEPLOYMENT: "New Deployment",
  LIVE: "Live",
  DEMO: "Demo",
  TEST: "Test",
  NO_LONGER_ACTIVE: "No Longer Active"
};

const ASSISTANT_CHANNELS = {
  VOICE: "Voice",
  PHONE: "Phone",
  WEB: "Web"
};

const CHANNEL_TO_DISPLAY_NAME = { Phone: "Voice", Web: "Chat" };

const ASSISTANT_METHODS = { CODELESS: "Codeless", LEGACY: "Legacy" };

const ASSISTANT_TYPE_TO_ICON = {
  [ASSISTANT_TYPES.DEMO]: {
    src: assistantTypeDemoIcon,
    backgroundColorPath: ["warning", "light"],
    textColorPath: ["warning", "dark"]
  },
  [ASSISTANT_TYPES.LIVE]: {
    src: assistantTypeLiveIcon,
    backgroundColorPath: ["success", "alert", "background"],
    textColorPath: ["success", "dark"]
  },
  [ASSISTANT_TYPES.TEST]: {
    src: assistantTypeTestIcon,
    backgroundColorPath: ["grey", "200"],
    textColorPath: ["action", "active"]
  },
  [ASSISTANT_TYPES.NO_LONGER_ACTIVE]: {
    src: assistantTypeNoLongerActiveIcon,
    backgroundColorPath: ["grey", "200"],
    textColorPath: ["text", "disabled"]
  },
  [ASSISTANT_TYPES.NEW_DEPLOYMENT]: {
    src: assistantTypeNewDeploymentIcon,
    backgroundColorPath: ["secondary", "light"],
    textColorPath: ["primary", "main"]
  }
};

const VERTICALS = {
  HEALTHCARE: "Healthcare",
  REAL_ESTATE: "Real Estate",
  OTHER: "Other"
};

const EMAIL_REGEX
  = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MOBILE_BROWSER_INFO = [
  "iOS",
  "Windows Mobile",
  "Android OS",
  "BlackBerry OS",
  "ios"
];
const CONCEPT_TYPE = "concept/type";


const sortAlphabetically = array =>
  array.sort((str1, str2) => str1.localeCompare(str2));

const objectByAssistantId = (assistantId, assistants) =>
  pipe(filter(propEq("id", assistantId)), head)(assistants);

const wentLiveDate = (assistantId, assistants) =>
  pathOr(
    "2018-01-01 00:00",
    ["config", "analysis", "wentLiveDate"],
    objectByAssistantId(assistantId, assistants),
    "config.analysis.wentLiveDate"
  );


const isNumber = s => !isNaN(s) && s !== "";

const pathMatches = validPaths => pathname =>
  pathname.match(`/${join("|", validPaths)}`);

const readTextFileAsync = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });

const capitalizeString = str =>
  str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

const replaceSpecialChars = c => str => str.toLowerCase().trimStart().replace(/[^A-Za-z0-9]/g, c);

const startsOrEndsWithWhitespace = str => /^\s|\s$/.test(str);

const isValidCssColor = value =>
  CSS.supports("background", value) && !startsOrEndsWithWhitespace(value);

const checkValidCssValueOrBlank = value =>
  isValidCssColor(value) || value === ""
    ? ""
    : "Invalid color input, please enter a valid hex code, RGB value or color name.";

const eventCustomStyleByColorVariant = (colorVariant) => {
  const backgroundColor = ({ palette }) =>
    colorVariant ? palette[colorVariant].main : palette.grey[300];
  return {
    backgroundColor,
    "&:hover": {
      backgroundColor
    },
    "color": theme =>
      theme.palette.common[
        !colorVariant || colorVariant === COLOR_VARIANTS.WARNING
          ? "black"
          : "white"
      ]
  };
};

const assistantTypeComparator = (a, b) => {
  const ordering = [ASSISTANT_TYPES.LIVE, ASSISTANT_TYPES.NEW_DEPLOYMENT, ASSISTANT_TYPES.DEMO, ASSISTANT_TYPES.TEST, ASSISTANT_TYPES.NO_LONGER_ACTIVE];

  return (ordering.indexOf(a.type) + 1 || ordering.length + 1) - (ordering.indexOf(b.type) + 1 || ordering.length + 1);
};

const NAME_TO_ICON = {
  [ASSISTANT_CHANNELS.WEB]: ScreenIcon,
  [ASSISTANT_CHANNELS.VOICE]: PhoneIcon,
  [ASSISTANT_CHANNELS.PHONE]: PhoneIcon,
  [ASSISTANT_TYPES.NEW_DEPLOYMENT]: NewDeploymentIcon,
  [ASSISTANT_TYPES.DEMO]: DemoIcon,
  [VERTICALS.HEALTHCARE]: ContentPasteOutlinedIcon,
  [VERTICALS.REAL_ESTATE]: HomeWorkOutlinedIcon,
  [VERTICALS.OTHER]: CardGiftcardOutlinedIcon,
  [ASSISTANT_METHODS.CODELESS]: CodelessIcon,
  [ASSISTANT_METHODS.LEGACY]: LegacyIcon
};

const WIDGET_TOKEN = "hyro.token";

const deleteWidgetCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const removeUnderscore = str => str?.replace(/_/g, " ");

const capitalizeAndCleanUnderscore = (str) => {
  const isString = typeof str === "string" || str instanceof String;
  return isString ? capitalize(removeUnderscore(str)) : str;
};

const toLowerCaseAndCapitalize = (str) => {
  const isString = typeof str === "string" || str instanceof String;
  return isString ? capitalize(str.toLowerCase()) : str;
};
export const capitalize = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);

// Taken from https://stackoverflow.com/a/831583
const middleEllipsisTrim = (string, maxLength) => {
  if (!string || maxLength < 1 || string.length <= maxLength) return string;
  if (maxLength === 1) return string.substring(0, 1) + "...";

  const midpoint = Math.ceil(string.length / 2);
  const toRemove = string.length - maxLength;
  const leftStrip = Math.ceil(toRemove / 2);
  const rightStrip = toRemove - leftStrip;
  return (
    string.substring(0, midpoint - leftStrip)
    + "..."
    + string.substring(midpoint + rightStrip)
  );
};

const extractNameFromEmail = email =>
  capitalize(email?.split("@")?.[0] || "");

const checkWhitespace = s => (/\s/).test(s);

const isValidHttpUrl = (string) => {
  try {
    const newUrl = new URL(string);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:" || newUrl.protocol === "ws:"|| newUrl.protocol === "wss:";
  } catch (err) {
    return false;
  }
};

const stringToColor = (value) => {
  value = value || " ";
  const defaultColors = [
    "#A62A21",
    "#7e3794",
    "#0B51C1",
    "#3A6024",
    "#A81563",
    "#B3003C"
  ];
  const charCodes = [...value].map(letter => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (defaultColors.length - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % defaultColors.length;

  let random = charCodes[0] % defaultColors.length;
  for (let i = 0; i < len; i++)
    random = ((a * random) + c) % defaultColors.length;
  return defaultColors[random];
};

const getStringInitials = (string = " ", maxInitials) => string?.split(/\s/)
  .map(part => part.substring(0, 1).toUpperCase())
  .filter(v => !!v)
  .slice(0, maxInitials)
  .join("")
  .toUpperCase();

const validatePhoneNumber = (input, region = 'US') => {
  try {
    const formattedInput = formatPhoneNumber(input);
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parse(formattedInput, region);
    let isValidPhoneNumber = phoneUtil.isValidNumberForRegion(number, region);
    const formattedNumber = phoneUtil.format(number, PhoneNumberFormat.E164);

    if (!isValidPhoneNumber) {
      const sipNumberRegex = /^(1|\+1)(400|999)\d{7}$/;
      isValidPhoneNumber = sipNumberRegex.test(formattedInput);
    }

    return isValidPhoneNumber && (formattedNumber === formattedInput);
  } catch (e) {
    return false;
  }
};

export const formatPhoneNumber = (value) => !value.startsWith('+') ? `+${value}` : value;

export const shuffleArray  = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const filterObjectByKeys = (obj, keys) => Object.keys(obj)
  .filter(key => keys.includes(key))
  .reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});

export const getTextWidth = ({ text, extraWidth = 0, font = '400 12px Inter, sans-serif' }) => {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  const context = canvas.getContext('2d');

  // Set the font for the context
  context.font = font;

  // Measure the text width
  const metrics = context.measureText(text);
  return metrics.width + extraWidth;
};
export const filterCollectionsByEnv = (collections) => collections?.filter(({ expose_to_external_users }) =>
  import.meta.env.VITE_ENV !== "production" || expose_to_external_users);
export const commaNotation = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
  * @param {(string|string[])} input - input to check
  * @param {string} channel - channel to check
 */
export const checkAssistantChannel = (input, channel) => {
  if (Array.isArray(input)){
    return input.map((str) => str.toLowerCase()).includes(channel.toLowerCase());
  } else {
    return input?.toLowerCase() === channel.toLowerCase();
  }
};

export const getUserDeviceFromEvent = (item) => {
  const os = item.browserInfo ? item.browserInfo.os : null;
  if (os && MOBILE_BROWSER_INFO.includes(os)) {
    return "mobile";
  } else if (["nexmo", "phone", "twilio"].includes(os)) {
    return "phone";
  } else {
    return "desktop";
  }
};

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production'
};

export const isProd = () => import.meta.env.VITE_ENV === ENVIRONMENTS.PRODUCTION;

const BUILD_STATUSES = {
  LIVE: "Live",
  PENDING: "Pending",
  EXPIRED: "Expired",
  TERMINATED: "Terminated",
  FAILED: "Failed",
  NOT_FOUND: "Not Found",
  KG_UPDATE: "KG Update"
};

export {
  BUILD_STATUSES,
  COLLECTION_PAGES,
  ADMIN,
  ALL_ASSISTANTS,
  ACCOUNT_MANAGEMENT,
  BACK_OFFICE,
  ASSISTANT_TYPES,
  ASSISTANT_METHODS,
  ASSISTANT_TYPE_TO_ICON,
  ASSISTANT_CHANNELS,
  BRANCH_DEPLOYER,
  CONFIGURATIONS_DIRECTORY_NAME,
  RESPONSES,
  CHANNEL_SETTINGS,
  CHANNEL_TO_DISPLAY_NAME,
  NOTIFICATIONS_STATES,
  COLOR_VARIANTS,
  DOWNLOAD_BTN_VARIANTS,
  DOWNLOAD_BTN_SIZES,
  CONNECTOR_TYPES,
  DRAFT_STATES,
  HOME,
  INSIGHTS_DASHBOARD_NAMES,
  DATA_SOURCES,
  DATA_SOURCE_TYPES,
  EMAIL_REGEX,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  INPUT_TYPES,
  INSIGHTS,
  INTEGRATIONS,
  KNOWLEDGE_DEBUGGER,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  PORTAL_PAGES,
  PAGE_PERMISSIONS,
  PROMPT_PLAYGROUND,
  NAME_TO_ICON,
  PIPELINE_PERFORMANCE,
  SETTINGS,
  SEVERITY_VARIANTS,
  CONVERSATIONS,
  SKILLS,
  CORE_HYRO_KPIS,
  SYNONYM,
  SYNONYMS,
  TEMPLATE_SETTINGS,
  TRIGGER,
  MOBILE_BROWSER_INFO,
  CONCEPT_TYPE,
  VERTICALS,
  WIDGET_TOKEN,
  QUESTION_GENERATOR,
  BUILDING_BLOCKS,
  AUDIT_LOG,
  VOICE_INSIGHTS,
  CHAT_INSIGHTS,
  extractNameFromEmail,
  middleEllipsisTrim,
  assistantTypeComparator,
  checkValidCssValueOrBlank,
  deleteWidgetCookie,
  eventCustomStyleByColorVariant,
  objectByAssistantId,
  isNumber,
  wentLiveDate,
  pathMatches,
  sortAlphabetically,
  readTextFileAsync,
  capitalizeString,
  replaceSpecialChars,
  capitalizeAndCleanUnderscore,
  toLowerCaseAndCapitalize,
  breadcrumbAllowedPages,
  isValidHttpUrl,
  checkWhitespace,
  stringToColor,
  getStringInitials,
  validatePhoneNumber
};
