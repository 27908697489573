import { Button, buttonClasses, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  handleDraftTripletsSelector,
  isFirstDraftSelector,
  isPublishingTripletsSelector
} from "../../../../../stores/selectors/tripletsSelectors";
import useAppStore from "../../../../../stores/appStore";
import DiscardIcon from "../../../../../icons/DiscardIcon";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";
import { COLORS, DISCARD_DRAFT_DIALOG } from "../../../../consts";
import { DIALOG_VARIANTS } from "../../../../../components/common/Dialogs/DialogConsts";
import {
  isDiscardingChangesSelector,
  updateConfigAndTripletsSelector
} from "../../../../../stores/selectors/appSelectors";

const DraftButtons = ({ inProgressOrTestIsRunning }) => {
  const isPublishingTriplets = useAppStore(isPublishingTripletsSelector);
  const updateConfigAndTriplets = useAppStore(updateConfigAndTripletsSelector);
  const isFirstDraft = useAppStore(isFirstDraftSelector);
  const isDiscardingChanges = useAppStore(isDiscardingChangesSelector);
  const [, deleteCurrentDraft] = useAppStore(handleDraftTripletsSelector);

  const [deleteDraftConfirmationOpen, setDeleteDraftConfirmationOpen]
    = useState(false);

  const handleDiscard = async () => {
    await deleteCurrentDraft();
    setDeleteDraftConfirmationOpen(false);
    updateConfigAndTriplets();
  };

  return (
    <Stack direction="row" gap={1} alignItems={'center'}>
      {!isFirstDraft ? <Button
        sx={{
          height: 22,
          p: '5px 8px',
          lineHeight: '14px',
          borderRadius: '4px',
          fontSize: '13px',
          [`.${buttonClasses.startIcon}`]: { ml: 0, mr: .5 },
          [`&.${buttonClasses.disabled}`]: { bgcolor: COLORS.DISABLED_BTN_BG, color: COLORS.DISABLED_BTN_COLOR }
        }}
        startIcon={<DiscardIcon disabled={isPublishingTriplets || inProgressOrTestIsRunning} />}
        variant='contained'
        disabled={isPublishingTriplets || inProgressOrTestIsRunning}
        onClick={() => {
          setDeleteDraftConfirmationOpen(true);
        }}
      >
        Discard draft
      </Button> : null}
      <Dialog
        handleClose={() => setDeleteDraftConfirmationOpen(false)}
        handleCancel={() => setDeleteDraftConfirmationOpen(false)}
        handleSubmit={handleDiscard}
        open={deleteDraftConfirmationOpen}
        showCloseButton
        cancelButtonText={DISCARD_DRAFT_DIALOG.CANCEL_BUTTON_TEXT}
        submitButtonText={DISCARD_DRAFT_DIALOG.SUBMIT_BUTTON_TEXT}
        submitButtonLoading={isDiscardingChanges}
        title={DISCARD_DRAFT_DIALOG.TITLE}
        variant={DIALOG_VARIANTS.transactional}
      >
        <Typography>{DISCARD_DRAFT_DIALOG.TEXT}</Typography>
      </Dialog>
    </Stack>
  );
};

export default DraftButtons;
