import Navbar from "../Navbar";
import React from "react";
import { useCurrentFullAssistantPage } from "../../../hooks";

const AssistantNavbar = (
  {
    open,
    setOpen,
    generalNavItems,
    showMinimizeButton,
    hyroNavItems
  }
) => (<Navbar
  coreNavItems={generalNavItems}
  secondaryNavItems={hyroNavItems}
  secondaryNavTitle="Hyro Tools"
  showAssistantSelector
  useCurrentPage={useCurrentFullAssistantPage}
  open={open}
  setOpen={setOpen}
  showMinimizeButton={showMinimizeButton}
/>);

export default AssistantNavbar;
