import React from "react";

const HyroInternalIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle cx="24" cy="24" r="23.5" stroke="#E0E0E0"/>
    <path d="M35.7317 12.6696L34.5568 9.18665H33.5776L32.4167 12.6696L28.9337 13.8306V14.8097L32.4167 15.9847L33.5776 19.4536H34.5568L35.7317 15.9847L39.2007 14.8097V13.8306L35.7317 12.6696Z" fill="#EF4068"/>
    <path d="M26.5607 21.8247V26.2253C26.5607 28.656 24.5943 30.6258 22.1677 30.6258C19.7412 30.6258 17.7748 28.656 17.7748 26.2253C17.7748 23.7945 19.7412 21.8247 22.1677 21.8247H26.5607V14.8118H22.1677C15.8642 14.8118 10.76 19.9248 10.76 26.2392C10.76 32.5536 15.8642 37.6666 22.1677 37.6666C28.4573 37.6666 33.5615 32.5536 33.5755 26.2532V21.8527H26.5607V21.8247Z" fill="#161453"/>
  </g>
</svg>;

export default HyroInternalIcon;
