import {
  accountIdsSelector,
  accountNameSelector,
  assistantsSelector,
  fetchingAssistantsSelector,
  setAssistantIdSelector,
  setHeaderSkeletonSelector,
  setSystemModeSelector,
  userDataSelector
} from "../stores/selectors/appSelectors";
import { ASSISTANT_TYPES, assistantTypeComparator, CHANNEL_TO_DISPLAY_NAME } from "../utils";
import AssistantCard from "../components/widgets/my-assistants/AssistantCard";
import Banner from "../components/widgets/my-assistants/Banner";
import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useAppStore from "../stores/appStore";
import { useNavigate } from "react-router-dom";
import PlaceholderPage from "./PlaceholderPage";
import { SYSTEM_MODES } from "../stores/slices/createAppSlice";

const PageTitle = styled(props => <Typography variant="h5" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    marginTop: "24px",
    marginBottom: "24px"
  })
);

const AssistantsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(3),
  [theme.breakpoints.up("xs")]: {
    gridTemplateColumns: "minmax(0, 1fr)"
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))"
  }
}));


const AccountAssistants = () => {
  const accountIds = useAppStore(accountIdsSelector);
  const appAssistants = useAppStore(assistantsSelector);
  const fetchingAssistants = useAppStore(fetchingAssistantsSelector);
  const setShowHeaderSkeleton = useAppStore(setHeaderSkeletonSelector);
  const setSystemMode = useAppStore(setSystemModeSelector);
  const setSelectedAssistantId = useAppStore(setAssistantIdSelector);

  // while assistants are being fetched, display 6 empty skeleton assistants
  const shouldShowSkeleton = (accountIds.length && !appAssistants) || fetchingAssistants;
  const assistants = appAssistants?.length > 0 && !fetchingAssistants
    ? appAssistants.toSorted(assistantTypeComparator)
    : Array(6).fill({}).map((_, index) => ({ id: index + '' }));
  const accountName = useAppStore(accountNameSelector);
  const userData = useAppStore(userDataSelector);
  const firstName = userData.given_name
    ? userData.given_name
    : userData.name?.split(" ")[0];
  const navigate = useNavigate();

  useEffect(() => {
    setSystemMode(SYSTEM_MODES.READ_ONLY);
    setSelectedAssistantId();
    if (appAssistants?.length === 0) {
      setShowHeaderSkeleton(false);
    }
  }, [appAssistants, setShowHeaderSkeleton, setSystemMode, setSelectedAssistantId]);

  return (
    <>
      <Banner accountName={accountName} firstName={firstName} />
      <PageTitle>
        {accountName}
        {" "}
        assistants
      </PageTitle>
      {appAssistants?.length === 0 && !fetchingAssistants ? <PlaceholderPage height={'calc(100vh - 330px)'} /> : <AssistantsContainer data-testid="assistants-container" >
        {assistants.map((assistant) => {
          const {
            id: assistantId,
            name: assistantName,
            type,
            channel,
            liveAt:wentLiveDate,
            createdAt:createdDate
          } = assistant;
          return (
            <AssistantCard
              key={assistantId}
              assistantId={assistantId}
              assistantName={assistantName}
              date={type === ASSISTANT_TYPES.LIVE ? wentLiveDate : createdDate}
              dateType={
                type === ASSISTANT_TYPES.LIVE ? "live since" : "Created"
              }
              channel={  CHANNEL_TO_DISPLAY_NAME[channel] || "Unknown Channel"}
              assistantType={type || "Unknown Type"}
              onClick={() => {
                if (assistantId && !shouldShowSkeleton) {
                  navigate(`${assistantId}`);
                }
              }}
              showSkeleton={shouldShowSkeleton}
            />
          );
        })}
      </AssistantsContainer>}
    </>
  );
};

export default AccountAssistants;
