import { ACCOUNT_EDITOR_ERRORS } from "../../components/widgets/account-management/AccountEditorConsts";
import adminServiceNew from "../../services/adminServiceNew";

const SLICE_PREFIX = "accountManagement";
const ACCOUNT_MANAGEMENT_ERROR = `${SLICE_PREFIX}:setAccountManagementError`;
const CREATE_ACCOUNT = `${SLICE_PREFIX}:createAccount`;
const EDIT_ACCOUNT = `${SLICE_PREFIX}:editAccount`;
const GET_ACCOUNTS = `${SLICE_PREFIX}:getAccounts`;

// Use this function to set the name of the action
const createArgs = type => [false, type];

const createAccountManagementSlice = (set, get) => ({
  managementAccounts: null,
  accountManagementError: null,

  setAccountManagementError: error =>
    set(
      { accountManagementError: error },
      ...createArgs(ACCOUNT_MANAGEMENT_ERROR)
    ),

  getAccountsAdmin: async (page = 0, perPage = 50) => {
    try {
      const managementAccounts = await adminServiceNew.getAccounts({
        page,
        perPage
      });
      set({ managementAccounts }, ...createArgs(GET_ACCOUNTS));
    }
    catch (e) {
      console.error(e);
      set({ managementAccounts: [] }, ...createArgs(GET_ACCOUNTS));
    }
  },

  createAccount: async ({ name }) => {
    try {
      const { pageName } = get();
      const newlyCreatedUser = await adminServiceNew.createAccount({ accountName: name, pageName });
      set(
        {
          managementAccounts: [...get().managementAccounts, newlyCreatedUser]
        },
        ...createArgs(CREATE_ACCOUNT)
      );
    }
    catch (e) {
      console.error(e);
      set(
        {
          accountManagementError: true,
          accountManagementErrorText: getErrorText(e)
        },
        ...createArgs(CREATE_ACCOUNT)
      );
    }
  },

  editAccount: async ({ id, name, accountId }) => {
    try {
      const { pageName } = get();
      const editedUser = await adminServiceNew.editAccount({
        id,
        accountName: name,
        accountId,
        pageName
      });
      const managementAccounts = get().managementAccounts.map(user =>
        user.id === id ? editedUser : user
      );
      set({ managementAccounts }, ...createArgs(EDIT_ACCOUNT));
    }
    catch (e) {
      console.error(e);
      set(
        {
          accountManagementError: true,
          accountManagementErrorText: getErrorText(e)
        },
        ...createArgs(EDIT_ACCOUNT)
      );
    }
  },

  deleteAccount: async ({ id }) => {
    try {
      const { pageName } = get();
      await adminServiceNew.deleteAccount({ id, pageName });
      const managementAccounts = get().managementAccounts.filter(
        user => user.id !== id
      );
      set({ managementAccounts }, ...createArgs(EDIT_ACCOUNT));
    }
    catch (e) {
      console.error(e);
      set(
        {
          accountManagementError: true,
          accountManagementErrorText: getErrorText(e)
        },
        ...createArgs(EDIT_ACCOUNT)
      );
    }
  }
});

export default createAccountManagementSlice;

const getErrorText = (error) => {
  switch (error.response.status) {
  case 409:
    return ACCOUNT_EDITOR_ERRORS.duplicate;
  default:
    return ACCOUNT_EDITOR_ERRORS.general;
  }
};
