import React from "react";

const ChevronDown = ({ disabled, stroke = "#2D2C44", style, ...rest }) => <svg
  {...rest}
  style={style}
  xmlns="http://www.w3.org/2000/svg" width="24" height="24"
  viewBox="0 0 24 24" fill="none">
  <path
    d="M7 10L12 15L17 10"
    stroke={stroke} strokeOpacity={disabled ? .24 : .7} strokeWidth="2" strokeLinecap="round"
    strokeLinejoin="round" />
</svg>
;

export default ChevronDown;
