export const DIALOG_VARIANTS = {
  transactional: "transactional",
  acknowledgement: "acknowledgement",
  passive: "passive"
};

export const EXIT_ASSISTANT_DIALOG = {
  title: "Draft mode reminder",
  content:
    "You are currently in edit mode for this assistant. Leaving now will discard all your unsaved changes.",
  discardButton: "Discard changes",
  keepEditingButton: "Keep editing"
};

export const CREATE_PHONE_NUMBER_ERROR_DIALOG = {
  title: "System error notification",
  content:
    "An unknown error occurred while attempting to create a new phone number. Please try again.",
  buttonText: "Got it"
};

export const SYSTEM_ERROR_DIALOG = {
  title: "System error notification",
  content:
    "A system error has occurred. Please refresh the page to resolve this issue.",
  buttonText: "Refresh"
};
