import makeService from "./makeService";

const adminService = makeService({
  service: "admin",
  subservices: {
    POST: [
      "createAssistant",
      "configurationIds",
      "getAllAssistantsData",
      // eslint-disable-next-line no-secrets/no-secrets
      "getGraph",
      "getOpenAiPromptResponses",
      "makeAssistantLive",
      "switchAccount",
      "getDashboardData"
    ]
  }
});

const accountsService = makeService({
  service: "accounts",
  subservices: {
    POST: ["findAccounts", "findAccountAssistants", "findAccountByAssistantId"],
    GET: ["test"]
  }
});

const botPortalService = makeService({
  service: "botPortal",
  subservices: {
    POST: [
      "createDraftAndUpdateTriplets",
      "deleteDraft",
      "editTriplets",
      "getClientName",
      "getLastNotification",
      "createMergeRequest",
      "originalTriplets",
      "tripletsFromDraft"
    ]
  }
});

const conversationService = makeService({
  service: "conversation",
  subservices: { POST: ["find", "get"] }
});

const deploymentService = makeService({
  service: "switch",
  subservices: {
    POST: ["addNewNumber", "deactivateRoom", "findConnector"]
  }
});



const kgviewerService = makeService({
  service: "kgviewer",
  subservices: { POST: ["getGraph", "getVersionsFile", "isNodeExistsInKg"] }
});

const previewService = makeService({
  service: "preview",
  subservices: {
    POST: [
      "createBotForTesting",
      "isBotReadyForTesting",
      "initiateTestCall",
      "isBotPreviewable"
    ]
  }
});

const recordingService = makeService({
  service: "recording",
  subservices: {
    POST: ["get"]
  }
});

const storageService = makeService({
  service: "storage",
  subservices: {
    POST: [
      "createDataSource",
      "updateDataSource",
      "downloadFile",
      "getFilesMetaData",
      "uploadImageFile"
    ],
    DELETE: [
      "deleteDataSource"
    ]
  }
});

const usersService = makeService({
  service: "users",
  subservices: {
    POST: ["userPermissions"]
  }
});

const voiceService = makeService({
  service: "voice",
  subservices: {
    POST: ["initiateCall"]
  }
});

export {
  adminService,
  accountsService,
  botPortalService,
  voiceService,
  conversationService,
  deploymentService,
  kgviewerService,
  previewService,
  recordingService,
  storageService,
  usersService
};
