import React, { useState } from "react";
import { useAccessToken } from "../hooks";

const ClientSocketContext = React.createContext(null);

const ClientSocketProvider = ({ children }) => {
  const accessToken = useAccessToken();
  const [ws, setWs] = useState(null);

  const connect = () => {
    if (accessToken) {
      const url = new URL(window.location);
      const protocol = url.protocol === "http:" ? "ws" : "wss";
      const host = import.meta.env.VITE_DASHBOARD_API_HOST;

      const socket = new WebSocket(
        `${protocol}://${host}?token=${accessToken}`
      );
      socket.onerror = (err) => {
        console.error(
          `An error occurred when trying to connect to ${protocol}://${host}?token=${accessToken}`,
          err
        );
      };
      setWs(socket);
    }
  };

  return (
    <ClientSocketContext.Provider
      value={{
        connect,
        ws,
        setWs
      }}
    >
      {children}
    </ClientSocketContext.Provider>
  );
};

export default ClientSocketContext;

export { ClientSocketProvider };
