import { del, get, patch, post } from "./api";

const adminServiceNew = {
  generateQuestions: assistantId =>
    get("/admin/back-office/questions-generator", { assistantId }),
  getAccounts: ({ page, perPage }) => get("/admin/accounts", { page, perPage }),
  createAccount: ({ accountName, accountId, pageName }) =>
    post("/admin/accounts", { accountName, accountId }, null, { pageName }),
  editAccount: ({ id, accountName, accountId, pageName }) =>
    patch(`/admin/accounts/${id}`, { accountName, accountId }, { pageName }),
  deleteAccount: ({ id, pageName }) => del(`/admin/accounts/${id}`, {}, { pageName }),
  getAvailableData: () => get("/admin/assistant/available-data"),
  createCodelessAssistant: (data, pageName) => post("/admin/assistant", data, null, { pageName }),
  archiveAssistant: (assistantId, pageName) => del(`/admin/assistants/${assistantId}/archive`, {}, { pageName }),
  deactivateAssistant: (assistantId, pageName) => patch(`/admin/assistants/${assistantId}/deactivate`, {}, { pageName }),
  getBranchDeployerRows: ({ page, perPage }) => get("/admin/branch-deployer", { page, perPage }),
  createBranchDeployer: (branchDeployerData) => post("/admin/branch-deployer", branchDeployerData),
  editBranchDeployer: (branchDeployerData) => patch("/admin/branch-deployer", branchDeployerData),
  deleteBranchDeployer: (id) => del(`/admin/branch-deployer/${id}`),
  getBranchDeployerStatus: (id) => get(`/admin/branch-deployer/status/${id}`)
};

export default adminServiceNew;
